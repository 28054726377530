$dark-gray: #212832;
$light-gray: #F5F5F5;
$electricity: #97C13D;
$inactive: #CECECE;
.mobile-waste {
	display: flex;
	flex-direction: column;
	margin: 40px 0 20px;
	padding: 30px 10% 20px !important;
	.mobile-waste-icon {
		margin: auto;
	}
	.mobile-waste-title {
		margin: 10px auto;
		font-size: 20px;
		line-height: 24px;
		font-weight: 600;
		color: $dark-gray;
	}
	.mobile-waste-detail {
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 18px;
		color: black;
	}
	.mobile-waste-how-to-button {
		display: flex;
		justify-content: center;
		height: 20px;
		margin: 0 -14%;
		padding: 22px 14% 18px;
		border: 1px solid $light-gray;
		font-size: 15px;
		line-height: 18px;
		color: $electricity;
		font-weight: 600;
		.mobile-waste-toggle-icon {
			height: 20px;
			width: 20px;
			font-size: 20px;
			margin-top: -2px;
		}
	}
	.mobile-waste-how-to {
		max-height: 0;
		padding: 0 14%;
		overflow: hidden;
		margin: 0 -14%;
		background: $light-gray;
		font-size: 15px;
		line-height: 18px;
		transition: ease all 0.4s;
		.mobile-waste-how-to-detail {
			margin-bottom: 15px;
		}
		.mobile-waste-how-to-images {
			display: flex;
			justify-content: space-around;
			margin-top: 15px;
			.mobile-waste-how-to-image {
				display: inline-block;
				img {
					width: 70%;
					max-width: 120px;
				}
			}
		}
		.mobile-waste-how-to-link {
			margin-top: 30px;
			text-decoration: none;
			color: black;
			b {
				text-decoration: underline;
			}
		}
		&.display {
			transition: ease all 0.4s;
			max-height: none;
			padding: 30px 14%;
		}
	}
	.mobile-waste-survey {
		margin: 30px auto 20px;
		font-size: 15px;
		line-height: 18px;
		color: $dark-gray;
		.mobile-waste-survey-headline {
			color: black;
			font-size: 18px;
			line-height: 22px;
			font-weight: 600;
			padding: 15px 0;
		}
		.mobile-waste-options {
			height: 300px;
			margin: 30px auto 50px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.mobile-waste-option {
				display: flex;
				opacity: 0.5;
				transition: ease all 0.3s;
				.mobile-waste-option-dot {
					box-sizing: border-box;
					margin: 2px 10px 0 0;
					height: 20px;
					width: 20px;
					border-radius: 10px;
					background-color: #F5F5F5;
					transition: ease all 0.3s;
					.mobile-waste-option-dot-inner {
						display: none;
						height: 10px;
						width: 10px;
						border-radius: 5px;
						border-radius: 5px;
						margin: 4px auto;
						background-color: black;
						transition: ease all 0.3s;
					}
				}
				img {
					width: 38px;
					height: 25px;
					vertical-align: middle;
					margin-right: 10px;
					filter: grayscale(100%);
				}
				.mobile-waste-option-text {
					width: 70%;
				}
				&.selected,
				&.all {
					opacity: 1;
					.mobile-waste-option-dot {
						border: 1px solid #000000;
					}
					img {
						filter: none;
					}
				}
				&.selected {
					.mobile-waste-option-dot {
						.mobile-waste-option-dot-inner {
							display: block;
						}
					}
				}
			}
		}
		.mobile-waste-question {
			display: flex;
			flex-direction: column;
			margin: 40px 4%;
			font-size: 16px;
			line-height: 18px;
			font-weight: 600;
			input {
				height: 19px;
				padding: 11px 15px 10px;
				margin-top: 20px;
				font-size: 14px;
				line-height: 18px;
				border: 1px solid #D3D3D3;
			}
		}
		.mobile-waste-button {
			width: 225px;
			height: 19px;
			padding: 10px 15px 11px;
			margin: 50px auto 20px;
			background: $inactive;
			text-align: center;
			font-weight: 600;
			color: white;
			transition: ease all 0.3s;
			&.active {
				background: $dark-gray;
				color: white;
			}
			&.posted {
				color: $electricity;
				background: transparent;
			}
		}
		.mobile-waste-confirmation {
			height: 18px;
			margin-top: -20px;
			color: $electricity;
			font-size: 15px;
			line-height: 18px;
			text-align: center;
			font-style: italic;
		}
	}
}
