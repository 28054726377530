.chcp-navigation {
  height: 66px;
  width: 100%;
  background-color: #3c414b;
  color: #9DA0A5;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.7px;
  text-align: center;
}
.chcp-navigation .chcp-navigation-wrapper {
  display: flex;
  justify-content: space-between;
  width: 76%;
  max-width: 950px;
  margin: auto;
}

.chcp-navigation-button {
  width: 175px;
  cursor: pointer;
  padding: 25px 15px;
  transition: ease all 0.2s;
}
.chcp-navigation-button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
}
.chcp-navigation-button.active {
  color: #3c414b;
  background-color: #EEEDEE;
}

