$background: #eeedee;
$button-background: #2D3138;
$dark-gray: #3C414B;
$text-inactive: rgba(60, 65, 75, 0.5);

.building-demand {
	background-color: $background;
}
.building-demand-title {
	position: relative;
	font-size: 16px;
	text-align: left;
	padding-bottom: 25px;
	.demand-zoom-helper {
		position: absolute;
		right: 30%;
		bottom: 0;
		color: $dark-gray;
		opacity: 0.9;
		font-size: 13px;
		img {
			margin-bottom: -1px;
		}
		@media (min-width: 1080px) {
			right: 21%;
		}
	}
}
.building-demand-graph {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.building-demand-chart {
		height: 400px;
	}
	.building-demand-side-options {
		text-align: center;
		font-size: 16px;
		line-height: 18px;
		.building-demand-side-button {
			height: 28px;
			border: solid 1px #dbdbdb;
			margin: 10px;
			padding-top: 12px;
			cursor: pointer;
		}
		.building-demand-side-button:hover,
		.building-demand-side-button.active {
			background-color: $dark-gray;
			border: solid 1px $dark-gray;
			color: white;
			font-weight: 600;
		}
		.building-demand-side-oat-wrapper {
			padding-left: 12px;
			padding-top: 24px;
			.building-demand-side-oat-label-spacer {
				padding-left: 34px;
			}
		}
		.building-demand-side-oat {
			text-align: left;
			position: relative;
			height: 21px;
			line-height: 21px;
			display: block;
			cursor: pointer;
			margin-top: 20px;
		}
		.building-demand-side-oat input {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
		}
		.building-demand-side-oat .building-demand-side-oat-label:before {
			content: '';
			padding: 0px;
			display: inline-block;
			height: 16px;
			width: 16px;
			border: solid 1px $background;
			border-radius: 0;
			font-size: 12px;
			margin-right: 7px;
			vertical-align: middle;
		}
		.building-demand-side-oat input + .building-demand-side-oat-label:before {
			color: white;
			background-color: white;
			margin-right: 15px;
		}
		.building-demand-side-oat input:checked + .building-demand-side-oat-label:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-demand-side-oat input + .building-demand-side-oat-label {
			font-size: 15px;
			color: $text-inactive;
		}
		.building-demand-side-oat input:checked + .building-demand-side-oat-label {
			color: black;
		}
	}
	@media (min-width: 850px) {
		.building-demand-chart {
			width: 72%;
		}
		.building-demand-side-options {
			width: 27%
		}
		.building-demand-commodities {
			padding: 10px 0 0 10%;
		}
	}
	@media (min-width: 1080px) {
		.building-demand-chart {
			width: 80%;
		}
		.building-demand-side-options {
			width: 19%
		}
		.building-demand-commodities {
			padding: 10px 5% 0 12%;
		}
	}
	.building-demand-commodities {
		width: 62%;
		display: flex;
		justify-content: space-between;
		.building-demand-commodity {
			display: flex;
			font-size: 16px;
			font-weight: 600;
			margin: auto;
			input {
				margin: 2px 12px 0 0;
			}
		}
		.building-demand-commodity {
			text-align: left;
			position: relative;
			height: 21px;
			line-height: 21px;
			display: block;
			cursor: pointer;
		}
		.building-demand-commodity input {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
		}
		.building-demand-commodity .building-demand-commodity-label:before {
			content: '';
			padding: 0px;
			display: inline-block;
			height: 16px;
			width: 16px;
			border: solid 1px $background;
			border-radius: 0;
			font-size: 12px;
			margin-right: 7px;
			vertical-align: middle;
		}
		.building-demand-commodity input + .building-demand-commodity-label:before {
			color: white;
			background-color: white;
			margin-right: 15px;
		}
		.building-demand-commodity input:checked + .building-demand-commodity-label.electricity:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-demand-commodity input:checked + .building-demand-commodity-label.gas:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%238D67D6' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-demand-commodity input:checked + .building-demand-commodity-label.steam:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23f69222' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-demand-commodity input:checked + .building-demand-commodity-label.chilledWater:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%231caac8' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-demand-commodity input:checked + .building-demand-commodity-label.solar:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23F8D81C' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-demand-commodity input:checked + .building-demand-commodity-label.oat:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-demand-commodity input + .building-demand-commodity-label {
			font-size: 15px;
			color: $text-inactive;
		}
		.building-demand-commodity input:checked + .building-demand-commodity-label {
			color: black;
		}
	}
	.csv {
		padding-top: 5px;
		a {
			color: $dark-gray;
			text-decoration: none;
			font-size: 14px;
		}
	}
	//    _   _ _       _          _                _
	//   | | | (_) __ _| |__   ___| |__   __ _ _ __| |_ ___
	//   | |_| | |/ _` | '_ \ / __| '_ \ / _` | '__| __/ __|
	//   |  _  | | (_| | | | | (__| | | | (_| | |  | |_\__ \
	//   |_| |_|_|\__, |_| |_|\___|_| |_|\__,_|_|   \__|___/
	//            |___/
	.highcharts-root {
		font-family: ['Proxima Nove', 'Lucida Grande', 'Lucida Sans', 'Verdana', 'sans-serif'];
		.highcharts-axis-labels {
			text-transform: uppercase;
		}
	}
}
