$dark-gray: #2D3138;
$light-text: rgba(45, 49, 56, 0.7);
$text: #3c414b;
$shadow: rgba(0, 0, 0, 0.16);
.metrics-tab {
	padding-top: 40px;
	max-width: 1050px;
	width: 100%;
	margin: auto;
	font-size: 16px;
	line-height: 20px;
	.water-card {
		background-color: white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
		padding: 57px 3%;
		display: flex;
		flex-direction: row;
		flex-flow: wrap;
		object-fit: contain;
	}
	.water-metrics {
		.water-headline {
			width: 100%;
			font-size: 16px;
			font-weight: 100;
			line-height: 22px;
			text-align: center;
      padding-bottom: 610px; 
		}
  }
}