.building-search-label {
    opacity: 0.75;
    color: #2E384D;
    font-size: 14px;
    line-height: 22px; 
    padding-left: 3%;
    padding-right: 3%;
}

input.building-search {
    height: 33px;
    width: 340px;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #2E384D;
    font-size: 15px;
    line-height: 18px;
    padding-left: 10px;
}
