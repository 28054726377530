.error-message {
	width: 100%;
	width: 100vw;
	min-width: 350px;
	height: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	background-color: #E5E5E5;
	background-image: url("./images/error-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.error-message-joules,
	.error-message-header,
	.error-message-content,
	.error-message-logo {
		margin: 0 auto;
		padding: 10px;
		text-align: center;
		font-weight: 600;
	}
	.error-message-header {
		font-size: 48px;
		line-height: 56px;
	}
	.error-message-content {
		font-size: 24px;
		line-height: 32px;
		padding-bottom: 20px;
	}
}
