.metrics-chart {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	.metrics-chart-content {
		width: 76%;
		.metrics-chart-title {
			position: relative;
			padding: 10px 0 30px;
			font-size: 16px;
			.demand-zoom-helper {
				position: absolute;
				right: 8px;
				bottom: 0;
				color: #3c414b;
				opacity: 0.9;
				font-size: 13px;
				img {
					margin-bottom: -1px;
				}
			}
		}
	}
}
