.total-usage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  line-height: 17px;
}
.total-usage .total-usage-value {
  display: flex;
  flex-direction: column;
  width: 6%;
  min-width: 65px;
  justify-content: space-evenly;
}
.total-usage .total-usage-circle {
  width: 150px;
  height: 150px;
  margin: -14px -30px;
}
.total-usage .total-usage-grid {
  display: flex;
  flex-direction: column;
  width: 65%;
}
.total-usage .total-usage-grid .usage-grid-header,
.total-usage .total-usage-grid .usage-grid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.total-usage .total-usage-grid .usage-grid-header.electricity,
.total-usage .total-usage-grid .usage-grid-row.electricity {
  color: #3C414B;
}
.total-usage .total-usage-grid .usage-grid-header.gas,
.total-usage .total-usage-grid .usage-grid-row.gas {
  color: #3C414B;
}
.total-usage .total-usage-grid .usage-grid-header .usage-grid-column,
.total-usage .total-usage-grid .usage-grid-row .usage-grid-column {
  padding: 14px 10px 10px;
  width: 100%;
  font-size: 15px;
  line-height: 16px;
  border-bottom: 1px solid #EEEDEE;
  border-right: 1px solid #EEEDEE;
}
.total-usage .total-usage-grid .usage-grid-header .usage-grid-column img,
.total-usage .total-usage-grid .usage-grid-row .usage-grid-column img {
  vertical-align: middle;
  margin-top: -4px;
  padding-right: 12px;
}
.total-usage .total-usage-grid .usage-grid-header .usage-grid-column.column-1,
.total-usage .total-usage-grid .usage-grid-row .usage-grid-column.column-1 {
  border-left: 1px solid #EEEDEE;
}
.total-usage .total-usage-grid .usage-grid-header .usage-grid-column.column-2, .total-usage .total-usage-grid .usage-grid-header .usage-grid-column.column-3,
.total-usage .total-usage-grid .usage-grid-row .usage-grid-column.column-2,
.total-usage .total-usage-grid .usage-grid-row .usage-grid-column.column-3 {
  padding-right: 20px;
  text-align: right;
}
.total-usage .total-usage-grid .usage-grid-header {
  background-color: #EEEDEE;
}

