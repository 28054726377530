$border-gray: #D8D8D8;
$card-gray: #F6F6F7;
.total-savings {
	.total-savings-title {
		font-size: 17px;
		padding-top: 10px;
		line-height: 22px;
		font-weight: 600;
	}
	.total-savings-description {
		width: 95%;
		margin: 30px 0;
		line-height: 22px;
	}
	.total-savings-overview {
		width: 100%;
		display: flex;
		margin: 30px auto 50px;
		justify-content: space-between;
		.savings-overview-table {
			width: 36%;
			height: 100%;
			border-top: 1px solid $border-gray;
			border-right: 1px solid $border-gray;
			.overview-table-header {
				display: flex;
				width: 100%;
				width: -webkit-fill-available;
				font-weight: 600;
			}
			.overview-data-row {
				display: flex;
				width: 100%;
				width: -webkit-fill-available;
			}
			.overview-table-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 10px 20px;
				border: 1px solid $border-gray;
				border-top: none;
				border-right: none;
				&.right {
					text-align: right;
					border-left: none;
				}
			}
		}
		.savings-overview-chart {
			width: 55%;
		}
	}
	.total-savings-current {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		margin: 0 -40px -60px;
		padding: 30px 40px 60px;
		background: $card-gray;
		.savings-current-svg {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 30px auto 40px;
			path {
				animation: progress 3s ease-out forwards;
			}
			@keyframes progress {
				0% {
					stroke-dasharray: none;
				}
				33% {
					stroke-dasharray: 0 1000;
				}
			}
			line {
				stroke: orange;
				stroke-width: 18;
			}
			svg {
				width: 100%;
				border: 4px solid $border-gray;
				border-radius: 16px;
				background: $border-gray;
			}
			.stroke-3 {
				stroke: blue
			}
		}
		.savings-current-values {
			width: 100%;
			display: flex;
			justify-content: space-between;
			.savings-current-value {
				display: flex;
				padding: 10px 12px 8px;
				background-color: white;
				border: 2px solid $border-gray;
				.savings-current-value-color {
					padding-right: 12px;
				}
				b {
					padding-left: 6px;
				}
			}
		}
	}
	.savings-current-table {
		width: 40%;
		background: white;
		border-top: 1px solid $border-gray;
		border-right: 1px solid $border-gray;
		margin-top: 30px;
		.current-table-header {
			display: flex;
			width: 100%;
			width: -webkit-fill-available;
			font-weight: 600;
		}
		.current-data-row {
			display: flex;
			width: 100%;
			width: -webkit-fill-available;
		}
		.current-table-content {
			display: flex;
			justify-content: flex-start;
			padding: 10px 20px;
			border: 1px solid $border-gray;
			border-top: none;
			border-right: none;
			&.right {
				text-align: right;
				border-left: none;
			}
			.current-table-dot {
				margin-right: 10px;
			}
		}
	}
	.savings-current-graph {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 55%;
		margin-top: 30px;
		.savings-current-circle {
			width: 100%;
		}
		.savings-current-hover {
			margin-top: 20px;
			text-align: center;
		}
	}
}
