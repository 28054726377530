.mobile-building-view {
  background: #F5F5F5;
}

.mobile-building-content {
  background-color: #eeedee;
  min-height: 100vh;
  font-size: 18px;
  line-height: 22px;
}

.mobile-navigation-title {
  color: white;
  margin-top: 2px;
}
.mobile-navigation-title img {
  margin-right: 10px;
}

.mobile-building-header {
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #666667;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.mobile-building-header .mobile-building-header-image {
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  opacity: 0.4;
}
.mobile-building-header .mobile-building-header-image img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.mobile-building-header .mobile-building-header-overlay {
  background: #222831;
  height: inherit;
  width: 100%;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
}
.mobile-building-header .mobile-building-stats {
  position: relative;
  color: white;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  z-index: 100;
}
.mobile-building-header .mobile-building-stats.title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin: 12px auto;
}

.mobile-building-nav-bar {
  display: flex;
  justify-content: space-around;
  padding: 0 4%;
  font-size: 15px;
  line-height: 18px;
  color: white;
  background: #222831;
}
.mobile-building-nav-bar a {
  color: white;
  text-decoration: none;
}
.mobile-building-nav-bar .mobile-building-nav-item {
  height: 20px;
  padding: 14px 0 8px;
  opacity: 0.5;
  text-align: center;
}
.mobile-building-nav-bar .mobile-building-nav-item.active {
  font-weight: 600;
  border-bottom: 4px solid white;
  opacity: 1;
}
.mobile-building-nav-bar .mobile-building-nav-item.housing {
  max-width: 28%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-building-demand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
}
.mobile-building-demand .mobile-building-demand-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}
.mobile-building-demand .mobile-building-demand-subtitle {
  font-size: 14px;
  opacity: 0.5;
}
.mobile-building-demand #demand-graph {
  height: 310px;
}
.mobile-building-demand .mobile-building-demand-options {
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 75%;
}
.mobile-building-demand .mobile-building-demand-options .mobile-demand-option {
  padding: 3px 6px;
}
.mobile-building-demand .mobile-building-demand-options .selected {
  font-weight: 600;
  color: #97C13D;
  border-bottom: 3px solid #97C13D;
}

.mobile-building-success {
  height: 140px;
  width: 95%;
  max-width: 400px;
  margin: 20px auto;
  display: flex;
  background: white;
  border-radius: 2px;
}
.mobile-building-success img {
  height: 100%;
}
.mobile-building-success .mobile-building-success-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 20px 20px;
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
}
.mobile-building-success .mobile-building-success-details .mobile-building-success-detail {
  padding: 0 25px;
}
.mobile-building-success .mobile-building-success-details a {
  text-decoration: none;
  color: black;
}
.mobile-building-success .mobile-building-success-details a .mobile-building-success-button {
  height: 20px;
  width: 85%;
  padding: 11px 10px 9px;
  margin: auto;
  background: #97C13D;
  color: white;
  font-weight: 600;
}

.mobile-graph-card {
  padding: 30px 4% 20px;
  background: white;
}

