$dark-gray: #3c414b;
$hover-gray: #5A5D65;
$light-gray: #9DA0A5;
.dashboards-menu-navigation {
	position: absolute;
	left: 15%;
	padding-top: 24px;
	.dashboards-link,
	.dashboards-menu {
		width: 140px;
		height: 30px;
		padding-top: 12px;
		padding-left: 12px;
		border-radius: 2px;
		font-size: 16px;
		line-height: 20px;
		cursor: pointer;
		font-weight: 600;
		background-color: $dark-gray;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		color: $light-gray;
		text-decoration: none;
		transition: ease all 0.3s;
		display: flex;
	}
	.dashboards-link {
		justify-content: center;
		img {
			height: 20px;
			width: 20px;
			padding: 0 4px;
			transform: rotate(-90deg);
			filter: brightness(320%);
		}
	}
	.dashboards-menu {
		display: flex;
		align-items: center;
		width: 340px;
		height: 40px;
		padding: 5px 30px;
		img {
			margin-right: 20px;
			width: 32px;
			height: 32px;
		}
		&:hover {
			background-color: $hover-gray;
			color: white;
			img {
				filter: brightness(125%);
				-webkit-filter: brightness(125%);
			}
		}
	}
}
a {
	text-decoration: none;
}
.dashboards-link:hover {
	background-color: $hover-gray;
	color: white;
	img {
		filter: brightness(500%);
		-webkit-filter: brightness(500%);
	}
}
