$background : #222831;
.mobile-navigation {
	height: 20px;
	padding: 14px 4% 16px;
	display: flex;
	justify-content: space-between;
	background: $background;
	color: white;
	.mobile-navigation-menu {
		padding: 5px 10px;
		font-size: 22px;
		line-height: 6px;
	}
	.mobile-navigation-right {
		width: 35px;
		height: 20px;
	}
}