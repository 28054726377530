.dropdown-menu ul {
  list-style-type: none;
  -webkit-padding-start: 0px;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -5px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: -13px;
  position: relative; 
  z-index: 2; 
  border-top: none;
  border-left: 1px solid #E0E7FF;
  border-right: 1px solid #E0E7FF;
  border-bottom: 1px solid #E0E7FF;
  border-radius: 0px 5px 5px 5px;
  width: 208px;
  padding-left: 0px;
}

.dropdown-menu li {
  padding-top: 6px;
  padding-left: 12px;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: 22px;
  position: relative;
  z-index: 999;
  color: #2E384D;
}

.dropdown-menu li input[type="checkbox"] {
  opacity: 0;
}

.dropdown-select {
  border: 1px solid #E0E7FF;
  color: #2E384D;
  border-radius: 5px;
  width: 196px;
  height: 30px;
  background-color: #F5F5F5;
  -webkit-appearance: none;
  -moz-appearance: none;
  letter-spacing: normal;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  padding-left: 12px;
  position: relative;

  // We put the ul color here so the user can override it with a custom class
  ul {
    background-color: #F5F5F5;
  }
}

.custom-checkbox {
  .checkbox {
    label::before {
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;
      border: 1px solid #E0E7FF;
      border-radius: 2px;
      background-color: #FFFFFF;
    }
    
    label {
      position: relative;
    }
    
    label::before,
    label::after {
      position: absolute;
    }
    
    /*Position Outer-box*/
    label::before {
      top: 0px;
      left: -23px;
    }
    
    /* Checkmark */
    label::after {
      content: "";
      display: inline-block;
      height: 6px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      /* Position checkmark inside Outer-box*/
      left: -19px;
      top: 4px;
      color: black;
    }
    
    /*Hide the checkmark by default*/
    input[type="checkbox"] + label::after {
      content: none;
    }
    
    /*Unhide the checkmark on the checked state*/
    input[type="checkbox"]:checked + label::after {
      content: "";
    }
  
    input[type="checkbox"]:checked + label::after {
      content: "";
    }
  
    input[type=checkbox]:not(:checked) + label {
      opacity: .5;
    } 
  }

  .radio {
    /* Hide default radio icon */
    [type=radio] { 
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* Position radio button */
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }

    /* Draw unchecked radio button */
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 14px;
      height: 14px;
      border: 1px solid #E0E7FF;
      border-radius: 100%;
      background: #FFFFFF;
    }

    /* Draw checked radio button */
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 10px;
      height: 10px;
      background: #2E384D;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    /* Hide check by default */
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    /* Show check */
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    label {
      position: relative;
    }

    input[type=radio]:not(:checked) + label span {
      opacity: .75;
    } 

    input[type=radio]:checked + label {
      font-weight: bold
    } 

  }
}

.dropdown-button::after {
  content: "";
  background: transparent url("../common/images/caret.svg");
  background-repeat: no-repeat;
  background-position: 92%;
  opacity: .3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.selected-item {
  opacity: .5;
}
