.box-wrapper {
  padding-top: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.box-wrapper-selected {
  padding-top: 5px;
  background-color: #f5f5f5;
  border-style: solid;
  border-width: thin;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.box-wrapper .savingsText-wrapper {
  border-top: 1px solid #d8d8d8;
  height: 30px;
}

.box-wrapper .barChart-wrapper {
  width: 120px;
  height: 110px;
}

.box-wrapper-selected .savingsText-wrapper {
  border-top: 1px solid #d8d8d8;
  height: 40px;
}

.box-wrapper-selected .barChart-wrapper {
  width: 120px;
  height: 110px;
}
