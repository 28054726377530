.tab-wrap {
  width: calc(90% + 80px);
  max-width: 1050px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 35px;
}

.tab-navigation-button {
  width: 24%;
  height: 165px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  color: rgba(45, 49, 56, 0.5);
  transition: ease all 0.3s;
  cursor: pointer;
}
.tab-navigation-button .tab-title {
  padding: 5px;
  font-size: 17px;
  color: rgba(45, 49, 56, 0.5);
}
.tab-navigation-button .tab-FY {
  padding: 3px 0 5px;
  font-size: 15px;
}
.tab-navigation-button img {
  height: 26%;
  margin-top: 30px;
  margin-bottom: 5px;
  border: none;
  filter: grayscale(100%) brightness(80%);
  opacity: 0.5;
}
.tab-navigation-button.active, .tab-navigation-button:hover {
  transition: ease all 0.3s;
  color: #2D3138;
}
.tab-navigation-button.active img, .tab-navigation-button:hover img {
  filter: none;
  opacity: 1;
}
.tab-navigation-button.active, .tab-navigation-button:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
.tab-navigation-button.active .tab-title, .tab-navigation-button:hover .tab-title {
  color: #2D3138;
}

