.search-box {
  position: absolute;
  top: 10px;
  right: 50px;
  width: 290px;
  background-color: white;
  border: solid 4px #e1e1e1;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: 600;
  z-index: 100;
}

.search-input-box {
  position: relative;
}
.search-input-box img {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 4px;
  background: white;
  z-index: 100;
}
.search-input-box img.hide {
  display: none;
}
.search-input-box .search-input-clear {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 30px;
  cursor: pointer;
  z-index: 50;
  height: 85%;
  padding: 2px 6px;
  font-weight: 100;
  transition: transform 200ms ease;
}
.search-input-box .search-input-clear:hover {
  transform: rotate(-90deg);
  transition: transform 200ms ease;
}

input.search-input-control {
  width: 230px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  padding: 5px 15px;
  background: white;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.search-metering-unavailable {
  position: absolute;
  left: -4px;
  background-color: #efefef;
  border: solid 4px #e1e1e1;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 30px 0;
  transition: 0.5s all;
  text-align: center;
  margin: auto;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #676767;
  display: none;
}
.search-metering-unavailable.show {
  display: block;
}

.search-results {
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
}

.search-box-row {
  width: inherit;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  border-top: 1px solid #efefef;
}
.search-box-row svg {
  padding: 15px 10px 0px;
}
.search-box-row .search-box-row-building {
  width: 250px;
  padding-right: 10px;
}
.search-box-row .no-eui,
.search-box-row .no-results {
  color: #676767;
  padding-left: 10px;
}
.search-box-row .no-eui:hover,
.search-box-row .no-results:hover {
  color: black;
}
.search-box-row .no-results {
  cursor: auto;
}

.search-box-row:hover {
  background-color: #efefef;
}

