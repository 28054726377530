$text: white;

.chcp-header {
	width: 100%;
	background-image: url("./images/chcp.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 75%;
	padding-bottom: 70px;
	.chcp-header-content {
		display: flex;
		justify-content: space-around;
		width: 70%;
		height: 80%;
		margin: auto;
		padding-top: 25px;
		.chcp-header-title {
			padding-top: 100px;
			width: 30%;
			margin: 0px;
			font-size: 36px;
			font-weight: 600;
			line-height: 1.2;
			text-align: left;
			color: white;
		}
		.chcp-header-description {
			width: 60%;
			padding-top: 100px;
			margin: 0 0 0 auto;
			color: white;
			font-size: 16px;
			line-height: 22px;
			p {
				margin: 0;
			}
		}
	}
}
.chcp-header-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: inherit;
	z-index: -1;
	overflow: hidden;
	img {
		object-fit: cover;
		object-position: center;
		width: inherit;
		height: inherit;
		z-index: inherit;
	}
}
