$dark-gray: #2D3138;
$light-text: rgba(45, 49, 56, 0.7);
$text: #3c414b;
$text-inactive: rgba(60, 65, 75, 0.7);
$light-green: #F5F8ED;
$green: #95BC43;
$border: #EEEDEE;
$bubble-background: #F6F6F6;
$shadow: rgba(0, 0, 0, 0.16);
.campus-usage {
	padding-top: 40px;
	max-width: 1050px;
	width: 100%;
	margin: auto;
	font-size: 16px;
	line-height: 20px;
	.campus-card {
		background-color: white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
		padding: 30px 3% 40px 3%;
		display: flex;
		flex-direction: row;
		flex-flow: wrap;
		object-fit: contain;
	}
	//    _____     _        _   _____
	//   |_   _|__ | |_ __ _| | | ____|_ __   ___ _ __ __ _ _   _
	//     | |/ _ \| __/ _` | | |  _| | '_ \ / _ \ '__/ _` | | | |
	//     | | (_) | || (_| | | | |___| | | |  __/ | | (_| | |_| |
	//     |_|\___/ \__\__,_|_| |_____|_| |_|\___|_|  \__, |\__, |
	//                                                |___/ |___/
	.usage-total-energy {
		.energy-gas-sankey-diagram {
			background-color: blue;
			margin: auto;
		}
		.total-energy-headline {
			width: 100%;
			font-size: 16px;
			font-weight: 100;
			line-height: 22px;
			padding: 20px 0;
		}
		.total-energy-info-icon {
			position: absolute;
			z-index: 1;
			margin-left: 58px;
			margin-top: 60px;
			cursor: pointer;
		}
		.speech-bubble-ds {
			display: none;
			background: $bubble-background;
			border: 3px solid $border;
			-webkit-border-radius: 3px;
			border-radius: 3px;
			-webkit-box-shadow: 0px 2px 4px $shadow;
			box-shadow: 0px 2px 4px $shadow;
			font-size: 14px;
			line-height: 17px;
			color: $text;
			margin: 14px 0 0 92px;
			max-width: 400px;
			padding: 5px 15px 0px;
			width: 240px;
			height: 110px;
			position: absolute;
			z-index: 1;
		}
		.speech-bubble-ds p {
			margin-bottom: -5px;
		}
		.speech-bubble-ds p:last-of-type {
			margin-bottom: 0;
		}
		.speech-bubble-ds-arrow {
			border-left: 21px solid transparent;
			border-top: 17px solid $border;
			bottom: 48px;
			position: absolute;
			right: 270px;
		}
		.speech-bubble-ds-arrow::before {
			border-left: 20px solid transparent;
			border-bottom: 15px solid $bubble-background;
			bottom: 18px;
			content: "";
			position: absolute;
			right: 0px;
		}
		.speech-bubble-ds-arrow::after {
			border-left: 20px solid transparent;
			border-top: 15px solid $bubble-background;
			bottom: 3px;
			content: "";
			position: absolute;
			right: 0px;
		}
		.total-energy-info-icon:hover + .speech-bubble-ds {
			display: inline;
		}
		.campus-graph {
			width: 100%;
			display: flex;
			justify-content: space-between;
			.campus-chart {
				width: 79%;
			}
			.total-energy-view-selection-options {
				width: 20%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: 14px;
				.total-energy-view-button {
					width: 100%;
					height: 20px;
					max-width: 140px;
					border: 2px solid $border;
					background-color: white;
					border-radius: 3px;
					color: $text-inactive;
					font-weight: 500;
					padding: 9px 24px 9px;
					margin-bottom: 5px;
					cursor: pointer;
					transition: all ease 0.2s;
					text-align: center;
					z-index: 2;
					&:hover,
					&.active {
						color: $dark-gray;
						background-color: white;
						border: 2px solid $dark-gray;
						font-weight: 600;
					}
				}
			}
			.csv-div {
				.total-energy-csv {
					.csv-link {
						.total-energy-csv-button {
							width: 100%;
							max-width: 140px;
							height: 20px;
							border: 2px solid $border;
							background-color: white;
							border-radius: 3px;
							color: $dark-gray;
							font-weight: 500;
							text-align: center;
							display: flex;
							padding: 9px 24px 9px;
							.download-img {
								width: 11px;
								height: 13px;
								margin-top: 3px;
								margin-right: 18px;
							}
							&:hover {
								border: 2px solid $dark-gray;
								font-weight: 600;
							}
						}
					}
				}
			}
			.total-energy-graph {
				display: flex;
				.total-energy-graph-options {
					min-height: 60px;
					display: flex;
					flex-direction: column;
					margin-bottom: 15px;
					.energy-graph-option {
						display: flex;
						font-size: 16px;
						font-weight: 550;
					}
					.energy-graph-option {
						width: 100%;
						text-align: left;
						position: relative;
						display: block;
						cursor: pointer;
						margin: 10px 0 0 0px;
					}
					.energy-graph-option input {
						display: none;
						position: absolute;
						cursor: pointer;
					}
					.energy-graph-option .energy-graph-option-label:before {
						content: '';
						padding: 0px;
						display: inline-block;
						height: 16px;
						width: 16px;
						border: solid 1px #d6d6d6;
						border-radius: 3px;
						font-size: 12px;
						margin-right: 7px;
						vertical-align: middle;
					}
					.energy-graph-option input + .energy-graph-option-label:before {
						color: white;
						background-color: white;
						min-width: 16px;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.gas:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23875878' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.primateGas:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23875878' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.grid:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.buildingGas:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23413A6A' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.carbonNeutral:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23C1D991' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.electricity:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.solarFarm:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23CAD93E' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.rooftopSolar:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23cad93f' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.gasCost:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23413A6A' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input:checked + .energy-graph-option-label.electricityCost:before {
						background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
						opacity: 0.8;
					}
					.energy-graph-option input + .energy-graph-option-label {
						font-size: 14px;
						color: #a6a6a6;
						font-weight: 450;
						display: flex;
						margin-top: 1px;
					}
					.energy-graph-option input:checked + .energy-graph-option-label {
						color: black;
						display: flex;
					}
				}
			}
		}
		.total-energy-story {
			width: 90%;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: auto;
			font-size: 14px;
			img {
				height: 40px;
				margin-right: 25px;
			}
			div {
				display: inline-block;
				font-weight: 450;
				p {
					font-weight: 10;
					color: $light-text;
					margin-top: 12px;
					line-height: 17px;
				}
				.total-energy-story-note {
					font-style: italic;
					margin-bottom: 43px;
					font-weight: 10;
					color: $light-text;
				}
			}
			.total-energy-story-column1 {
				width: 45%;
				flex-direction: column;
				.total-energy-story-box {
					display: flex;
					flex-direction: row;
				}
			}
			.total-energy-story-column2 {
				width: 45%;
				flex-direction: column;
				.total-energy-story-box {
					display: flex;
					flex-direction: row;
					margin-bottom: 25px;
					.total-energy-story-flag {
						padding: 3px 15px;
						background-color: $light-green;
						color: $green;
						border-radius: 12px;
						margin-left: 10px;
						font-weight: bold;
						&.pge_demand {
							background-color: transparent;
						}
					}
					&.pge_demand,
					&.hydropower {
						margin-left: -7px;
					}
				}
			}
		}
	}
	//     ____                                  __  __      _        _
	//    / ___|__ _ _ __ ___  _ __  _   _ ___  |  \/  | ___| |_ _ __(_) ___ ___
	//   | |   / _` | '_ ` _ \| '_ \| | | / __| | |\/| |/ _ \ __| '__| |/ __/ __|
	//   | |__| (_| | | | | | | |_) | |_| \__ \ | |  | |  __/ |_| |  | | (__\__ \
	//    \____\__,_|_| |_| |_| .__/ \__,_|___/ |_|  |_|\___|\__|_|  |_|\___|___/
	//                        |_|
	.usage-campus-metrics {
		.campus-metrics-headline {
			text-align: center;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			padding: 20px 0 30px;
		}
		.campus-metrics-description {
			width: 80%;
			margin: 0 auto;
			padding-bottom: 30px;
		}
		.campus-metrics-view-selection-options {
			display: flex;
			span {
				padding-top: 22px;
				font-weight: 600;
			}
			.campus-metrics-view-button {
				border: 1px solid #C8E2EB;
				background-color: white;
				color: #6c6c6c;
				padding: 10px 24px 8px;
				margin: 12px;
				cursor: pointer;
				transition: all ease 0.2s;
				&:hover,
				&.active {
					background-color: #C8E2EB;
					color: black;
					font-weight: 600;
				}
			}
		}
		.campus-metrics-year-selection {
			padding-top: 16px;
			span {
				font-weight: 600;
			}
			.campus-metrics-year-selection-options {
				display: flex;
				flex-wrap: wrap;
				.campus-metrics-year-button {
					padding: 10px 3px 8px;
					color: #6c6c6c;
					width: 7%;
					min-width: 62px;
					font-size: 14px;
					text-align: center;
					cursor: pointer;
					transition: all ease 0.2s;
					margin: 2px 1px;
					&:hover,
					&.active {
						width: 7%;
						background-color: #C8E2EB;
						color: black;
						font-weight: 600;
					}
				}
			}
		}
		.campus-metrics-line {
			padding-top: 40px;
			font-weight: 600;
			.campus-metrics-line-graph {
				width: 100%;
				padding-top: 10px;
			}
			.campus-metrics-line-data {
				display: flex;
				flex-direction: column;
				margin: auto;
				font-size: 14px;
				line-height: 22px;
				font-weight: normal;
				.line-data-energy,
				.line-data-footage {
					display: flex;
					width: 40%;
					min-width: 300px;
					margin: auto;
					span {
						width: 60%;
						svg {
							margin-right: 8px;
							vertical-align: text-top;
						}
					}
					.line-data-energy-amount,
					.line-data-footage-amount {
						width: 24%;
						text-align: right;
						color: #99be4f;
						font-size: 15px;
						font-weight: 600;
					}
					.line-data-footage-amount {
						color: #25a9c6;
					}
				}
			}
		}
		.campus-metrics-bar {
			padding-top: 40px;
			font-weight: 600;
			.campus-metrics-bar-data {
				position: relative;
				top: -23px;
				left: 40%;
				font-weight: normal;
				font-size: 14px;
				span {
					padding-right: 12px;
				}
			}
			.campus-metrics-bar-graph {
				width: 83%;
				margin-left: 6.8%;
			}
		}
	}
}
