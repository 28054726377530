$search-text: #212832;
.mobile-search {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 300px;
	background-color: transparent;
	text-align: center;
	font-weight: 600;
	z-index: 100;
}
.mobile-search-input {
	position: relative;
	img {
		position: absolute;
		top: 2px;
		left: 4px;
		padding: 4px;
		background: white;
		z-index: 100;
	}
}
input.mobile-search-input-control {
	width: 90%;
	width: -webkit-fill-available;
	padding: 5px 20px;
	background: white;
	font-size: 15px;
	line-height: 18px;
	font-weight: 600;
	text-align: center;
	color: $search-text;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}
//    ____                      _       ____                 _ _       
//   / ___|  ___  __ _ _ __ ___| |__   |  _ \ ___  ___ _   _| | |_ ___ 
//   \___ \ / _ \/ _` | '__/ __| '_ \  | |_) / _ \/ __| | | | | __/ __|
//    ___) |  __/ (_| | | | (__| | | | |  _ <  __/\__ \ |_| | | |_\__ \
//   |____/ \___|\__,_|_|  \___|_| |_| |_| \_\___||___/\__,_|_|\__|___/
//                                                                     
.mobile-search-results {
	max-height: 250px;
	margin-top: 8px;
	overflow-x: hidden;
	overflow-y: auto;
	cursor: pointer;
	background: white;
}
.mobile-search-row {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: space-between;
	font-size: 15px;
	line-height: 50px;
	font-weight: 600;
	text-align: left;
	color: $search-text;
	
	.mobile-search-row-building {
		width: 100%;
		margin: 0 20px;
		background: white;
	}
	.no-results {
		color: #676767;
	}
	.no-results:hover {
		color: black;
	}
	.no-results {
		cursor: auto;
	}
}
.mobile-search-row:hover {
	background-color: #efefef;
}
