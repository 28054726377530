$text: #3C414B;

.total-metrics {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	.metrics-mini-card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-content: center;
		width: 18%;
		height: 105px;
		padding: 30px;
		background: white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
		filter: grayscale(100%);
		cursor: pointer;
		transition: ease all 0.2s;
		.metrics-image,
		.metrics-info,
		.metrics-value {
			margin: 0 auto;
			color: $text;
			opacity: 0.5;
			text-align: center;
		}
		.metrics-info {
			font-size: 15px;
			line-height: 21px;
		}
		.metrics-value {
			font-size: 18px;
			line-height: 22px;
			.metrics-label {
				font-size: 14px;
				line-height: 22px;
			}
		}
		&:hover {
			filter: none;
		}
		&.active {
			height: 113px;
			padding: 26px 30px;
			filter: none;
			.metrics-image,
			.metrics-info,
			.metrics-value {
				opacity: 1;
				font-weight: 600;
			}
		}
	}
}
