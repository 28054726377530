.datadownload-filter {
  background-color: #eeedee;
}

.datadownload-upper-search {
  display: flex;
  justify-content: space-between;
}
.datadownload-upper-search .datadownload-select-visible {
  text-align: center;
  width: 170px;
  padding: 10px;
  border-radius: 3px;
  margin-top: 22px;
  margin-right: 20px;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  background-color: #92BD3B;
  transition: ease all 0.3s;
  color: white;
}
.datadownload-upper-search .datadownload-select-visible:hover {
  background-color: #3C414B;
}

