.savings-wrapper {
  text-align: right;
  padding-top: 10px;
}

.green-dot {
  height: 10px;
  width: 10px;
  background-color: #9be34e;
  border-radius: 50%;
  display: inline-block;
  margin-left: 15px;
  margin-right: 5px;
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: #fa3939;
  border-radius: 50%;
  display: inline-block;
  margin-left: 15px;
  margin-right: 5px;
}

.savings {
  font-size: 15px;
  padding-top: 3px;
}
.savings-value-red {
  font-weight: bold;
}
