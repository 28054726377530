$electricity: #97C13D;
$header-background: #222831;
$selected: #97C13D;
$light-gray: #F5F5F5;
.mobile-building-view {
	background: $light-gray;
}
.mobile-building-content {
	background-color: #eeedee;
	min-height: 100vh;
	font-size: 18px;
	line-height: 22px;
}
.mobile-navigation-title {
	color: white;
	margin-top: 2px;
	img {
		margin-right: 10px;
	}
}
.mobile-building-header {
	height: 160px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	background-color: #666667;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
	.mobile-building-header-image {
		position: absolute;
		top: 0;
		left: 0;
		height: inherit;
		width: 100%;
		z-index: 5;
		overflow: hidden;
		opacity: 0.4;
		img {
			width: 100%;
			height: auto;
			vertical-align: middle;
		}
	}
	.mobile-building-header-overlay {
		background: $header-background;
		height: inherit;
		width: 100%;
		opacity: 0.5;
		position: absolute;
		z-index: 10;
	}
	.mobile-building-stats {
		position: relative;
		color: white;
		text-align: center;
		font-size: 14px;
		line-height: 18px;
		z-index: 100;
		&.title {
			font-size: 24px;
			line-height: 30px;
			font-weight: 600;
			margin: 12px auto;
		}
	}
}
.mobile-building-nav-bar {
	display: flex;
	justify-content: space-around;
	padding: 0 4%;
	font-size: 15px;
	line-height: 18px;
	color: white;
	background: $header-background;
	a {
		color: white;
		text-decoration: none;
	}
	.mobile-building-nav-item {
		height: 20px;
		padding: 14px 0 8px;
		opacity: 0.5;
		text-align: center;
		&.active {
			font-weight: 600;
			border-bottom: 4px solid white;
			opacity: 1;
		}
		&.housing {
			max-width: 28%;
			height: 40px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}
.mobile-building-demand {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-size: 16px;
	line-height: 18px;
	.mobile-building-demand-title {
		font-size: 20px;
		line-height: 24px;
		font-weight: 600;
	}
	.mobile-building-demand-subtitle {
		font-size: 14px;
		opacity: 0.5;
	}
	#demand-graph {
		height: 310px;
	}
	.mobile-building-demand-options {
		max-width: 400px;
		display: flex;
		justify-content: space-between;
		margin: auto;
		width: 75%;
		.mobile-demand-option {
			padding: 3px 6px;
		}
		.selected {
			font-weight: 600;
			color: $selected;
			border-bottom: 3px solid $selected;
		}
	}
}
.mobile-building-success {
	height: 140px;
	width: 95%;
	max-width: 400px;
	margin: 20px auto;
	display: flex;
	background: white;
	border-radius: 2px;
	img {
		height: 100%;
	}
	.mobile-building-success-details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 30px 20px 20px;
		margin: 0 auto;
		text-align: center;
		font-size: 15px;
		line-height: 18px;
		.mobile-building-success-detail {
			padding: 0 25px;
		}
		a {
			text-decoration: none;
			color: black;
			.mobile-building-success-button {
				height: 20px;
				width: 85%;
				padding: 11px 10px 9px;
				margin: auto;
				background: $electricity;
				color: white;
				font-weight: 600;
			}
		}
	}
}
.mobile-graph-card {
	padding: 30px 4% 20px;
	background: white;
}
