.key {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  padding-inline: 23px 42px;
  margin-bottom: 7px;
}
.key li {
  color: #3c414b;
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.legend {
  display: flex;
  padding: 0;
  column-gap: 22px;
  list-style-type: none;
}
.legend > li {
  display: flex;
  align-items: center;
}
.legend > li::before {
  content: "";
  height: 18px;
  width: 18px;
  margin-right: 6px;
}
.legend > li:first-child::before {
  border-radius: 50%;
  background-color: #98bf47;
}
.legend > li:nth-child(2)::before {
  border-radius: 50%;
  background-color: #cad93e;
}

.demand_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 66px;
}

.demand {
  text-align: center;
  width: 380px;
}
.demand > h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: #022851;
}
.demand > p {
  font-size: 20px;
  line-height: 1.2;
  color: black;
  margin-bottom: 20px;
}
.demand > p > span {
  font-style: italic;
}
.demand > button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  column-gap: 9px;
  height: 64px;
  width: 222px;
  padding: 13px 14px 13px 27px;
  border-radius: 12px;
  color: white;
  font-family: "Proxima Nova";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}
.demand > button > img {
  pointer-events: none;
  height: 38px;
  width: 38px;
}
.demand > button.even {
  padding: 13px 14px;
}
.demand > button.yellow {
  background-color: #cad93e;
}
.demand > button.green {
  background-color: #98bf47;
}

