$text-inactive: rgba(60, 65, 75, 0.7);
$text: #3C414B;
$dark-gray: #2D3138;
$light-gray: #EEEDEE;
$green: #99be4f;

.campus-metrics {
	padding-top: 40px;
	max-width: 1050px;
	width: 100%;
	margin: auto;
	font-size: 16px;
	line-height: 20px;
	.campus-card {
		background-color: white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
		padding: 30px 3% 40px;
		display: flex;
		flex-direction: row;
		flex-flow: wrap;
		object-fit: contain;
	}
	.usage-campus-metrics {
		.campus-metrics-headline {
			text-align: center;
			width: 100%;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			padding: 20px 0 30px;
		}
		.campus-metrics-description {
			width: 80%;
			margin: 0 auto;
			padding-bottom: 30px;
		}
		.campus-metrics-view-selection-options {
			display: flex;
			span {
				padding-top: 22px;
				font-weight: 600;
			}
			.campus-metrics-view-button {
				border: 2px solid $light-gray;
				border-radius: 3px;
				background-color: white;
				color: $text-inactive;
				padding: 10px 24px 8px;
				margin: 12px;
				cursor: pointer;
				transition: all ease 0.2s;
				&:hover,
				&.active {
					border: 2px solid $dark-gray;
					background-color: white;
					color: $text;
					font-weight: 600;
				}
			}
		}
		.campus-metrics-year-selection {
			padding-top: 16px;
			span {
				font-weight: 600;
			}
			.campus-metrics-year-selection-options {
				display: flex;
				flex-wrap: wrap;
				.campus-metrics-year-button {
					padding: 10px 3px 8px;
					color: $text-inactive;
					width: 7%;
					min-width: 62px;
					font-size: 14px;
					text-align: center;
					cursor: pointer;
					transition: all ease 0.2s;
					margin: 2px 1px;
					&:hover,
					&.active {
						width: 7%;
						background-color: white;
						color: $text;
						font-weight: 600;
					}
				}
			}
		}
		.campus-metrics-line {
			padding-top: 40px;
			font-weight: 600;
      		width: 100%;
			.campus-metrics-line-graph {
				width: 100%;
				padding-top: 10px;
			}
			.campus-metrics-line-data {
				display: flex;
				flex-direction: column;
				margin: auto;
				font-size: 14px;
				line-height: 22px;
				font-weight: normal;
				.line-data-energy,
				.line-data-footage {
					display: flex;
					width: 40%;
					min-width: 300px;
					margin: auto;
					span {
						width: 60%;
						svg {
							margin-right: 8px;
							vertical-align: text-top;
						}
					}
					.line-data-energy-amount,
					.line-data-footage-amount {
						width: 24%;
						text-align: right;
						color: $green;
						font-size: 15px;
						font-weight: 600;
					}
					.line-data-footage-amount {
						color: $dark-gray;
					}
				}
			}
		}
		.campus-metrics-bar {
			padding-top: 40px;
			font-weight: 600;
			.campus-metrics-bar-data {
				position: relative;
				top: -23px;
				left: 40%;
				font-weight: normal;
				font-size: 14px;
				span {
					padding-right: 12px;
				}
			}
			.campus-metrics-bar-graph {
				width: 83%;
				margin-left: 6.8%;
			}
		}
	}
}
