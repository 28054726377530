.container {
  padding: 36px 28px 34px 36px;
  border-radius: 7.5px;
  background-color: white;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.1);
}
.container > h2 {
  color: #022851;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 18px;
}
.container > img {
  width: 100%;
}

