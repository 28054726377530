$dark-gray: #2E384D;
.data-filter-section {
	color: $dark-gray;
	font-size: 14px;
	line-height: 22px;
	padding-left: 5%;
	padding-right: 5%;
	opacity: 1.0;
	margin-top: 10px;
}
.data-filter-outer-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.data-filter-inner-column {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
}
.data-filter-section-header {
	opacity: 0.75;
	color: $dark-gray;
	font-size: 14px;
	line-height: 22px;
	word-spacing: normal;
}
.data-type-group {
	.col1 {
		width: 33%;
	}
	.col2 {
		width: 33%;
	}
	.col3 {
		width: 33%;
	}
}
.date-range-outer-group {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}
.date-range-group {
	position: relative;
	margin-right: 40px;
	min-width: 180px;
	height: 78px;
	align-self: flex-start;
	.header-text {
		margin: auto 0;
		width: 90%;
	}
	.react-calendar {
		position: absolute;
		top: 30px;
		max-width: 350px;
		margin-bottom: 30px;
		z-index: 1000;
	}
}
.input-box {
	height: 42px;
	min-width: 182px;
	color: $dark-gray;
	font-size: 15px;
	line-height: 21px;
	margin-top: 8px;
	margin-bottom: 8px;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	padding-left: 10px;
}
.input-box-unselected {
	box-sizing: border-box;
	border: 1px solid #E0E7FF;
	border-radius: 5px;
	background-color:#FFFFFF;
}
.input-box-selected {
	box-sizing: border-box;
	border: 1px solid #92BD3B;
	border-radius: 5px;
	background-color: #FFFFFF;
	font-weight: bold;
}
.radio-group {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	.custom-radio-button {
		/* Hide default radio icon */
		[type=radio] {
			position: absolute;
			opacity: 0;
			width: 0;
			height: 0;
		}
		/* Position radio button */
		[type="radio"]:checked + label,
		[type="radio"]:not(:checked) + label {
			position: relative;
			padding-left: 28px;
			cursor: pointer;
			line-height: 20px;
			display: inline-block;
		}
		/* Draw unchecked radio button */
		[type="radio"]:checked + label:before,
		[type="radio"]:not(:checked) + label:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 18px;
			height: 18px;
			border: 1px solid #F2F4F7;
			border-radius: 100%;
			background: #F2F4F7;
			color: #F2F4F7;
		}
		/* Draw checked radio button */
		[type="radio"]:checked + label:after,
		[type="radio"]:not(:checked) + label:after {
			content: '';
			width: 10px;
			height: 10px;
			background: #98BF47;
			position: absolute;
			top: 5px;
			left: 5px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		/* Hide check by default */
		[type="radio"]:not(:checked) + label:after {
			opacity: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
		/* Show check */
		[type="radio"]:checked + label:after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
	.oat-checkbox {
		padding-right: 20px;
		padding-left: 10px;
		input[type="checkbox"] {
			opacity: 0;
			cursor: pointer;
		}
		/*Hide the checkmark by default*/
		input[type="checkbox"] + label::after {
			content: none;
		}
		/*Unhide the checkmark on the checked state*/
		input[type="checkbox"]:checked + label::after {
			content: "";
		}
		label {
			position: relative;
			cursor: pointer;
		}
		label::before,
		label::after {
			position: absolute;
		}
		/* unchecked */
		label::before {
			content: "";
			display: inline-block;
			height: 16px;
			width: 16px;
			border: 1px solid #E0E7FF;
			border-radius: 2px;
			background-color: #FFFFFF;
			/* Position checkmark inside Outer-box*/
			left: -29px;
		}
		/* checked */
		label::after {
			content: "";
			display: inline-block;
			height: 6px;
			width: 9px;
			border-left: 2px solid;
			border-bottom: 2px solid;
			transform: rotate(-45deg);
			// position checkmark in box
			left: -25px;
			top: 1px;
		}
	}
}
.radiobox label {
	position: relative;
	margin-left: 10px;
	opacity: 0.75;
}
.selected-data-items {
	border: 1px solid #E0E7FF;
	border-radius: 5px;
	background-color: #FFFFFF;
	color: #3C414B;
	font-size: 15px;
	font-weight: 600;
	line-height: 45px;
}
.selected-data-item {
	padding-left: 3%;
	padding-right: 3%;
	border-bottom: 1px solid #E0E7FF;
	img {
		padding-left: 15px;
		vertical-align: middle;
	}
	.selected-data-remove {
		float: right;
		color: $dark-gray;
		font-size: 14px;
		font-weight: 100;
		opacity: 0.5;
		cursor: pointer;
		transition: ease all 0.3s;
		span {
			font-size: 12px;
			border: 1px solid black;
			padding: 0 3px;
			margin-left: 3px;
			margin-top: -2px;
		}
		&:hover {
			opacity: 1;
			font-weight: 600;
		}
	}
}
.required {
	color: #F41F1F;
}
.helpicon {
	display: flex;
	padding-left: 10px;
	position: relative;
	img {
		height: 20px;
		width: 20px;
		vertical-align: middle;
		opacity: 0.3;
		transition: ease all 0.3s;
		cursor: pointer;
	}
	.helpicon-hovers {
		pointer-events: none;
		display: flex;
		align-items: center;
		position: absolute;
		width: 240px;
		height: 75px;
		left: 36px;
		top: -38px;
		padding: 10px 20px;
		color: white;
		background: $dark-gray;
		border-radius: 10px;
		z-index: 100;
		opacity: 0;
		transition: ease all 0.3s;
		transition-delay: 50ms;
		&.wide {
			width: 450px;
		}
		&::after {
			content: " ";
			position: absolute;
			top: 50%;
			left: 0%;
			margin-left: -10px;
			margin-top: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: transparent $dark-gray transparent transparent;
		}
	}
	&:hover {
		img {
			opacity: 1;
		}
		.helpicon-hovers {
			transition-delay: 700ms;
			opacity: 1;
		}
	}
}
.data-filter-entry-text {
	display: flex;
	justify-content: space-between;
	width: 90%;
	.data-filter-name,
	.data-filter-company {
		width: 45%;
		.header-text {
			margin-bottom: 10px;
		}
		input[type="text"] {
			height: 38px;
			width: 100%;
			border: 1px solid #E0E7FF;
			border-radius: 5px;
			background-color: #FFFFFF;
			padding-left: 26px;
			color: $dark-gray;
			font-size: 15px;
			line-height: 18px;
		}
	}
}
.emailandsend-group {
	margin-right: 40px;
	.header-text {
		width: 90%;
		margin-bottom: 10px;
	}
	input[type="text"] {
		height: 38px;
		width: 438px;
		border: 1px solid #E0E7FF;
		border-radius: 5px;
		background-color: #FFFFFF;
		padding-left: 26px;
		color: $dark-gray;
		font-size: 15px;
		line-height: 18px;
	}
}
.calendarbox {
	font-weight: 600;
}
.download-button {
	display: flex;
	align-items: center;
	text-align: center;
	vertical-align: middle;
	height: 50px;
	width: 200px;
	border-radius: 3px;
	background-color: #F5F5F5;
	margin-top: 44px;
	margin-right: 6%;
	cursor: pointer;
	span {
		color: #3C414B;
		font-size: 15px;
		line-height: 27px;
		opacity: 0.5;
		margin-left: 45px;
		font-weight: 600;
	}
	&.active {
		background-color: #92BD3B;
		transition: ease all 0.3s;
		span {
			color: white;
			opacity: 1;
		}
		&:hover {
			background-color: #3C414B;
		}
	}
}
.dropdown-time-interval {
	border: 1px solid #E0E7FF;
	color: $dark-gray;
	border-radius: 5px;
	width: 196px;
	height: 38px;
	background-color: #FFFFFF;
	-webkit-appearance: none;
	-moz-appearance: none;
	letter-spacing: normal;
	font-size: 15px;
	font-weight: 500;
	line-height: 38px;
	padding-left: 12px;
	position: relative;
	margin: 8px 0;
	span {
		opacity: .5;
	}
	ul {
		background-color: #FFFFFF;
	}
}
.dropdown-time-interval-expanded {
	border: 1px solid #E0E7FF;
	color: $dark-gray;
	border-radius: 5px;
	width: 196px;
	height: 38px;
	background-color: #F5F5F5;
	-webkit-appearance: none;
	-moz-appearance: none;
	letter-spacing: normal;
	font-size: 15px;
	font-weight: 500;
	line-height: 38px;
	padding-left: 12px;
	position: relative;
	margin: 8px 0;
	ul {
		background-color: #F5F5F5;
	}
}
.dropdown-time-interval-expanded > div > span {
	opacity: .75;
}
.dropdown-time-interval-highlight {
	border: 1px solid #92BD3B;
	color: $dark-gray;
	border-radius: 5px;
	width: 196px;
	height: 38px;
	background-color: #FFFFFF;
	-webkit-appearance: none;
	-moz-appearance: none;
	letter-spacing: normal;
	font-size: 15px;
	font-weight: bold;
	line-height: 38px;
	padding-left: 12px;
	position: relative;
	margin: 8px 0;
	ul {
		background-color: #FFFFFF;
	}
}