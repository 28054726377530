$background-gray: #2C3037;
$dark-gray: #212832;
$light-gray: #F5F5F5;
.ewc-mobile-wrapper {
	height: 400px;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-bottom: 200px solid $background-gray;
	.ewc-mobile-header {
		height: 70%;
		margin: 0 auto 10px;
		padding: 0 5% 15px;
		display: flex;
		max-width: 900px;
		justify-content: space-between;
		.ewc-mobile-header-logo {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: auto;
			.ewc-mobile-dates {
				text-align: center;
				font-size: 22px;
				color: white;
				padding-top: 15px;
			}
			img {
				width: 85%;
				min-width: 260px;
				height: auto;
				margin-top: 30px;
				align-self: center;
			}
		}
	}
	.ewc-mobile-header-detail {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 265px;
		.ewc-mobile-header-detail-box {
			width: 60%;
			padding: 15px 25px 13px;
			background: $dark-gray;
			border-radius: 3px;
			align-self: center;
			text-align: center;
			color: $light-gray;
			font-size: 14px;
			line-height: 18px;
			.ewc-mobile-header-detail-title {
				font-weight: 600;
				font-style: italic;
				opacity: 0.7;
			}
			.ewc-mobile-header-detail-description {
				margin: 7px auto 0;
				opacity: 0.7;
			}
		}
		.ewc-mobile-header-tips {
			width: 75%;
			margin: 30px auto 0;
			.ewc-mobile-header-tip {
				display: flex;
				margin-bottom: 20px;
				img {
					&.ewc-mobile-header-tip-light {
						margin: 0 25px 0 5px;
					}
					& {
					margin-right: 20px;
					}
				}
				.header-tip-detail {
					color: white;
					font-size: 14px;
					line-height: 18px;
					.header-tip-header {
						font-weight: 600;
						&.electricity {
							color: #92BD3B;
						}
						&.water {
							color: #32BBD5;
						}
					}
				}
			}
		}
	}
}
