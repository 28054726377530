.commodity-breakdown {
  display: flex;
  height: 590px;
  width: auto;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.12);
  background: white;
  padding: 35px;
  margin-top: 50px;
  flex-direction: row;
  flex-wrap: wrap;
}
.commodity-breakdown .commodity-title {
  font-size: 16px;
  font-weight: bold;
  align-self: center;
  padding-bottom: 35px;
}
.commodity-breakdown .commodity-graph {
  height: 430px;
}
.commodity-breakdown .commodity-graph .commodity-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.commodity-breakdown .commodity-graph .commodity-select .commodity-show-label {
  font-size: 14px;
  margin: auto 10px auto 20px;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect {
  width: 100%;
  background-color: rgba(240, 240, 240, 0);
  position: relative;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect .ReactA11ySelect__button {
  width: 185px;
  height: 45px;
  padding: 8px 14px;
  position: relative;
  margin-left: 0px;
  margin-right: 10px;
  background: white;
  font-size: 14px !important;
  font-family: Proxima Nova, Lucida Grande, Lucida Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 2px solid #EEEDEE;
  border-radius: 3px;
  box-shadow: none;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect .ReactA11ySelect__button:hover {
  border: 2px solid #2D3138;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect .ReactA11ySelect__button span {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect .ReactA11ySelect__button span div {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect .ReactA11ySelect__button span div.text {
  padding-left: 5px;
  margin-top: -6px;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect .ReactA11ySelect__button span div.main-text {
  font-weight: bold;
  color: black;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul {
  z-index: 3;
  box-shadow: none;
  width: 184px;
  margin-top: -1px;
  border-radius: 3px;
  background: white;
  position: absolute;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul div {
  display: flex;
  flex-direction: row;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li {
  outline: 0;
  box-sizing: border-box;
  font-size: 14px !important;
  font-family: Proxima Nova, Lucida Grande, Lucida Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 1px solid #EEEDEE;
  width: 100%;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li div:nth-of-type(1) div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li div:nth-of-type(1) div.text {
  padding-left: 35px;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li div:nth-of-type(1) div.main-text {
  color: #3C414B;
  padding-left: 5px;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li div:nth-of-type(1) div.main-text:hover {
  color: black;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li div:nth-of-type(1) div:nth-of-type(1) {
  margin-bottom: 2px;
}
.commodity-breakdown .commodity-graph .commodity-select .ReactA11ySelect ul li div:nth-of-type(1) div:nth-of-type(2) {
  display: none;
}
@media (min-width: 769px) {
  .commodity-breakdown .commodity-graph {
    width: 46%;
    margin-right: 1%;
  }
  .commodity-breakdown .commodity-graph .commodity-select {
    width: 200%;
  }
  .commodity-breakdown .commodity-stats {
    width: 53%;
  }
  .commodity-breakdown .commodity-csv {
    left: 68%;
  }
}
@media (min-width: 1080px) {
  .commodity-breakdown .commodity-graph {
    width: 55%;
    margin-right: 8%;
  }
  .commodity-breakdown .commodity-graph .commodity-select {
    width: 100%;
  }
  .commodity-breakdown .commodity-stats {
    width: 35%;
  }
  .commodity-breakdown .commodity-csv {
    left: 79%;
  }
}
.commodity-breakdown .commodity-stats {
  height: 480px;
  font-size: 13px;
  line-height: 24px;
}
.commodity-breakdown .commodity-stats .commodity-header,
.commodity-breakdown .commodity-stats .commodity-footer {
  color: #3C414B;
  display: flex;
  align-items: stretch;
}
.commodity-breakdown .commodity-stats .commodity-data {
  display: flex;
}
.commodity-breakdown .commodity-stats .column1,
.commodity-breakdown .commodity-stats .column2,
.commodity-breakdown .commodity-stats .column3,
.commodity-breakdown .commodity-stats .column4 {
  padding: 2px 5px;
  border-top: solid 1px #EEEDEE;
  font-size: 13px;
  line-height: 23px;
}
.commodity-breakdown .commodity-stats .column1 {
  width: 20%;
}
.commodity-breakdown .commodity-stats .column2 {
  width: 45%;
  text-align: right;
  padding-right: 10px;
}
.commodity-breakdown .commodity-stats .column3 {
  width: 35%;
  text-align: right;
}
.commodity-breakdown .commodity-stats .column1,
.commodity-breakdown .commodity-stats .column2 {
  color: #3C414B;
}
.commodity-breakdown .commodity-stats .commodity-stats-title {
  padding: 6px 0 88px;
  font-size: 16px;
  font-weight: bold;
}
.commodity-breakdown .commodity-stats .commodity-header {
  color: #3C414B;
  margin-bottom: 10px;
  padding: 0 4px;
}
.commodity-breakdown .commodity-stats .commodity-header .column1,
.commodity-breakdown .commodity-stats .commodity-header .column2,
.commodity-breakdown .commodity-stats .commodity-header .column3,
.commodity-breakdown .commodity-stats .commodity-header .column4 {
  font-size: 14px;
  border: none;
  padding-right: 5px;
}
.commodity-breakdown .commodity-stats .commodity-data {
  font-size: 15px;
  line-height: 24px;
  padding: 0 4px;
}
.commodity-breakdown .commodity-stats .commodity-footer {
  background-color: #EEEDEE;
  height: 44px;
  margin-top: 8px;
  padding: 0 4px;
}
.commodity-breakdown .commodity-stats .commodity-footer .column1 {
  color: black;
  margin: auto 0;
  font-size: 14px;
  text-align: left;
  border: none;
}
.commodity-breakdown .commodity-stats .commodity-footer .column2,
.commodity-breakdown .commodity-stats .commodity-footer .column3 {
  margin: auto 0;
  color: black;
  font-size: 14px;
  text-align: right;
  font-weight: 600;
  border: none;
}
.commodity-breakdown .commodity-stats .disclaimer {
  font-size: 12px;
  text-align: right;
  color: #3C414B;
}
.commodity-breakdown .csv-div {
  width: 150px;
  margin-left: 800px;
}
.commodity-breakdown .csv-div .commodity-csv {
  position: relative;
  bottom: 0px;
}
.commodity-breakdown .csv-div .commodity-csv button {
  margin-top: 55px;
  width: 150px;
  height: 40px;
  background-color: white;
  border: solid 1px #EEEDEE;
  outline: none;
  font-size: 13px;
  color: #3C414B;
  letter-spacing: 1px;
  padding: 0 5px;
}
.commodity-breakdown .csv-div .commodity-csv button svg {
  float: right;
  opacity: 0.3;
}
.commodity-breakdown .csv-div .commodity-csv button:hover {
  background-color: #EEEDEE;
  cursor: pointer;
  color: #3C414B;
  font-weight: 600;
  border: solid #EEEDEE 2px;
}
.commodity-breakdown .csv-div .commodity-csv button:hover svg {
  opacity: 1;
}

.csv-div {
  width: 100%;
  height: 40px;
  margin-top: 60px;
}
.csv-div .commodity-csv {
  width: 150px;
  max-width: 160px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
}
.csv-div .commodity-csv .commodity-side-button {
  width: 100%;
  height: 25px;
  border: solid 2px #EEEDEE;
  border-radius: 2px;
  margin: 10px;
  padding-top: 10px;
  cursor: pointer;
  position: absolute;
  font-weight: 500;
  top: -9px;
  right: 105px;
  display: flex;
  color: rgba(45, 49, 56, 0.7);
}
.csv-div .commodity-csv .commodity-side-button img {
  width: 11px;
  height: 13px;
  margin-right: 10px;
  margin-left: 15px;
  padding-top: 2px;
}
.csv-div .commodity-csv .commodity-side-button:hover,
.csv-div .commodity-csv .commodity-side-button.active {
  background-color: white;
  border: solid 2px #2D3138;
  color: #3C414B;
  font-weight: 600;
}
.csv-div .csv {
  padding-top: 5px;
}
.csv-div .csv a {
  color: #3C414B;
  text-decoration: none;
  font-size: 14px;
}

