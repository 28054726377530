$bubble-background: #F6F6F6;
$button-background: #2D3138;
$background-gray: #EEEDEE;
$text: #3C414B;
$button-text: rgba(45, 49, 56, 0.7);
$text-inactive: rgba(60, 65, 75, 0.5);
$shadow: rgba(0, 0, 0, 0.16);

.building {
	min-height: 100vh;
}
.building-content {
	background-color: $background-gray;
	min-height: calc(100vh - 410px);
}
.building-demand,
.building-usage,
.building-comparison,
.building-ewc-ranking {
	padding-top: 40px;
	max-width: 1050px;
	margin: 30px auto 0;
	.graph-card {
		background-color: white;
		box-shadow: 0 2px 4px 0 $shadow;
		padding: 30px 3% 40px 3%;
		margin-bottom: 40px;
	}
	.demand-info-icon{
		position: absolute;
		z-index: 1;
		margin-left: 57px;
		margin-top: -2px;
		cursor: pointer;
	}
	.demand-speech-bubble-ds {
		display: none;
		background: $bubble-background;
		border: 3px solid $background-gray;
		-webkit-border-radius: 3px;
				border-radius: 3px;
		-webkit-box-shadow: 0px 2px 4px $shadow;
				box-shadow: 0px 2px 4px $shadow;
		font-size: 14px;
		line-height: 17px;
		color: $text;
		margin: -65px 0 0 89px;
		max-width: 400px;
		padding: 5px 15px 0px;
		width: 240px;
		height: 128px;
		position: absolute;
		z-index: 1;
	}
	
	.demand-speech-bubble-ds p {
		margin-bottom: -5px;
	}
	.demand-speech-bubble-ds p:last-of-type {
		margin-bottom: 0;
	}
	
	.demand-speech-bubble-ds-arrow {
		border-left: 21px solid transparent;
		border-top: 17px solid $background-gray;
		bottom: 48px;
		position: absolute;
		right: 270px;
	}
	.demand-speech-bubble-ds-arrow::before {
		border-left: 20px solid transparent;
		border-bottom: 15px solid $bubble-background;
		bottom: 18px;
		content: "";
		position: absolute;
		right: 0px;
	}
	.demand-speech-bubble-ds-arrow::after {
		border-left: 20px solid transparent;
		border-top: 15px solid $bubble-background;
		bottom: 3px;
		content: "";
		position: absolute;
		right: 0px;
	}

	.demand-info-icon:hover + .demand-speech-bubble-ds{
		display: inline;
	}
	
	@media (max-width: 850px) {
		padding: 20px 0 0;
		max-width: 100%;
		.graph-card {
			padding: 30px 0 20px;
			box-shadow: 0 0 4px 0 $shadow;
		}
	}
}
.building-comparison, .building-usage{
	margin-top: 0px;
}
.building-footer {
	padding: 40px 6%;
	max-width: 1120px;
	margin: auto;
}
.building-graph {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.building-chart {
		height: 400px;
	}
	.building-side-options {
		text-align: center;
		line-height: 18px;
		margin-top: -25px;
		.building-side-button {
			width: 100%;
			max-width: 150px;
			height: 36px;
			border: solid 2px $background-gray;
			border-radius: 2px;
			box-sizing: border-box;
			margin: 10px;
			margin-left: 30px;
			padding: 9px 0;
			cursor: pointer;
			background: white;
			color: $button-text;
			font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size: 15px;
			font-weight: 500;
		}
		.building-side-button:hover,
		.building-side-button.active,
		.building-side-button[aria-pressed="true"] {
			border: solid 2px $button-text;
			background-color: white;
			color: $text;
			font-weight: 600;
			&.not-ready {
				background-color: white;
				border: solid 1px $background-gray;
				font-weight: normal;
				cursor: not-allowed;
			}
		}
		.building-side-oat input {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
		}
		.building-side-oat .building-side-oat-label:before {
			content: '';
			padding: 0;
			display: inline-block;
			height: 16px;
			width: 16px;
			border: solid 1px $background-gray;
			border-radius: 0;
			font-size: 12px;
			vertical-align: middle;
		}
		.building-side-oat input + .building-side-oat-label:before {
			color: white;
			background-color: white;
			margin-right: 7px;
			border-radius: 3px;
		}
		.building-side-oat input:checked + .building-side-oat-label:before {
			background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
		}
		.building-side-oat input + .building-side-oat-label {
			font-size: 15px;
			color: $text-inactive;
		}
		.building-side-oat input:checked + .building-side-oat-label {
			color: black;
		}
	}
	.building-commodities-wrap{
		.building-commodities {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			width: 90%;
			padding: 0;
			margin-left: 15px;
			.building-side-oat-label-spacer {
				padding-left: 31%;
			}
			.building-commodity {
				display: flex;
				font-size: 16px;
				font-weight: 500;
				width: 150px;
				margin-top: 12px;
			}
			.building-commodity {
				text-align: left;
				position: relative;
				height: 21px;
				width: 100%;
				line-height: 21px;
				display: block;
				cursor: pointer;
			}
			.building-commodity input {
				opacity: 0;
				top: 0;
				left: 0;
				cursor: pointer;
			}
			.building-commodity .building-commodity-label:before {
				content: '';
				padding: 0;
				display: inline-block;
				height: 16px;
				width: 16px;
				border: solid 1px $background-gray;
				font-size: 12px;
				vertical-align: middle;
			}
			.building-commodity input + .building-commodity-label:before {
				color: white;
				background-color: white;
				margin-right: 7px;
				border-radius: 3px;
			}
			.building-commodity input:checked + .building-commodity-label.electricity:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.building-commodity input:checked + .building-commodity-label.gas:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%238D67D6' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.building-commodity input:checked + .building-commodity-label.steam:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23f69222' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.building-commodity input:checked + .building-commodity-label.hotWater:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23FFC149' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.building-commodity input:checked + .building-commodity-label.chilledWater:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%231caac8' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.building-commodity input:checked + .building-commodity-label.solar:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23F8D81C' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.building-commodity input:checked + .building-commodity-label.oat:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.building-commodity input + .building-commodity-label {
				font-size: 15px;
				color: $text-inactive;
			}
			.building-commodity input:checked + .building-commodity-label {
				color: black;
			}
		}
	}
	
	@media (min-width: 851px) {
		
		.building-chart {
			width: 72%;
		}
		.building-side-options {
			width: 27%
		}
		.building-commodities {
			width: 70%;
			padding: 10px 0 0 2%;
			.building-commodity input + .building-commodity-label:before {
				margin-right: 4px;
			}
		}
	}
	@media (min-width: 1080px) {
		.building-chart {
			width: 80%;
		}
		.building-side-options {
			width: 19%
		}
		.building-commodities {
			width: 62%;
			padding: 10px 5% 0 12%;
			.building-commodity input + .building-commodity-label:before {
				margin-right: 15px;
			}
		}
	}
	.csv {
		padding-top: 5px;
		width: 100%;
		max-width: 150px;
		margin-top: 65px;
		.building-side-button{
			display: flex;
			font-size: 14px;
			color: $button-text;
			img{
				width: 11px;
				height: 13px;
				margin-left: 10px;
				margin-right: 10px;
				padding-top: 2px;
			}
		}
		a {
			color: $text;
			text-decoration: none;
		}
	}
	.csv-usage{
		padding-top: 5px;
		width: 100%;
		max-width: 150px;
		margin-top: 31px;
	}
	
	//    _   _ _       _          _                _
	//   | | | (_) __ _| |__   ___| |__   __ _ _ __| |_ ___
	//   | |_| | |/ _` | '_ \ / __| '_ \ / _` | '__| __/ __|
	//   |  _  | | (_| | | | | (__| | | | (_| | |  | |_\__ \
	//   |_| |_|_|\__, |_| |_|\___|_| |_|\__,_|_|   \__|___/
	//            |___/
	.highcharts-root {
		font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		.highcharts-axis-labels {
			letter-spacing: 1px;
			text-transform: uppercase;
		}
	}
}
