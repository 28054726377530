// relevant colors for each building type
$lab: #2a575f;
$office: #93bb45;
$community: #107d4b;
$classroom: #3ea0b7;
$housing: #3778BC;
$light-grap: #F5F5F5;
$gray: #CDCDCF;
$dark-gray: #2D3138;
// parent wrapper for stats component; hides to left side of view window by default
.building-stats-parent {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100vh;
  background: white;

  font-size: 15px;

  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 30;

  &.hide {
    left: -410px;
  }

  // relevant colors for each building type
  &.classroom {
    .svg-dot {
      circle {
        fill: $classroom;
      }
    }
    button.building-data-button {
      background: $classroom;
    }
  }
  &.lab {
    .svg-dot {
      circle {
        fill: $lab;
      }
    }
    button.building-data-button {
      background: $lab;
    }
  }
  &.housing {
    .svg-dot {
      circle {
        fill: $housing;
      }
    }
    button.building-data-button {
      background: $housing;
    }
  }
  &.community {
    .svg-dot {
      circle {
        fill: $community;
      }
    }
    button.building-data-button {
      background: $community;
    }
  }
  &.office {
    .svg-dot {
      circle {
        fill: $office;
      }
    }
    button.building-data-button {
      background: $office;
    }
  }
}

// hover when mouseEnter on bar graph
// hidden by default and positioned absolutely
// repositioned next to bar graph on mouseEnter via JavaScript
.graph-hover-wrapper {
  position: absolute;

  z-index: 50;

  opacity: 0;
  transition: opacity 0.3s;

  display: flex;
  flex-direction: row;

  .graph-hover-tip-container {
    width: 20px;
    height: 150px;
    overflow: hidden;
    pointer-events: none;
    position: relative;

    .graph-hover-tip {
      width: 17px;
      height: 17px;
      padding: 1px;
      margin: -10px auto 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

      position: absolute;
      top: 50%;
      left: 50%;

      background: white;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
      border: solid 1px #e8e8e8;
    }
  }

  .graph-hover-content {
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);

    background: white;
    height: 160px;
    width: 180px;
    box-sizing: border-box;

    padding: 22px;

    border-top: solid 1px #e8e8e8;
    border-right: solid 1px #e8e8e8;
    border-bottom: solid 1px #e8e8e8;

    font-size: 14px;

    div {
      margin-top: 2px;
    }

    .building-type {
      text-transform: capitalize;
    }

    .building-type, .this-building, .em-dash {
      color: #949494;
    }

    .type-average {
      font-weight: bold;
    }

    .percent {
      font-weight: bold;
      .below-average {
        color: #93bb45;
      }
      .above-average {
        color: red;
      }
    }
  }
}

// building-stats content wrapper
.building-stats {
  overflow-y: auto;
  overflow-x: hidden;

  // X icon in upper-right corner
  .close-button {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid $dark-gray;
    text-align: center;
    font-size: 24px;
    background-color: white;
    top: 15px;
    right: 25px;
    cursor: pointer;
    transition: transform 200ms ease;
    &:hover {
      background-color: $dark-gray;
      color: white;
      transform: rotate(-90deg);
      transition: transform 200ms ease;
    }
  }

  // triangle SVG icon in upper-left corner
  .building-type-icon {
    height: 96px;
  }

  // building name (e.g. "Plant and Environmental Sciences")
  h1 {
    margin-top: 0;
  }

  // padding for each content wrapper
  .stats-content-wrapper-1, .stats-content-wrapper-2 {
    padding-left: 32px;
    padding-right: 32px;
    &.top-list {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  // first content wrapper, before .building-data-button <button>
  .stats-content-wrapper-1 {
    //if .eui-selected is parent class, set flex order with .eui first
    &.eui-selected {
      .eui {
        order: 1;
        .switch-link {
          display: none;
        }
      }
      .bar-graph {
        order: 2;
      }
      .annual-usage {
        order: 3;
        svg {
          display: none;
        }
      }
    }
    //if .annualUsage-selected is parent class, set flex order with .annual-usage first
    &.annualUsage-selected {
      .eui {
        order: 3;
        svg {
          display: none;
        }
      }
      .bar-graph {
        order: 2;
      }
      .annual-usage {
        order: 1;
        .switch-link {
          display: none;
        }
      }
    }

    .annual-usage, .eui {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .usage-value {
        margin-right: 15px;
      }
      .switch-link {
        font-size: 14px;
        color: #949494;

        // button is re-styled to look like a text link
        button, button:focus, button:active {
          /* Remove all decorations to look like normal text */
          background: none;
          border: none;
          display: inline;
          font: inherit;
          margin: 0;
          padding: 0;
          /* Additional styles to look like a link */
          color: #949494;
          cursor: pointer;
          text-decoration: underline;
        }
        /* Remove extra space inside buttons in Firefox */
        button::-moz-focus-inner {
          border: none;
          padding: 0;
        }
      }
    }
  }

  // each stats item is a <li>; list style is none to remove bullet points and indents
  ul {
    display: flex;

    list-style: none;
    padding-left: 0;
  }

  // first content wrapper's list, before .building-data-button <button>
  .stats-content-wrapper-1 ul {
    flex-direction: column;
  }

  // second content wrapper's list, after .building-data-button <button>
  .stats-content-wrapper-2 ul {
    flex-direction: row;
    flex-wrap: wrap;

    li {
      width: 50%;
      margin-bottom: 12px;
    }
  }

  // value title, always light grey before bold .value element
  .value-title {
    color: #808080;
    margin-bottom: 5px;
  }

  // number value, always bold after light grey .value-title element
  .value {
    // font-weight: 100;
    font-weight: bold;

    display: flex;
    flex-direction: row;

    .svg-dot {
      margin-right: 8px;
    }

    .eui-units {
      font-size: 13px;
      margin-left: 2px;
      margin-top: 1px;
    }
  }

  // eui / annual usage bar graph containing <li> element
  .bar-graph {
    height: 150px;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .view-ranking {
    background-color: $light-grap;
    display: flex;
    height: 44px;
    justify-content: space-between;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    vertical-align: middle;
    line-height: 44px;
    padding: 0 35px;
    cursor: pointer;
    span {
      color: $dark-gray;
      font-weight: 600;
      letter-spacing: 0.6px;
    }
    img {
      height: 60%;
      margin-top: 8px;      
      transform: rotate(180deg);
      transition: ease 0.4s all;

    }
    &:hover {
      img {
        margin-right: -8px;
      }
    }
  }

  // primary/secondary usages, with a space ("\00a0") after the name and before the percentage
  .primary-usage, .secondary-usage {
    text-transform: capitalize;
    &:after {
      content: "\00a0";
    }
  }

  // always fill width of sidebar for these elements
  .annual-cost, .description-wrapper, .certification-wrapper {
    width: 100% !important;
  }
  .green-building {
    padding-left: 50%;
  }

  // different description for each type of building, e.g. Lab
  .building-type-description {
    line-height: 1.67;
  }

  // div around certification names and logos (LEED / BREAM)
  .certification-wrapper {
    span {
      text-transform: capitalize;

      &:nth-of-type(2):before {
        content: ", "
      }
    }

    img {
      margin-right: 25px;
    }
  }

  // "View Building Data" <button>
  button.building-data-button {
    width: 100%;
    height: 55px;
    margin: 15px auto;

    border-radius: 1px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border: solid 1px #000000;

    font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: white;

    cursor: pointer;

    transition: background 0.3s;

    &:hover {
      background: #2D323C;
      transition: background 0.3s;
    }
  }

  // Highcharts
  .highcharts-root {
    font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

    .highcharts-plot-lines-0 {
      stroke-width: 1px;
      transition: stroke-width 0.3s;
    }

    &:hover {
      .highcharts-plot-lines-0 {
        path {
          stroke-width: 2px;
          transition: stroke-width 0.3s;
        }
      }
    }
  }
  .highcharts-xaxis .highcharts-tick {
    stroke-width: 0;
  }
  .highcharts-xaxis .highcharts-axis-line {
    stroke-width: 0;
  }
}