.heading-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.heading-buildingProject,
.heading-electricity,
.heading-steam,
.heading-chilledWater,
.heading-monthlySavings {
  flex: 1;
  vertical-align: middle;
  padding-top: 5px;
  background: #eeedee;
  text-align: center;
}

.heading-buildingProject,
.heading-monthlySavings {
  border: 1px solid #d8d8d8;
}

.heading-electricity {
  background-color: #98be48;
  border-bottom: 1px solid #d8d8d8;
}

.heading-steam {
  background-color: #f49233;
  border-bottom: 1px solid #d8d8d8;
}

.heading-chilledWater {
  background-color: #25a9c6;
  border-bottom: 1px solid #d8d8d8;
}
