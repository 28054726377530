.datadownload-started {
  display: flex;
  justify-content: space-between;
  max-width: 750px;
  margin: auto;
  padding: 40px 12% 80px;
}
.datadownload-started .datadownload-started-image {
  width: 30%;
}
.datadownload-started .datadownload-started-image .datadownload-started-image-background {
  background-color: white;
  border: 9px solid #e6e6e6;
  border-radius: 50%;
  width: 90%;
  height: 0;
  padding-bottom: 90%;
  position: relative;
  animation: spin 10s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.datadownload-started .datadownload-started-image .datadownload-started-image-background .temp {
  width: 75%;
  padding-bottom: 75%;
  border-radius: 50%;
  margin: auto;
  margin-top: 13%;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon {
  width: 12%;
  position: absolute;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon.electricity {
  top: 13%;
  left: 44%;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon.steam {
  top: 26%;
  left: 69.5%;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon.chilledWater {
  top: 53%;
  left: 74.5%;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon.gas {
  top: 74%;
  left: 58%;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon.solar {
  top: 74%;
  left: 30%;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon.domesticWater {
  top: 53%;
  left: 13.5%;
}
.datadownload-started .datadownload-started-image .datadownload-started-commodity-icon.heatingGas {
  top: 26%;
  left: 18.5%;
}
.datadownload-started .datadownload-started-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.datadownload-started .datadownload-started-content .started-content-item {
  font-size: 14px;
  line-height: 17px;
  line-height: 20px;
  color: #2D3138;
}
.datadownload-started .datadownload-started-content .started-content-item.header {
  font-weight: bold;
  padding-bottom: 10px;
}

