$background: #F6F6F6;
$border: #EEEDEE;
$electricity: #98BF47;
$steam: #FFC149;
$cooling: #1caac8;
$gas: #8D67D6;
$solar: #F8D81C;
$text-inactive: rgba(60, 65, 75, 0.5);
$text: #3C414B;

.demand-whats-powering {
	height: auto;
	margin-top: 50px;
	max-width: 1050px;
	margin: auto;
	margin-top: 50px;

	.demand-whats-powering-title {
		padding: 15px;
		font-size: 18px;
		line-height: 22px;
		font-weight: 600;
		text-align: center;
	}
	@media (min-width: 769px) {
		.demand-whats-powering-options {
			display: flex;
			justify-content: space-evenly;
			width: 100%;
			margin: 20px auto;
			.demand-whats-powering-button {
				display: flex;
				justify-content: center;
				width: 24%;
				height: 20px;
				padding: 15px 0;
				border: solid 2px $border;
				border-radius: 3px;
				font-size: 13px;
				color: $text-inactive;
				text-align: center;
				line-height: 20px;
				cursor: pointer;
				transition: ease 0.3s all;
				.demand-whats-powering-icon {
					margin-left: -7%;
					margin-top: -2%;
					padding-right: 0;
					visibility: hidden;
					img {
						width: 90%;
					}
				}
				&:hover,
				&.active {
					color: black;
					font-weight: 600;
					.demand-whats-powering-icon {
						visibility: visible;
					}
				}
			}
			.demand-whats-powering-button.electricity:hover,
			.demand-whats-powering-button.electricity.active {
				background-color: $electricity;
			}
			.demand-whats-powering-button.gas:hover,
			.demand-whats-powering-button.gas.active {
				background-color: $gas;
			}
			.demand-whats-powering-button.steam:hover,
			.demand-whats-powering-button.steam.active {
				background-color: $steam;
			}
			.demand-whats-powering-button.chilledWater:hover,
			.demand-whats-powering-button.chilledWater.active {
				background-color: $cooling;
			}
			.demand-whats-powering-button.solar:hover,
			.demand-whats-powering-button.solar.active {
				background-color: $solar;
			}
		}
	}
	@media (min-width: 1080px) {
		.demand-whats-powering-options {
			width: 85%;
			.demand-whats-powering-button {
				padding: 10px 0;
				.demand-whats-powering-icon {
					margin-left: -18%;
					margin-top: -2%;
					padding-right: 4%;
				}
			}
		}
	}
	@media (min-width: 769px) {
		.demand-whats-powering-description {
			width: 80%;
			margin: auto;
			padding: 20px;
			font-size: 16px;
			line-height: 22px;
			white-space: pre-wrap;
			.demand-whats-powering-details {
				display: flex;
				align-content: space-between;
				padding-top: 20px;
				.demand-whats-powering-details-column {
					display: flex;
					flex-direction: column;
					width: 49%;
					color: $text;
					span {
						font-weight: 600;
						color: black;
						line-height: 22px;
					}
				}
			}
		}
	}
	@media (min-width: 1080px) {
		.demand-whats-powering-description {
			width: 70%;
			.demand-whats-powering-details {
				.demand-whats-powering-details-column {
					line-height: 40px;
				}
			}
		}
	}
	.demand-whats-powering-examples {
		display: flex;
		justify-content: space-between;
		width: 85%;
		margin: auto;
		padding: 20px 0;
		&.solar {
			justify-content: center;
		}
		.demand-whats-powering-case {
			width: 48%;
			.demand-whats-powering-case-top {
				background-color: $background;
				padding: 0 10px 10px;
				margin-bottom: 20px;
				.demand-whats-powering-case-title {
					margin: auto;
					font-weight: 600;
					text-align: center;
					padding: 20px 0;
				}
				img {
					width: 100%;
				}
			}
			.demand-whats-powering-case-description {
				width: 90%;
				margin: auto;
				line-height: 22px;
			}
		}
	}
}
