.compare-building-types {
  height: fit-content;
  margin-top: 50px;
}
.compare-building-types .compare-building-types-title {
  padding: 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
}
.compare-building-types .compare-building-types-options {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 20px auto;
}
.compare-building-types .compare-building-types-options .compare-building-types-button {
  width: 18%;
  height: 20px;
  padding: 10px 0;
  border: solid 1px #dbdbdb;
  font-size: 13px;
  color: #b2b2b2;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  transition: ease 0.3s all;
}
.compare-building-types .compare-building-types-options .compare-building-types-button:hover, .compare-building-types .compare-building-types-options .compare-building-types-button.active {
  color: white;
  font-weight: 600;
}
.compare-building-types .compare-building-types-options .compare-building-types-button.lab:hover,
.compare-building-types .compare-building-types-options .compare-building-types-button.lab.active {
  background-color: #285760;
}
.compare-building-types .compare-building-types-options .compare-building-types-button.classroom:hover,
.compare-building-types .compare-building-types-options .compare-building-types-button.classroom.active {
  background-color: #3ea0b7;
}
.compare-building-types .compare-building-types-options .compare-building-types-button.housing:hover,
.compare-building-types .compare-building-types-options .compare-building-types-button.housing.active {
  background-color: #3778BC;
}
.compare-building-types .compare-building-types-options .compare-building-types-button.office:hover,
.compare-building-types .compare-building-types-options .compare-building-types-button.office.active {
  background-color: #92bd3b;
}
.compare-building-types .compare-building-types-options .compare-building-types-button.community:hover,
.compare-building-types .compare-building-types-options .compare-building-types-button.community.active {
  background-color: #107d4b;
}
.compare-building-types .compare-building-types-description {
  width: 67%;
  margin: auto;
  padding: 20px;
  font-size: 16px;
  line-height: 22px;
}
.compare-building-types .compare-building-types-stats {
  display: flex;
  justify-content: space-between;
  width: 67%;
  margin: auto;
  padding-top: 20px;
  font-size: 15px;
}
.compare-building-types .compare-building-types-stats .compare-building-types-average {
  width: 30%;
}
.compare-building-types .compare-building-types-stats .compare-building-types-average .compare-building-types-average-value {
  background: #eeedee;
  height: 20px;
  padding: 10px;
  margin-top: 12px;
  text-align: center;
  font-weight: 600;
}
.compare-building-types .compare-building-types-stats .compare-building-types-top-buildings {
  width: 55%;
}
.compare-building-types .compare-building-types-stats .compare-building-types-top-buildings .compare-building-types-top-chart {
  width: 100%;
  margin-top: 12px;
}

