$dark-gray: #212832;
$light-gray: #F5F5F5;
.ewc-desktop-wrapper {
	height: 360px;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.ewc-desktop-header {
		margin-top: 5px;
		height: inherit;
		margin: auto;
		padding: 0 5%;
		display: flex;
		max-width: 900px;
		justify-content: space-between;
		.ewc-desktop-header-logo {
			height: inherit;
			display: flex;
			flex-direction: column;
			justify-content: center;
			img {
				width: 85%;
				min-width: 260px;
				height: auto;
				align-self: center;
			}
			.ewc-desktop-dates {
				text-align: center;
				font-size: 16px;
				line-height: 19px;
				color: white;
				margin-top: 25px;
				span {
					letter-spacing: 2.17px;
				}
			}
		}
		.ewc-desktop-header-detail {
			display: flex;
			flex-direction: column;
			height: inherit;
			justify-content: center;
			.ewc-desktop-header-detail-box {
				width: 60%;
				padding: 15px 25px 13px;
				background: $dark-gray;
				border-radius: 3px;
				align-self: center;
				text-align: center;
				color: $light-gray;
				font-size: 14px;
				line-height: 18px;
				.ewc-desktop-header-detail-title {
					font-weight: 600;
					font-style: italic;
					opacity: 0.7;
				}
				.ewc-desktop-header-detail-description {
					margin: 7px auto 0;
					opacity: 0.7;
				}
				.ewc-desktop-header-tips {
					width: 85%;
					margin: 30px auto 0;
					text-align: left;
					.ewc-desktop-header-tip {
						display: flex;
						justify-content: space-between;
						margin-bottom: 20px;
						img {
							&.ewc-desktop-header-tip-light {
								margin: 0 25px 0 5px;
							}
							& {
							margin-right: 20px;
							}
						}
						.header-tip-detail {
							color: white;
							font-size: 14px;
							line-height: 18px;
							.header-tip-header {
								font-weight: 600;
								&.electricity {
									color: #92BD3B;
								}
								&.water {
									color: #32BBD5;
								}
							}
						}
					}
				}
			}
		}
	}
}
