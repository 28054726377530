.building-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.graphRow-box {
  flex: 1;
  vertical-align: middle;
  border: 1px solid #d8d8d8;
  text-align: left;
}

.building-wrapper .graphRow-box .building-title {
  width: 50%;
  height: 100%;
  padding-left: 20px;
  padding-top: 50px;
}
