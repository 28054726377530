.usage-next-wrapper {
  padding-top: 50px;
	width: fit-content;
	margin: auto;
}

.usage-next-wrapper .usage-link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
