.leaflet-container {
  height: 100vh;
  min-height: 500px;
  width: 100%;
  z-index: 0;
  font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 5px !important;
  text-align: center !important;
  padding: 0 !important;
  width: 250px;
}

.leaflet-popup-content {
  margin: 0 !important;
}

.popup-eui-header-wrapper {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
  color: white !important;
  max-height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-eui-header-wrapper.lab {
  background-color: #2a575f;
}
.popup-eui-header-wrapper.office {
  background-color: #93bb45;
}
.popup-eui-header-wrapper.community {
  background-color: #107d4b;
}
.popup-eui-header-wrapper.classroom {
  background-color: #3ea0b7;
}
.popup-eui-header-wrapper.housing {
  background-color: #3778BC;
}
.popup-eui-header-wrapper .popup-eui-header {
  margin: auto;
}
.popup-eui-header-wrapper .popup-eui-value {
  font-size: 18px;
  font-weight: bold;
}
.popup-eui-header-wrapper .popup-eui-units {
  font-size: 13px;
}
.popup-eui-header-wrapper .popup-eui-label {
  font-size: 14px;
}

.popup-building-type {
  padding: 15px 0 12px 0px;
  text-align: left;
  display: flex;
}
.popup-building-type img {
  height: 35px;
  margin: 8px 8px 0 8px;
}
.popup-building-type .popup-building-type-info {
  margin-right: 8px;
}

.popup-view {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 5px;
  text-align: center;
  cursor: pointer;
}

.popup-green-building {
  color: #212832;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
.popup-green-building .popup-green-building-content {
  padding: 8px 15px 8px;
}

.popup-clicked-building-info {
  color: #212832;
  font-size: 14px;
  line-height: 17px;
}
.popup-clicked-building-info .popup-clicked-savings {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  border-bottom: 1px solid #EEEDEE;
}
.popup-clicked-building-info .popup-building-info {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  border-bottom: 1px solid #EEEDEE;
}

.popup-eui-commodities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
}
.popup-eui-commodities div:first-child:nth-last-child(1),
.popup-eui-commodities div:first-child:nth-last-child(1) ~ div {
  border-bottom: none;
}
.popup-eui-commodities div:first-child:nth-last-child(2),
.popup-eui-commodities div:first-child:nth-last-child(2) ~ div {
  border-bottom: none;
}
.popup-eui-commodities div {
  box-sizing: border-box;
  width: 49.9%;
  height: 32px;
  text-align: left;
  padding-top: 7px;
  border-bottom: solid 1px #dbdbdb;
}
.popup-eui-commodities div:nth-of-type(odd) {
  padding-left: 17px;
}
.popup-eui-commodities div:nth-of-type(n+3) {
  border-bottom: none;
}
.popup-eui-commodities div.unmetered {
  color: #a8a8a8;
}

.building-dot-icon {
  display: flex !important;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
}
.building-dot-icon:hover svg {
  transform: scale(1.6);
  transition: all 80ms;
  transform-origin: 50% 50%;
}
.building-dot-icon:hover div.building-name {
  display: inline-block;
}
.building-dot-icon svg {
  transition: all 80ms;
  padding-left: 1.5px;
}
.building-dot-icon.classroom-type svg {
  fill: #3ea0b7;
}
.building-dot-icon.community-type circle {
  fill: #107d4b;
}
.building-dot-icon.housing-type svg {
  fill: #3778BC;
}
.building-dot-icon.lab-type svg {
  fill: #2a575f;
}
.building-dot-icon.office-type svg {
  fill: #93bb45;
}
.building-dot-icon div.building-name {
  display: none;
  width: 145px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.14;
}
.building-dot-icon div.building-name.static {
  display: inline-block;
}

.eco-logo {
  width: 200px;
  position: absolute;
  bottom: 20px;
  right: 8px;
  z-index: 500;
}
.eco-logo img {
  width: 100%;
}

