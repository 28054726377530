.container {
  padding: 46px 46px 33px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.1);
}
.container > h2 {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 30px;
  color: #022851;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
}
.container > img {
  width: 100%;
}

