.app-menu img.apps-base {
	margin-right: 7px;
}
.app-menu img {
	vertical-align: baseline;
	max-width: 100%;
	position: relative;
	top: 1px;
}
.app-menu .menu-title {
	font-weight: 600;
	margin-left: 30%;
}
.app-menu{
	.menu-close:hover{
		transform: rotate(-90deg);
		transition: transform 200ms ease;
	}
}