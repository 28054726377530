@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova-Bold.eot');
  src: url('./fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/ProximaNova-Bold.woff2') format('woff2'),
  url('./fonts/ProximaNova-Bold.woff') format('woff'),
  url('./fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("./fonts/ProximaNova-Medium.eot");
  src: url("./fonts/ProximaNova-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova-Medium.woff2") format("woff2"), url("./fonts/ProximaNova-Medium.woff") format("woff"), url("./fonts/ProximaNova-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova-Regular.eot');
  src: url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('./fonts/ProximaNova-Regular.woff') format('woff'),
  url('./fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
