.campus-metrics {
  padding-top: 40px;
  max-width: 1050px;
  width: 100%;
  margin: auto;
  font-size: 16px;
  line-height: 20px;
}
.campus-metrics .campus-card {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 30px 3% 40px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  object-fit: contain;
}
.campus-metrics .usage-campus-metrics .campus-metrics-headline {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 20px 0 30px;
}
.campus-metrics .usage-campus-metrics .campus-metrics-description {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 30px;
}
.campus-metrics .usage-campus-metrics .campus-metrics-view-selection-options {
  display: flex;
}
.campus-metrics .usage-campus-metrics .campus-metrics-view-selection-options span {
  padding-top: 22px;
  font-weight: 600;
}
.campus-metrics .usage-campus-metrics .campus-metrics-view-selection-options .campus-metrics-view-button {
  border: 2px solid #EEEDEE;
  border-radius: 3px;
  background-color: white;
  color: rgba(60, 65, 75, 0.7);
  padding: 10px 24px 8px;
  margin: 12px;
  cursor: pointer;
  transition: all ease 0.2s;
}
.campus-metrics .usage-campus-metrics .campus-metrics-view-selection-options .campus-metrics-view-button:hover, .campus-metrics .usage-campus-metrics .campus-metrics-view-selection-options .campus-metrics-view-button.active {
  border: 2px solid #2D3138;
  background-color: white;
  color: #3C414B;
  font-weight: 600;
}
.campus-metrics .usage-campus-metrics .campus-metrics-year-selection {
  padding-top: 16px;
}
.campus-metrics .usage-campus-metrics .campus-metrics-year-selection span {
  font-weight: 600;
}
.campus-metrics .usage-campus-metrics .campus-metrics-year-selection .campus-metrics-year-selection-options {
  display: flex;
  flex-wrap: wrap;
}
.campus-metrics .usage-campus-metrics .campus-metrics-year-selection .campus-metrics-year-selection-options .campus-metrics-year-button {
  padding: 10px 3px 8px;
  color: rgba(60, 65, 75, 0.7);
  width: 7%;
  min-width: 62px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: all ease 0.2s;
  margin: 2px 1px;
}
.campus-metrics .usage-campus-metrics .campus-metrics-year-selection .campus-metrics-year-selection-options .campus-metrics-year-button:hover, .campus-metrics .usage-campus-metrics .campus-metrics-year-selection .campus-metrics-year-selection-options .campus-metrics-year-button.active {
  width: 7%;
  background-color: white;
  color: #3C414B;
  font-weight: 600;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line {
  padding-top: 40px;
  font-weight: 600;
  width: 100%;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-graph {
  width: 100%;
  padding-top: 10px;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data {
  display: flex;
  flex-direction: column;
  margin: auto;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-energy,
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-footage {
  display: flex;
  width: 40%;
  min-width: 300px;
  margin: auto;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-energy span,
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-footage span {
  width: 60%;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-energy span svg,
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-footage span svg {
  margin-right: 8px;
  vertical-align: text-top;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-energy .line-data-energy-amount,
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-energy .line-data-footage-amount,
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-footage .line-data-energy-amount,
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-footage .line-data-footage-amount {
  width: 24%;
  text-align: right;
  color: #99be4f;
  font-size: 15px;
  font-weight: 600;
}
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-energy .line-data-footage-amount,
.campus-metrics .usage-campus-metrics .campus-metrics-line .campus-metrics-line-data .line-data-footage .line-data-footage-amount {
  color: #2D3138;
}
.campus-metrics .usage-campus-metrics .campus-metrics-bar {
  padding-top: 40px;
  font-weight: 600;
}
.campus-metrics .usage-campus-metrics .campus-metrics-bar .campus-metrics-bar-data {
  position: relative;
  top: -23px;
  left: 40%;
  font-weight: normal;
  font-size: 14px;
}
.campus-metrics .usage-campus-metrics .campus-metrics-bar .campus-metrics-bar-data span {
  padding-right: 12px;
}
.campus-metrics .usage-campus-metrics .campus-metrics-bar .campus-metrics-bar-graph {
  width: 83%;
  margin-left: 6.8%;
}

