$text: #3C414B;
$light-gray: #EEEDEE;

.total-usage {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 15px;
	line-height: 17px;
	.total-usage-value {
		display: flex;
		flex-direction: column;
		width: 6%;
		min-width: 65px;
		justify-content: space-evenly;
	}
	.total-usage-circle {
		width: 150px;
		height: 150px;
		margin: -14px -30px;
	}
	.total-usage-grid {
		display: flex;
		flex-direction: column;
		width: 65%;
		.usage-grid-header,
		.usage-grid-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			&.electricity {
				color: $text;
			}
			&.gas {
				color: $text;
			}
			.usage-grid-column {
				padding: 14px 10px 10px;
				width: 100%;
				font-size: 15px;
				line-height: 16px;
				border-bottom: 1px solid $light-gray;
				border-right: 1px solid $light-gray;
				img {
					vertical-align: middle;
					margin-top: -4px;
					padding-right: 12px;
				}
				&.column-1 {
					border-left: 1px solid $light-gray;
				}
				&.column-2,
				&.column-3 {
					padding-right: 20px;
					text-align: right;
				}
			}
		}
		.usage-grid-header {
			background-color: $light-gray;
		}
	}
}
