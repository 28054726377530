.saving-navigation {
  height: 65px;
  width: 100%;
  background-color: #3c414b;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.28);
  color: #999999;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.7px;
  text-align: center;
}
.saving-navigation .saving-navigation-wrapper {
  display: flex;
  justify-content: space-between;
  width: 76%;
  max-width: 900px;
  margin: auto;
}

.saving-navigation-button {
  width: 350px;
  cursor: pointer;
  padding: 25px 15px;
  transition: ease all 0.2s;
}
.saving-navigation-button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
}
.saving-navigation-button.active {
  color: #3c414b;
  background-color: #EEEDEE;
}
.saving-navigation-button.savingsCalculator, .saving-navigation-button.buildingProjects {
  padding-top: 18px;
}

