$dark-gray: #3c414b;
.building-selector {
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
}
@media (max-height: 700px) {
	.building-selector {
		flex-direction: column-reverse;
	}
}
.building-selector-grid-header {
	border-top: 1px solid #E0E0E0;
	border-bottom: 1px solid #E0E0E0;
	display: flex;
	color: #3C414B;
	font-size: 13px;
	font-weight: normal;
	line-height: 15px;
	letter-spacing: 1.21px;
	margin-top: 16px;
	img {
		padding-right: 10px;
		vertical-align: middle;
	}
	.building-selector-grid-header-row {
		display: flex;
		border-right: 1px solid white;
		padding: 16px 1.5%;
		cursor: pointer;
	}
	.building-selector-grid-header-row:hover {
		color: black;
		img {
			filter: brightness(-200%);
		}
	}
	.building-selector-grid-header-row.col1,
	.building-selector-grid-header-row.col3 {
		width: 25%;
		flex-wrap: wrap;
	}
	.building-selector-grid-header-row.col2 {
		width: 22.5%;
		flex-wrap: wrap;
	}
	.building-selector-grid-header-row.col4 {
		width: 20%;
		flex-wrap: wrap;
		border-right: none;
	}
	.grid-column-text {
		margin: auto 0;
		width: 90%;
		text-transform: uppercase;
	}
	.grid-column-text-selector {
		width: 100%;
		font-size: 15px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: normal;
		color: #2E384D;
		opacity: 0.5;
		margin-top: 7px;
	}
	.underline {
		text-decoration: underline;
	}
}
.capitalize {
	text-transform: capitalize;
}
.building-selector-rows-wrapper {
	width: 100%;
	max-height: 410px;
	position: relative;
	overflow-x: visible;
	overflow-y: auto;
	a {
		text-decoration: none;
	}
	.building-selector-grid-row-selected {
		border: 1px solid #92BD3B;
		border-radius: 4px;
		background-color: #FFFFFF;
		font-weight: 600;
	}
}
.building-selector-grid-row {
	align-items: center;
	font-size: 15px;
	color: #3C414B;
	opacity: 0.8;
	text-decoration: none;
	height: 75px;
	border: 1px solid transparent;
	border-bottom: 1px solid #f5f4f5;
	display: flex;
	.building-selector-grid-row-content {
		display: flex;
		width: 100%;
		padding: 10px 1.5%;
	}
	.col1,
	.col2,
	.col3 {
		display: block;
		text-align: left;
		text-transform: capitalize;
	}
	.col1,
	.col3 {
		width: 25%;
	}
	.col2 {
		width: 30%;
	}
	.col4 {
		width: 20%;
	}
	.col1,
	.col3,
	.col4 {
		text-overflow: ellipsis;
		line-height: 18px;
	}
	.col2 {
		img {
			width: 21px;
			margin-bottom: -2px;
			margin-right: 10px;
		}
	}
}
.building-selector-options-building-types input {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	display: none;
}
.building-selector-options-building-types .building-selector-options-building-types-label:before {
	content: '';
	padding: 0px;
	display: inline-block;
	height: 16px;
	width: 16px;
	border: solid 1px #d6d6d6;
	border-radius: 0;
	font-size: 12px;
	margin-right: 4px;
	vertical-align: none;
}
.building-selector-options-building-types input + .building-selector-options-building-types-label:before {
	color: white;
	background-color: white;
	margin-right: 10px;
	margin-top: 8px;
}
.building-selector-options-building-types input:checked + .building-selector-options-building-types-label:before {
	background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23F1F7E6' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%233C414B' /></svg>") no-repeat center center;
}
.building-selector-options-building-types input + .building-selector-options-building-types-label {
	font-size: 15px;
	cursor: pointer;
	color: #a6a6a6;
	margin-right: 10px;
	position: relative;
	span.building-types-label-tooltip {
		pointer-events: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 90px;
		height: 46px;
		position: absolute;
		top: -58px;
		left: 50%;
		margin-left: -42px;
		text-align: center;
		color: white;
		background: $dark-gray;
		padding: 0 6px;
		border-radius: 10px;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		opacity: 0;
		transition: ease all 0.3s;
		transition-delay: 50ms;
		&::after {
			content: " ";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: $dark-gray transparent transparent transparent;
		}
		&.right {
			top: -20px;
			left: 96px;
			z-index: 100;
			&::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 0%;
				margin-left: -10px;
				margin-top: -5px;
				border-color: transparent $dark-gray transparent transparent;
			}
		}
	}
	&:hover {
		span.building-types-label-tooltip {
			transition-delay: 1200ms;
			opacity: 1;
		}
	}
}
.building-selector-options-building-types input:checked + .building-selector-options-building-types-label {
	color: black;
}
.building-selector-options-building-types input:not(:checked) + .building-selector-options-building-types-label {
	color: black;
	img {
		-webkit-filter: opacity(0.5);
		-moz-filter: opacity(0.5);
		-o-filter: opacity(0.5);
		-ms-filter: opacity(0.5);
		filter: opacity(0.5);
	}
	&:hover {
		img {
			-webkit-filter: none;
			-moz-filter: none;
			-o-filter: none;
			-ms-filter: none;
			filter: none;
		}
	}
}
.building-selector-normalize-switch {
	position: relative;
	display: inline-block;
	top: 3px;
	width: 30px;
	height: 17px;
	margin-right: 5px;
	cursor: pointer;
}
