$text-color: #CFD1D3;
$light-background: #F5F5F5;
$dark-background: #232830;
.ceed-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	padding: 30px 5%;
	font-size: 15px;
	line-height: 18px;
	color: $text-color;
	background: $dark-background;
	text-align: center;
	a {
		text-decoration: none;
		color: white;
		font-weight: 600;
	}
	.ceed-footer-logo {
		max-width: 205px;
		img {
			width: 100%;
			height: auto;
		}
	}
	.ceed-footer-slogan {
		width: 70%;
		max-width: 235px;
		margin: -15px auto 25px;
	}
	.ceed-footer-sites {
		line-height: 40px;
		margin-bottom: 40px;
		.ceed-footer-site {
			cursor: pointer;
		}
	}
	.ceed-footer-newsletter {
		width: 70%;
		max-width: 235px;
		margin-bottom: 25px;
		.ceed-footer-newsletter-button {
			width: 100%;
			height: 25px;
			padding: 10px 0 3px;
			margin-top: 10px;
			border: 1px solid #D8D8D8;
			border-radius: 3px;
			background: white;
			color: black;
			font-weight: normal;
			text-align: center;
		}
	}
	.ceed-footer-contact {
		margin-top: 20px;
	}
	&.light {
		background: #F5F5F5;
		color: black;
		a {
			color: black;
		}
		.ceed-footer-slogan {
			margin: 10px auto 25px;
		}
	}
	&.fixed {
		position: fixed;
		bottom: -600px;
		padding: 20px 5% 30px;
		transition: ease all 0.4s;
		z-index: 900;
		&.pop {
			bottom: -480px;
			@media(max-width: 329px) {
				bottom: -515px;
			}
		}
		&.display {
			bottom: 0;
		}
	}
}
