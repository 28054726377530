.usage-chart {
  display: flex;
  justify-content: space-between;
}
.usage-chart .usage-chart-content {
  width: 76%;
}
.usage-chart .usage-chart-content .usage-chart-title {
  position: relative;
  padding: 10px 0 30px;
  font-size: 16px;
}
.usage-chart .usage-chart-content .usage-chart-title .demand-zoom-helper {
  position: absolute;
  right: 8px;
  bottom: 0;
  color: #3c414b;
  opacity: 0.9;
  font-size: 13px;
}
.usage-chart .usage-chart-content .usage-chart-title .demand-zoom-helper img {
  margin-bottom: -1px;
}

