.total-metrics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.total-metrics .metrics-mini-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 18%;
  height: 105px;
  padding: 30px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  filter: grayscale(100%);
  cursor: pointer;
  transition: ease all 0.2s;
}
.total-metrics .metrics-mini-card .metrics-image,
.total-metrics .metrics-mini-card .metrics-info,
.total-metrics .metrics-mini-card .metrics-value {
  margin: 0 auto;
  color: #3C414B;
  opacity: 0.5;
  text-align: center;
}
.total-metrics .metrics-mini-card .metrics-info {
  font-size: 15px;
  line-height: 21px;
}
.total-metrics .metrics-mini-card .metrics-value {
  font-size: 18px;
  line-height: 22px;
}
.total-metrics .metrics-mini-card .metrics-value .metrics-label {
  font-size: 14px;
  line-height: 22px;
}
.total-metrics .metrics-mini-card:hover {
  filter: none;
}
.total-metrics .metrics-mini-card.active {
  height: 113px;
  padding: 26px 30px;
  filter: none;
}
.total-metrics .metrics-mini-card.active .metrics-image,
.total-metrics .metrics-mini-card.active .metrics-info,
.total-metrics .metrics-mini-card.active .metrics-value {
  opacity: 1;
  font-weight: 600;
}

