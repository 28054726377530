$text: white;

.saving-header {
    width: 100%;
    // height: 340px;
	background-image: url("./images/Energy-Saving-header.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding-bottom: 70px;
}
.saving-header-wrap {
    display: flex;
    justify-content: space-around;
	width: 70%;
	height: 80%;
	margin: auto;
	padding-top: 25px;
    .saving-header-title {
        padding-top: 100px;
        width: 30%;
        margin: 0;
        font-size: 36px;
        font-weight: 600;
		line-height: 1.2;
        text-align: left;
        color: $text;
    }
    .saving-header-description {
        width: 60%;
        padding-top: 100px;
        margin: 0 0 0 auto;
        color: $text;
        font-size: 16px;
        line-height: 22px;
        p {
            margin: 0;
        }
        .link {
            color: $text;
        }
        .link:hover{
            text-decoration: underline;
        }
    }
}
