$dark-gray: #343841;
$buildings-header: #AAAAAA;
$stats: #92BD3B;
$darker-gray: #212832;
.ceed-mobile {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	.ceed-search-box {
		display: flex;
		flex-direction: column;
		padding: 30px;
		align-items: center;
		background-size: auto 138%;
		background-position: 70% 50%;
		font-weight: 600;
		.ceed-search-campus {
			margin: 30px auto 6px;
			font-size: 21px;
		}
		.ceed-search-title {
			font-size: 48px;
			line-height: 46px;
		}
		.ceed-search-box {
			display: flex;
			flex-direction: row;
			position: relative;
			align-content: center;
			width: 90%;
			max-width: 300px;
			height: 35px;
			padding: 0;
			margin: 45px auto 50px;
			color: black;
			font-size: 15px;
			line-height: 18px;
		}
		.ceed-search-slogan {
			font-size: 14px;
			line-height: 17px;
			margin-bottom: 15px;
		}
	}
	.ceed-filters-title {
		margin: 17px auto 10px;
		font-size: 14px;
		line-height: 17px;
		font-weight: 600;
	}
	.ceed-filters {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		.ceed-filters-button {
			position: relative;
			height: 110px;
			width: 110px;
			padding: 10px;
			background: $dark-gray;
			border-radius: 17px;
			margin: 0 12px 17px;
			cursor: pointer;
			transition: ease all 0.3s;
			.ceed-filters-close {
				display: none;
				position: absolute;
				right: 12px;
				top: 12px;
				color: $dark-gray;
				font-size: 28px;
				font-weight: normal;
			}
			.ceed-filters-icon {
				padding-top: 15px;
			}
			.ceed-filters-name {
				margin-top: 5px;
			}
			&.active {
				background-color: white;
				color: black;
				.ceed-filters-close {
					display: block;
				}
			}
		}
	}
	.ceed-mobile-buildings {
		display: flex;
		flex-direction: column;
		width: 95%;
		margin: 0 auto 70px;
		.mobile-buildings-headers {
			display: flex;
			justify-content: space-between;
			max-width: 350px;
			width: 85%;
			margin: 30px auto 10px;
			color: $buildings-header;
			font-size: 14px;
			line-height: 18px;
			font-weight: 600;
		}
		.mobile-building-card {
			display: flex;
			justify-content: space-between;
			max-width: 350px;
			padding: 20px;
			padding-bottom: 16px;
			margin: 3px auto;
			background: white;
			text-decoration: none;
			color: $darker-gray;
			cursor: pointer;
			.mobile-building-details {
				display: flex;
				flex-direction: column;
				width: 60%;
				text-align: left;
				font-size: 15px;
				b {
					font-size: 16px;
					margin-bottom: 4px;
				}
			}
			.mobile-building-statistics {
				display: flex;
				flex-direction: column;
				text-align: right;
				font-size: 14px;
				line-height: 18px;
				color: $buildings-header;
				.building-stats-kwh,
				.building-stats-homes {
					font-size: 15px;
					font-weight: 600;
					color: $darker-gray;
					span {
						color: $darker-gray;
						font-weight: normal;
					}
				}
				.building-stats-kwh {
					color: $stats;
				}
				.building-stats-homes {
					margin-top: 2px;
				}
			}
		}
	}
}
