.campus-navigation {
  height: 66px;
  width: 100%;
  background-color: #3c414b;
  color: #9DA0A5;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.7px;
  text-align: center;
}
.campus-navigation .campus-navigation-wrapper {
  display: flex;
  justify-content: space-between;
  width: 76%;
  max-width: 900px;
  margin: auto;
}
@media (max-width: 500px) {
  .campus-navigation .campus-navigation-wrapper {
    width: 95%;
  }
}

.campus-navigation-button {
  width: 175px;
  cursor: pointer;
  padding: 25px 15px;
  transition: ease all 0.2s;
}
.campus-navigation-button.active {
  color: #3c414b;
  background-color: #EEEDEE;
}
@media (max-width: 355px) {
  .campus-navigation-button {
    width: 48%;
  }
}

#demand-mobile {
  padding-top: 20px;
}

