$dark-gray: #2C3037;
$electricity: #92bd3b;
$email: #35BCD5;
$facebook: #3678EA;
$facebook-background: #899CCE;
$twitter: #4AA0EC;
$twitter-background: #95CAF1;
.ewc-building-ranking {
	max-width: 900px;
	margin: 40px auto 0;
	.ewc-switch-toggle {
		max-width: 350px;
		.ewc-switch-button.active {
			img {
				filter: brightness(3);
			}
		}
	}
	@media(max-width: 768px) {
		margin-top: 0;
	}
	.ewc-building-tips {
		width: 40%;
		height: 260px;
		margin: 50px 0;
		.ewc-building-tips-detail {
			width: 90%;
			height: 70px;
			margin: 0 auto;
		}
		@media(max-width:768px) {
			width: 80%;
			max-width: 320px;
			height: 200px;
			margin: 30px auto;
		}
	}
	.ewc-building-social-media {
		height: 260px;
		width: 35%;
		margin: 50px 0;
		.ewc-building-social-media-share {
			height: 90px;
			.ewc-building-social-media-tweet {
				display: flex;
				background: $twitter-background;
				img {
					height: 15px;
					width: auto;
					background: $twitter;
					padding: 11px 11px 10px;
				}
				.ewc-building-social-tweet-text {
					width: 100%;
					height: auto;
					padding: 10px 0 0 30px;
					color: white;
					font-weight: 600;
				}
			}
			.ewc-building-social-media-facebook {
				display: flex;
				background: $facebook-background;
				img {
					height: 18px;
					width: auto;
					background: $facebook;
					padding: 8px 10px 10px 9px;
				}
				.ewc-building-social-fb-text {
					width: 100%;
					height: auto;
					padding: 10px 0 0 30px;
					color: white;
					font-weight: 600;
				}
			}
		}
		.ewc-building-social-media-submit {
			font-size: 15px;
			line-height: 18px;
			a {
				font-weight: 600;
				color: $email;
			}
		}
		@media(max-width:768px) {
			width: 70%;
			max-width: 270px;
			height: 250px;
			margin: 0px auto;
		}
	}
	.ewc-building-ranking-footer {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		max-width: 900px;
		margin: 30px auto 0;
		.ewc-logos {
			width: 260px;
		}
		.ewc-building-raffle {
			max-width: 360px;
			@media(max-width:768px) {
				margin: auto;
				margin-bottom: 30px;
			}
			.ewc-building-raffle-button {
				background: $dark-gray;
				transition: ease all 0.3s;
				&:hover {
					background: $electricity;
				}
			}
		}
	}
}
