.ceed-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.ceed-mobile .ceed-search-box {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  background-size: auto 138%;
  background-position: 70% 50%;
  font-weight: 600;
}
.ceed-mobile .ceed-search-box .ceed-search-campus {
  margin: 30px auto 6px;
  font-size: 21px;
}
.ceed-mobile .ceed-search-box .ceed-search-title {
  font-size: 48px;
  line-height: 46px;
}
.ceed-mobile .ceed-search-box .ceed-search-box {
  display: flex;
  flex-direction: row;
  position: relative;
  align-content: center;
  width: 90%;
  max-width: 300px;
  height: 35px;
  padding: 0;
  margin: 45px auto 50px;
  color: black;
  font-size: 15px;
  line-height: 18px;
}
.ceed-mobile .ceed-search-box .ceed-search-slogan {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}
.ceed-mobile .ceed-filters-title {
  margin: 17px auto 10px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}
.ceed-mobile .ceed-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.ceed-mobile .ceed-filters .ceed-filters-button {
  position: relative;
  height: 110px;
  width: 110px;
  padding: 10px;
  background: #343841;
  border-radius: 17px;
  margin: 0 12px 17px;
  cursor: pointer;
  transition: ease all 0.3s;
}
.ceed-mobile .ceed-filters .ceed-filters-button .ceed-filters-close {
  display: none;
  position: absolute;
  right: 12px;
  top: 12px;
  color: #343841;
  font-size: 28px;
  font-weight: normal;
}
.ceed-mobile .ceed-filters .ceed-filters-button .ceed-filters-icon {
  padding-top: 15px;
}
.ceed-mobile .ceed-filters .ceed-filters-button .ceed-filters-name {
  margin-top: 5px;
}
.ceed-mobile .ceed-filters .ceed-filters-button.active {
  background-color: white;
  color: black;
}
.ceed-mobile .ceed-filters .ceed-filters-button.active .ceed-filters-close {
  display: block;
}
.ceed-mobile .ceed-mobile-buildings {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto 70px;
}
.ceed-mobile .ceed-mobile-buildings .mobile-buildings-headers {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  width: 85%;
  margin: 30px auto 10px;
  color: #AAAAAA;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  padding: 20px;
  padding-bottom: 16px;
  margin: 3px auto;
  background: white;
  text-decoration: none;
  color: #212832;
  cursor: pointer;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-details {
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: left;
  font-size: 15px;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-details b {
  font-size: 16px;
  margin-bottom: 4px;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-statistics {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 14px;
  line-height: 18px;
  color: #AAAAAA;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-statistics .building-stats-kwh,
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-statistics .building-stats-homes {
  font-size: 15px;
  font-weight: 600;
  color: #212832;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-statistics .building-stats-kwh span,
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-statistics .building-stats-homes span {
  color: #212832;
  font-weight: normal;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-statistics .building-stats-kwh {
  color: #92BD3B;
}
.ceed-mobile .ceed-mobile-buildings .mobile-building-card .mobile-building-statistics .building-stats-homes {
  margin-top: 2px;
}

