.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1720px;
  min-height: 100vh;
  box-sizing: border-box;
  margin: auto;
  column-gap: 60px;
}

.aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 36px;
  width: 38.6%;

  > h2 {
    color: #022851;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 30px;
  }
}
