.container {
  background: #F5F5F5;

  // reset
  * {
    box-sizing: border-box;
    margin: 0;
    border: none;
    padding: 0;
  }
}
