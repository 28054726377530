$lab: #2a575f;
$office: #93bb45;
$community: #107d4b;
$classroom: #3ea0b7;
$housing: #3778BC;
$light-gray: #EEEDEE;
$dark-gray: #212832;
.leaflet-container {
	height: 100vh;
	min-height: 500px;
	width: 100%;
	z-index: 0;
	font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.leaflet-popup-content-wrapper {
	border-radius: 5px !important;
	text-align: center !important;
	padding: 0 !important;
	width: 250px;
}
.leaflet-popup-content {
	margin: 0 !important;
}
.popup-eui-header-wrapper {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	padding: 10px;
	color: white !important;
	max-height: 68px;
	display: flex;
	justify-content: center;
	align-items: center;
	&.lab {
		background-color: $lab;
	}
	&.office {
		background-color: $office;
	}
	&.community {
		background-color: $community;
	}
	&.classroom {
		background-color: $classroom;
	}
	&.housing {
		background-color: $housing;
	}
	.popup-eui-header {
		margin: auto;
	}
	.popup-eui-value {
		font-size: 18px;
		font-weight: bold;
	}
	.popup-eui-units {
		font-size: 13px;
	}
	.popup-eui-label {
		font-size: 14px;
	}
}
.popup-building-type {
	padding: 15px 0 12px 0px;
	text-align: left;
	display: flex;
	img {
		height: 35px;
		margin: 8px 8px 0 8px;
	}
	.popup-building-type-info {
		margin-right: 8px;
	}
}
.popup-view {
	font-size: 12px;
	font-weight: bold;
	padding-bottom: 10px;
	padding-top: 5px;
	text-align: center;
	cursor: pointer;
}
.popup-green-building {
	color: $dark-gray;
	font-size: 12px;
	line-height: 14px;
	text-align: left;
	.popup-green-building-content {
		padding: 8px 15px 8px;
	}
}

.popup-clicked-building-info {
	color: $dark-gray;
	font-size: 14px;
	line-height: 17px;
	.popup-clicked-savings {
		display: flex;
		justify-content: space-between;
		padding: 5px 20px;
		border-bottom: 1px solid $light-gray;
	}
	.popup-building-info {
		display: flex;
		justify-content: space-between;
		padding: 5px 20px;
		border-bottom: 1px solid $light-gray;
	}
}
.popup-eui-commodities {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 14px;
	//don't draw a border if there are only 1 or 2 commodity divs
	//border will draw if there are 3 or more
	div:first-child:nth-last-child(1),
	div:first-child:nth-last-child(1) ~ div {
		border-bottom: none;
	}
	div:first-child:nth-last-child(2),
	div:first-child:nth-last-child(2) ~ div {
		border-bottom: none;
	}
	div {
		box-sizing: border-box;
		width: 49.9%;
		height: 32px;
		text-align: left;
		padding-top: 7px;
		border-bottom: solid 1px #dbdbdb;
		//first and third commodity divs need padding-left
		&:nth-of-type(odd) {
			padding-left: 17px;
		}
		//third and fourth commodity divs should never have a border
		&:nth-of-type(n+3) {
			border-bottom: none;
		}
		&.unmetered {
			color: #a8a8a8;
		}
	}
}
.building-dot-icon {
	display: flex !important;
	flex-shrink: 0;
	flex-direction: column;
	align-items: center;
	&:hover {
		//on hover, grow svg and reveal building name
		svg {
			transform: scale(1.6);
			transition: all 80ms;
			transform-origin: 50% 50%;
		}
		div.building-name {
			display: inline-block;
		}
	}
	svg {
		transition: all 80ms;
		padding-left: 1.5px;
	}
	&.classroom-type {
		svg {
			fill: $classroom;
		}
	}
	&.community-type {
		circle {
			fill: $community;
		}
	}
	&.housing-type {
		svg {
			fill: $housing;
		}
	}
	&.lab-type {
		svg {
			fill: $lab;
		}
	}
	&.office-type {
		svg {
			fill: $office;
		}
	}
	div.building-name {
		display: none; //hidden until hover on div.building-dot-icon
		width: 145px;
		text-align: center;
		font-size: 13px;
		font-weight: 500;
		line-height: 1.14;
		&.static {
			display: inline-block;
		}
	}
}
.eco-logo {
	width: 200px;
	position: absolute;
	bottom: 20px;
	right: 8px;
	z-index: 500;
	img {
		width: 100%;
	}
}
