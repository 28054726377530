.building-ranking {
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	@media (max-height: 700px) {
		flex-direction: column-reverse;
	}
}
.building-ranking-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 10px 0;
	border-bottom: 1px solid #e7e7e7;
	opacity: 1;
	transition: opacity .5s ease-in;
	cursor: pointer;
	.building-ranking-column1 {
		width: 38%;
		margin-left: 30px;
		color: #000;
		&.header {
			color: #676767;
		}
	}
	.building-ranking-column2 {
		.building-ranking-value {
			display: inline-block;
			width: 90px;
			font-size: 15px;
		}
	}
	&.header {
		color: #676767;
		cursor: default;
		span {
			font-size: 12px;
			font-style: italic;
		}
	}
	@media (max-height: 700px) {
		padding: 4px 0;
	}
}
.building-ranking-row:hover {
	background-color: #efefef;
	&.header {
		background-color: inherit;
	}
}
.building-ranking-full-link {
	display: flex;
	justify-content: space-between;
	padding: 15px 35px;
	line-height: 20px;
	cursor: pointer;
	font-weight: 500;
	background-color: #FFF;
	transition: all 0.3s ease;
	img {
		transform: rotate(-90deg);
		margin-top: -3px;
	}
	@media (max-height: 700px) {
		padding: 8px 35px;
	}
}
@-webkit-keyframes bounce {
	50% {
		transform: rotate(-90deg) translateX(-5px);
		-webkit-transform: rotate(-90deg) translateX(-5px);
	}
	100% {
		transform: rotate(-90deg) translateX(0px);
		-webkit-transform: rotate(-90deg) translateX(0px);
	}
}
@keyframes bounce {
	50% {
		transform: rotate(-90deg) translateX(-5px);
		-webkit-transform: rotate(-90deg) translateX(-5px);
	}
	100% {
		transform: rotate(-90deg) translateX(0px);
		-webkit-transform: rotate(-90deg) translateX(0px);
	}
}
.building-ranking-full-link:hover {
	background-color: #99BE4F;
	color: black;
}
.building-ranking-full-link:hover #bounce-arrow-down {
	filter: brightness(-320%);
	-webkit-filter: brightness(-320%);
	animation: bounce 0.4s ease;
	-webkit-animation: bounce 0.4s ease;
	animation-iteration-count: 3;
	-webkit-animation-iteration-count: 3;
}
.full-ranking-page {
	width: 400px;
	height: 100vh;
	overflow-y: hidden;
	background: white;
	flex-shrink: 0;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.3s;
	z-index: 40;
	.building-ranking {
		display: block;
		overflow-y: scroll;
		max-height: 42vh;
	}
	.full-ranking-eui-image {
		display: flex;
		padding-top: 30px;
		border-top: 1px solid #e7e7e7;
		img {
			margin: auto;
			filter: grayscale(100%) brightness(75%) contrast(150%);
			margin-bottom: 10px;
		}
	}
}
.full-ranking-page.hide {
	left: -410px;
	z-index: 90;
}
