$text: #3c414b;
$light-text: rgba(45,49,56,0.7);
$text-inactive: rgba(60,65,75, 0.7);
$border: #EEEDEE;
$dark-gary: #2D3138;
$light-gray: #d6d6d6;
$bubble-background: #F6F6F6;
$light-green: #F5F8ED;
$green: #95BC43;
$shadow: rgba(0,0,0,0.16);

.total-campus-graph-title {
	position: relative;
	width: 100%;
	font-size: 16px;
	font-weight: 100;
	line-height: 22px;
	padding: 20px 0;
	.demand-zoom-helper {
		position: absolute;
		right: 30%;
		bottom: 0;
		color: $text;
		opacity: 0.9;
		font-size: 13px;
		font-weight: normal;
		img {
			margin-bottom: -1px;
		}
		@media (min-width: 1080px) {
			right: 21%;
		}
	}
}
.chart-wrapper {
	display: flex;
	flex-direction: row;
	.graph-and-checkboxes,
	.buttons {
		display: flex;
		flex-direction: column;
	}
	.graph-and-checkboxes {
		width: 85%;
		text-align: center;
	}
}
.campus-demand {
	padding: 0;
	max-width: 1050px;
	width: 1050px;
	margin: auto;
	margin-top: 40px;
	.campus-demand-story {
		width: 69%;
		margin-left: 10%;
		font-size: 16px;
		line-height: 20px;
	}
}
.graph-card {
	background-color: white;
	box-shadow: 0 2px 4px 0 $shadow;
	height: auto;
	padding: 30px 3% 40px 3%;
	margin: 0px;
	.info-icon{
        position: absolute;
        z-index: 1;
		margin-left: 72px;
		margin-top: -2px;
        cursor: pointer;
    }
    .speech-bubble-ds {
        display: none;
        background: $bubble-background;
        border: 3px solid $border;
        -webkit-border-radius: 3px;
                border-radius: 3px;
        -webkit-box-shadow: 0px 2px 4px $shadow;
                box-shadow: 0px 2px 4px $shadow;
        font-size: 14px;
        line-height: 17px;
        color: $text;
        margin: -28px 0 0 106px;
        max-width: 400px;
        padding: 5px 15px 0px;
        width: 240px;
        height: 90px;
        position: absolute;
        z-index: 1;
    }
    
    .speech-bubble-ds p {
        margin-bottom: -5px;
    }
    .speech-bubble-ds p:last-of-type {
        margin-bottom: 0;
    }
    
    .speech-bubble-ds-arrow {
        border-left: 21px solid transparent;
        border-top: 17px solid $border;
        bottom: 48px;
        position: absolute;
        right: 270px;
    }
    .speech-bubble-ds-arrow::before {
        border-left: 20px solid transparent;
        border-bottom: 15px solid $bubble-background;
        bottom: 18px;
        content: "";
        position: absolute;
        right: 0px;
    }
    .speech-bubble-ds-arrow::after {
        border-left: 20px solid transparent;
        border-top: 15px solid $bubble-background;
        bottom: 3px;
        content: "";
        position: absolute;
        right: 0px;
    }
    .info-icon:hover + .speech-bubble-ds{
        display: inline;
	}
	
	.total-energy-story {
		width: 90%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: auto;
		font-size: 14px;
		img{
			height: 40px;
			margin-right: 25px;
		}
		div{
			display: inline-block;
			font-weight: 450;
			p{
				font-weight: 10;
				color: $light-text;
				margin-top: 12px;
				line-height: 17px;
			}
			.total-energy-story-note{
				font-style: italic;
				margin-bottom: 43px;
				font-weight: 10;
				color: $light-text;
			}
		}
		.total-energy-story-column1 {
			width: 45%;
			flex-direction: column;
			.total-energy-story-box {
				display: flex;
				flex-direction: row;

			}
		}
		.total-energy-story-column2 {
			width: 45%;
			flex-direction: column;
			.total-energy-story-box {
				display: flex;
				flex-direction: row;
				margin-bottom: 25px;
				.total-energy-story-flag{
					padding: 3px 15px;
					background-color: $light-green;
					color: $green;
					border-radius: 12px;
					margin-left: 10px;
					font-weight: bold;
					&.pge_demand{
						background-color: transparent;
					}
				}
				&.pge_demand, &.hydropower{
					margin-left: -7px;
				}
			}
		}
	}
}
.units-label {
	color: $dark-gary;
	font-size: 13px;
	margin-bottom: 50px;
}
.campus-graph {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 60px;
	.campus-side-options {
		text-align: center;
		font-size: 16px;
		line-height: 18px;
		color: $light-gray;
		padding-top: 5px;
		margin-top: -27px;
		.campus-side-button {
			width: 100%;
			height: 40px;
			max-width: 260px;
			border: solid 2px $border;
			border-radius: 3px;
			box-sizing: border-box;
			margin: 5px;
			padding: 12px 0;
			cursor: pointer;
			background:white;
			color: $text-inactive;
			font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-size: 14px;
			letter-spacing: 0.6px;
			font-weight: 500;
		}
		.campus-side-button:hover,
		.campus-side-button.active,
		.campus-side-button[aria-pressed="true"] {
			background-color: white;
			border: solid 2px $dark-gary;
			color: $text;
			font-weight: 600;
		}
		.campus-side-oat-wrapper {
			padding-left: 12px;
			padding-top: 24px;
			.campus-side-oat-label-spacer {
				padding-left: 34px;
			}
		}
		.campus-side-oat {
			text-align: left;
			position: relative;
			height: 21px;
			line-height: 21px;
			display: block;
			cursor: pointer;
			margin-top: 20px;
		}
		.campus-side-oat input {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
		}
		.campus-side-oat .campus-side-oat-label:before {
			content: '';
			padding: 0;
			display: inline-block;
			height: 16px;
			width: 16px;
			border: solid 1px $light-gray;
			border-radius: 0;
			font-size: 12px;
			margin-right: 7px;
			vertical-align: middle;
		}
		.campus-side-oat input + .campus-side-oat-label:before {
			color: white;
			background-color: white;
			margin-right: 15px;
		}
		.campus-commodities {
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			width: 100%;
			height: 41%;
			padding-left: 5px;
			margin-left: 0px;
			.campus-commodity {
				display: flex;
				font-size: 14px;
				font-weight: 500;
				margin: auto;
				margin-bottom: 10px;
				input {
					margin: 2px 12px 0 0;
				}
				& {
				min-width: 180px;
				text-align: left;
				position: relative;
				height: 21px;
				line-height: 21px;
				display: block;
				cursor: pointer;
				}
				&.pge_demand{
					padding-bottom: 10px;
				}
			}
			.campus-commodity input {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}
			.campus-commodity .campus-commodity-label:before {
				content: '';
				padding: 0;
				display: inline-block;
				height: 16px;
				width: 16px;
				border: solid 1px $light-gray;
				border-radius: 3px;
				font-size: 12px;
				margin-right: 7px;
				vertical-align: middle;
			}
			.campus-commodity input + .campus-commodity-label:before {
				color: white;
				background-color: white;
			}
			.campus-commodity input:checked + .campus-commodity-label.pge_demand:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
				opacity: 0.8;
			}
			.campus-commodity input:checked + .campus-commodity-label.solar_farm_demand:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23CAD93E' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
				opacity: 0.8;
			}
			.campus-commodity input:checked + .campus-commodity-label.chcp_gas_demand:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23875878' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
				opacity: 0.8;
			}
			.campus-commodity input:checked + .campus-commodity-label.rooftop_solar_demand:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23cad93f' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
				opacity: 0.8;
			}
			.campus-commodity input:checked + .campus-commodity-label.biodigester_demand:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%239AC049' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.campus-commodity input:checked + .campus-commodity-label.other_gas_demand:before {
				background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
			}
			.campus-commodity input + .campus-commodity-label {
				font-size: 15px;
				font-weight: 500;
				line-height: 21px;
				color: $text-inactive;
				&.pge_demand{
					.pge_demand-label-spacer{
						padding-left: 25px;
						margin-top: -6px;
					}
				}
			}
			.campus-commodity input:checked + .campus-commodity-label {
				color: black;
			}
		}
	}
	
	@media (min-width: 769px) {
		.campus-chart {
			width: 72%;
		}
		.campus-side-options {
			width: 27%
		}
		.campus-commodities {
			width: 70%;
			padding: 10px;
			margin-left: 9%;
			.campus-commodity input + .campus-commodity-label:before {
				margin-right: 4px;
			}
		}
	}
	@media (min-width: 1080px) {
		.campus-chart {
			width: 80%;
		}
		.campus-side-options {
			width: 19%
		}
		.campus-commodities {
			width: 70%;
			padding: 10px;
			.campus-commodity input + .campus-commodity-label:before {
				margin-right: 15px;
			}
		}
	}
	.csv {
		padding-top: 5px;
		a {
			color: #b2b2b2;
			text-decoration: none;
		}
	}
	//    _   _ _       _          _                _
	//   | | | (_) __ _| |__   ___| |__   __ _ _ __| |_ ___
	//   | |_| | |/ _` | '_ \ / __| '_ \ / _` | '__| __/ __|
	//   |  _  | | (_| | | | | (__| | | | (_| | |  | |_\__ \
	//   |_| |_|_|\__, |_| |_|\___|_| |_|\__,_|_|   \__|___/
	//            |___/
	.highcharts-root {
		font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		.highcharts-axis-labels {
			letter-spacing: 1px;
			text-transform: uppercase;
		}
	}
}
