/* Aside from using BEM in here, we also use some pretty
 * new CSS features like filters and variables, as opposed
 * to using a CSS preprocessor.
 */
.sidebar-dropdown .ReactA11ySelect {
  font: 1rem sans-serif;
  --border-color: #ddd;
  --background-color: #fff;
  --shadow-color: #aaa;
  --highlighted-foreground-color: #fff;
  --highlighted-background-color: #333;
}

.sidebar-dropdown .ReactA11ySelect__button {
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  font-size: 1rem;
  text-align: left;
  display: inline;
  margin: 0;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}

.sidebar-dropdown .ReactA11ySelect__button:hover {
  cursor: pointer;
}

.sidebar-dropdown .ReactA11ySelect__button[aria-expanded="true"] {
  background-color: var(--border-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
}

.sidebar-dropdown .ReactA11ySelect__button__arrow-indicator:after {
  font-size: 1.5rem;
  content: "\25be";
  margin-left: 1rem;
  line-height: 0;
}

.sidebar-dropdown .ReactA11ySelect__ul {
  background-color: var(--bacground-color);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  padding: 0;
  /* position: absolute; */
  background: var(--background-color);
  box-shadow: 0 4px 8px var(--shadow-color);
  -webkit-transiton: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -transition: opacity 0.2s;
  list-style: none;
  z-index: 1;
}

.sidebar-dropdown .ReactA11ySelect__ul:not([aria-hidden="true"]) {
  display: block;
}

.sidebar-dropdown .ReactA11ySelect__ul[aria-hidden="true"] {
  display: none;
}

.sidebar-dropdown .ReactA11ySelect__ul__li {
  position: relative;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  color: #111;
}

.sidebar-dropdown .ReactA11ySelect__ul__li:focus {
  background-color: var(--highlighted-background-color);
  color: var(--highlighted-foreground-color);
}

.sidebar-dropdown .ReactA11ySelect__ul__li:not([aria-checked="true"]) {
  padding-left: 1.75rem;
  padding-right: 2.25rem;
}

.sidebar-dropdown .ReactA11ySelect__ul__li[disabled] {
  filter: opacity(40%);
  padding-right: 2.25rem;
}

.sidebar-dropdown .ReactA11ySelect__ul__li[aria-checked="true"] > * {
  display: inline;
}

.sidebar-dropdown .ReactA11ySelect__ul__li__selected-indicator:after {
  content: "\2714";
  float: "left";
}
