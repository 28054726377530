$background-gray: #eeedee;
$dark-gray: #3c414b;
$hover-gray: rgba(255, 255, 255, 0.15);
$light-gray: #9da0a5;

.water-navigation {
	height: 66px;
	width: 100%;
	background-color: $dark-gray;
	color: $light-gray;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1.7px;
	text-align: center;
	.water-navigation-wrapper {
		display: flex;
		justify-content: space-between;
		width: 76%;
		max-width: 950px;
		margin: auto;
	}
}
.water-navigation-button {
	width: 175px;
	cursor: pointer;
	padding: 25px 15px;
	transition: ease all 0.2s;
	&:hover {
		color: white;
		background-color: $hover-gray;
	}
	&.active {
		color: $dark-gray;
		background-color: $background-gray;
	}
}
