$light-gray: #9B9B9B;
$electricity: #92BD3B;
$water: #32BBD5;
$overuse: #FC6616;
.ewc-savings-card-desktop {
	display: flex;
	justify-content: space-between;
	height: 290px;
	margin-top: 30px;
	.ewc-savings-card-left {
		width: 65%;
		margin-right: 30px;
		.ewc-savings-card-title {
			font-size: 16px;
			line-height: 20px;
			font-weight: 600;
		}
		.ewc-savings-chart {
			width: 430px;
			height: 220px;
			margin: 15px auto;
		}
		.ewc-savings-range-picker {
			display: flex;
			justify-content: space-between;
			width: 80%;
			max-width: 250px;
			margin: auto;
			font-size: 15px;
			line-height: 18px;
			font-weight: 600;
			.ewc-savings-range-arrow {
				padding: 2px;
				cursor: pointer;
				&.hidden {
					visibility: hidden;
				}
				&.right {
					img {
						margin-top: -1px;
						transform: rotate(180deg);
					}
				}
			}
			.ewc-savings-range-selection {
				margin-top: 5px;
			}
		}
	}
	.ewc-savings-card-right {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 35%;
		color: $light-gray;
		font-size: 14px;
		line-height: 24px;
		.ewc-savings-stats {
			display: flex;
			flex-direction: column;
			margin: 0 12%;
			.ewc-savings-detail {
				display: flex;
				justify-content: space-between;
				.ewc-savings-detail-value {
					color: black;
					font-size: 15px;
					&.electricity {
						color: $electricity;
					}
					&.water {
						color: $water;
					}
					&.overuse {
						color: $overuse;
					}
				}
			}
		}
		.ewc-savings-example {
			display: flex;
			img {
				width: 5%;
				height: auto;
				margin-right: 7%;
			}
			.ewc-savings-example-detail {
				width: 85%;
			}
		}
	}
}
