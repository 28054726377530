.sidebar-dropdown {
	.ReactA11ySelect {
		width: 100%;
		background: white;
		margin-top: 10px;
		box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
		.ReactA11ySelect__button {
			width: 100%;
			border-radius: 0;
			background: white;
			padding: 14px 14px 10px 14px;
			box-sizing: border-box;
			font-size: 14px !important;
			font-family: Proxima Nova, Lucida Grande, Lucida Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			border: solid 1px #c7c7c7;
			box-shadow: 0 1px 2px 0 rgba(127, 127, 155, 0.1);
			@media (max-height: 700px) {
				padding: 6px 14px 2px;
			}
			&::after {
				content: url('images/downarrow.svg');
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					content: '';
				}
			}
			span {
				display: flex;
				flex-direction: column;
				div {
					display: flex;
					flex-direction: row;
					align-items: center;
					&.text {
						margin-left: 12px;
						margin-top: 1px;
					}
					&.main-text {
						font-weight: bold;
					}
				}
				div:nth-of-type(1) {
					margin-bottom: 2px;
				}
			}
			svg.main-svg {
				circle:nth-of-type(1) {
					fill: #9eb8d3;
				}
				circle:nth-of-type(3) {
					fill: #5a8fc7;
				}
			}
			svg.partial-svg {
				circle {
					fill: #5a8fc7;
				}
			}
		}
		ul {
			z-index: 3;
			width: 100%;
			border-radius: 0;
			margin: 0;
			background: white;
			div {
				display: flex;
				flex-direction: row;
			}
			li {
				outline: 0;
				box-sizing: border-box;
				font-size: 14px !important;
				font-family: Proxima Nova, Lucida Grande, Lucida Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
				div:nth-of-type(1) {
					display: flex;
					flex-direction: column;
					div {
						display: flex;
						flex-direction: row;
						align-items: center;
						&.text {
							margin-left: 12px;
						}
						&.main-text {
							font-weight: bold;
						}
					}
					div:nth-of-type(1) {
						margin-bottom: 2px;
					}
					div:nth-of-type(2) {
						display: none;
					}
				}
				&[aria-checked="true"] {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					svg.main-svg {
						circle:nth-of-type(1) {
							fill: #9eb8d3;
						}
						circle:nth-of-type(3) {
							fill: #5a8fc7;
						}
					}
					&::after {
						content: url('images/checkmark.svg');
						margin-right: 8px;
						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							content: '';
						}
					}
				}
				&:focus {
					background-color: #F5F5F5;
					color: black;
				}
			}
		}
		.ReactA11ySelect:hover .ReactA11ySelect__button:hover {
			box-shadow: 0 4px 8px #888;
		}
		.ReactA11ySelect__button__arrow-indicator {
			display: none !important;
		}
		.ReactA11ySelect__ul__li__selected-indicator {
			display: none !important;
		}
		.ReactA11ySelect__ul__li {
			padding: 14px 14px 10px 14px;
			width: 100%;
		}
	}
}
