.total-campus-graph-title {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-weight: 100;
  line-height: 22px;
  padding: 20px 0;
}
.total-campus-graph-title .demand-zoom-helper {
  position: absolute;
  right: 30%;
  bottom: 0;
  color: #3c414b;
  opacity: 0.9;
  font-size: 13px;
  font-weight: normal;
}
.total-campus-graph-title .demand-zoom-helper img {
  margin-bottom: -1px;
}
@media (min-width: 1080px) {
  .total-campus-graph-title .demand-zoom-helper {
    right: 21%;
  }
}

.chart-wrapper {
  display: flex;
  flex-direction: row;
}
.chart-wrapper .graph-and-checkboxes,
.chart-wrapper .buttons {
  display: flex;
  flex-direction: column;
}
.chart-wrapper .graph-and-checkboxes {
  width: 85%;
  text-align: center;
}

.campus-demand {
  padding: 0;
  max-width: 1050px;
  width: 1050px;
  margin: auto;
  margin-top: 40px;
}
.campus-demand .campus-demand-story {
  width: 69%;
  margin-left: 10%;
  font-size: 16px;
  line-height: 20px;
}

.graph-card {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  height: auto;
  padding: 30px 3% 40px 3%;
  margin: 0px;
}
.graph-card .info-icon {
  position: absolute;
  z-index: 1;
  margin-left: 72px;
  margin-top: -2px;
  cursor: pointer;
}
.graph-card .speech-bubble-ds {
  display: none;
  background: #F6F6F6;
  border: 3px solid #EEEDEE;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  line-height: 17px;
  color: #3c414b;
  margin: -28px 0 0 106px;
  max-width: 400px;
  padding: 5px 15px 0px;
  width: 240px;
  height: 90px;
  position: absolute;
  z-index: 1;
}
.graph-card .speech-bubble-ds p {
  margin-bottom: -5px;
}
.graph-card .speech-bubble-ds p:last-of-type {
  margin-bottom: 0;
}
.graph-card .speech-bubble-ds-arrow {
  border-left: 21px solid transparent;
  border-top: 17px solid #EEEDEE;
  bottom: 48px;
  position: absolute;
  right: 270px;
}
.graph-card .speech-bubble-ds-arrow::before {
  border-left: 20px solid transparent;
  border-bottom: 15px solid #F6F6F6;
  bottom: 18px;
  content: "";
  position: absolute;
  right: 0px;
}
.graph-card .speech-bubble-ds-arrow::after {
  border-left: 20px solid transparent;
  border-top: 15px solid #F6F6F6;
  bottom: 3px;
  content: "";
  position: absolute;
  right: 0px;
}
.graph-card .info-icon:hover + .speech-bubble-ds {
  display: inline;
}
.graph-card .total-energy-story {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto;
  font-size: 14px;
}
.graph-card .total-energy-story img {
  height: 40px;
  margin-right: 25px;
}
.graph-card .total-energy-story div {
  display: inline-block;
  font-weight: 450;
}
.graph-card .total-energy-story div p {
  font-weight: 10;
  color: rgba(45, 49, 56, 0.7);
  margin-top: 12px;
  line-height: 17px;
}
.graph-card .total-energy-story div .total-energy-story-note {
  font-style: italic;
  margin-bottom: 43px;
  font-weight: 10;
  color: rgba(45, 49, 56, 0.7);
}
.graph-card .total-energy-story .total-energy-story-column1 {
  width: 45%;
  flex-direction: column;
}
.graph-card .total-energy-story .total-energy-story-column1 .total-energy-story-box {
  display: flex;
  flex-direction: row;
}
.graph-card .total-energy-story .total-energy-story-column2 {
  width: 45%;
  flex-direction: column;
}
.graph-card .total-energy-story .total-energy-story-column2 .total-energy-story-box {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}
.graph-card .total-energy-story .total-energy-story-column2 .total-energy-story-box .total-energy-story-flag {
  padding: 3px 15px;
  background-color: #F5F8ED;
  color: #95BC43;
  border-radius: 12px;
  margin-left: 10px;
  font-weight: bold;
}
.graph-card .total-energy-story .total-energy-story-column2 .total-energy-story-box .total-energy-story-flag.pge_demand {
  background-color: transparent;
}
.graph-card .total-energy-story .total-energy-story-column2 .total-energy-story-box.pge_demand, .graph-card .total-energy-story .total-energy-story-column2 .total-energy-story-box.hydropower {
  margin-left: -7px;
}

.units-label {
  color: #2D3138;
  font-size: 13px;
  margin-bottom: 50px;
}

.campus-graph {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 60px;
}
.campus-graph .campus-side-options {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: #d6d6d6;
  padding-top: 5px;
  margin-top: -27px;
}
.campus-graph .campus-side-options .campus-side-button {
  width: 100%;
  height: 40px;
  max-width: 260px;
  border: solid 2px #EEEDEE;
  border-radius: 3px;
  box-sizing: border-box;
  margin: 5px;
  padding: 12px 0;
  cursor: pointer;
  background: white;
  color: rgba(60, 65, 75, 0.7);
  font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.6px;
  font-weight: 500;
}
.campus-graph .campus-side-options .campus-side-button:hover,
.campus-graph .campus-side-options .campus-side-button.active,
.campus-graph .campus-side-options .campus-side-button[aria-pressed=true] {
  background-color: white;
  border: solid 2px #2D3138;
  color: #3c414b;
  font-weight: 600;
}
.campus-graph .campus-side-options .campus-side-oat-wrapper {
  padding-left: 12px;
  padding-top: 24px;
}
.campus-graph .campus-side-options .campus-side-oat-wrapper .campus-side-oat-label-spacer {
  padding-left: 34px;
}
.campus-graph .campus-side-options .campus-side-oat {
  text-align: left;
  position: relative;
  height: 21px;
  line-height: 21px;
  display: block;
  cursor: pointer;
  margin-top: 20px;
}
.campus-graph .campus-side-options .campus-side-oat input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.campus-graph .campus-side-options .campus-side-oat .campus-side-oat-label:before {
  content: "";
  padding: 0;
  display: inline-block;
  height: 16px;
  width: 16px;
  border: solid 1px #d6d6d6;
  border-radius: 0;
  font-size: 12px;
  margin-right: 7px;
  vertical-align: middle;
}
.campus-graph .campus-side-options .campus-side-oat input + .campus-side-oat-label:before {
  color: white;
  background-color: white;
  margin-right: 15px;
}
.campus-graph .campus-side-options .campus-commodities {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 41%;
  padding-left: 5px;
  margin-left: 0px;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  margin: auto;
  margin-bottom: 10px;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input {
  margin: 2px 12px 0 0;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity {
  min-width: 180px;
  text-align: left;
  position: relative;
  height: 21px;
  line-height: 21px;
  display: block;
  cursor: pointer;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity.pge_demand {
  padding-bottom: 10px;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity .campus-commodity-label:before {
  content: "";
  padding: 0;
  display: inline-block;
  height: 16px;
  width: 16px;
  border: solid 1px #d6d6d6;
  border-radius: 3px;
  font-size: 12px;
  margin-right: 7px;
  vertical-align: middle;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input + .campus-commodity-label:before {
  color: white;
  background-color: white;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input:checked + .campus-commodity-label.pge_demand:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
  opacity: 0.8;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input:checked + .campus-commodity-label.solar_farm_demand:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23CAD93E' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
  opacity: 0.8;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input:checked + .campus-commodity-label.chcp_gas_demand:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23875878' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
  opacity: 0.8;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input:checked + .campus-commodity-label.rooftop_solar_demand:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23cad93f' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
  opacity: 0.8;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input:checked + .campus-commodity-label.biodigester_demand:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%239AC049' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input:checked + .campus-commodity-label.other_gas_demand:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input + .campus-commodity-label {
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(60, 65, 75, 0.7);
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input + .campus-commodity-label.pge_demand .pge_demand-label-spacer {
  padding-left: 25px;
  margin-top: -6px;
}
.campus-graph .campus-side-options .campus-commodities .campus-commodity input:checked + .campus-commodity-label {
  color: black;
}
@media (min-width: 769px) {
  .campus-graph .campus-chart {
    width: 72%;
  }
  .campus-graph .campus-side-options {
    width: 27%;
  }
  .campus-graph .campus-commodities {
    width: 70%;
    padding: 10px;
    margin-left: 9%;
  }
  .campus-graph .campus-commodities .campus-commodity input + .campus-commodity-label:before {
    margin-right: 4px;
  }
}
@media (min-width: 1080px) {
  .campus-graph .campus-chart {
    width: 80%;
  }
  .campus-graph .campus-side-options {
    width: 19%;
  }
  .campus-graph .campus-commodities {
    width: 70%;
    padding: 10px;
  }
  .campus-graph .campus-commodities .campus-commodity input + .campus-commodity-label:before {
    margin-right: 15px;
  }
}
.campus-graph .csv {
  padding-top: 5px;
}
.campus-graph .csv a {
  color: #b2b2b2;
  text-decoration: none;
}
.campus-graph .highcharts-root {
  font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.campus-graph .highcharts-root .highcharts-axis-labels {
  letter-spacing: 1px;
  text-transform: uppercase;
}

