$electricity: #98bf47;
$steam: #f69321;
$cooling: #15a9c8;
$gas: #8D67D6;
$solar: #F8D81C;
$dark-gray: #2D3138;
$text: #3C414B;
$button-text: rgba(45, 49, 56, 0.7);
$shadow: rgba(0, 0, 0, 0.12);
$background-gray: #EEEDEE;

$background: rgba(240, 240, 240, 0.0);

.commodity-breakdown {
	display: flex;
	height: 590px;
	width: auto;
	box-shadow: 0 4px 7px 0 $shadow;
	background: white;
	padding: 35px;
	margin-top: 50px;
	flex-direction: row;
	flex-wrap: wrap;
	.commodity-title {
		font-size: 16px;
		font-weight: bold;
		align-self: center;
		padding-bottom: 35px;
	}
	.commodity-graph {
		height: 430px;
		.commodity-select {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
			.commodity-show-label {
				font-size: 14px;
				margin: auto 10px auto 20px;
			}
		}
		.commodity-select .ReactA11ySelect {
			width: 100%;
			background-color: $background;
			position: relative;
			.ReactA11ySelect__button {
			  width: 185px;
			  height: 45px;
				padding: 8px 14px;
			  position: relative;
			  margin-left: 0px;
			  margin-right: 10px;
			  background: white;
			  font-size: 14px !important;
			  font-family: Proxima Nova, Lucida Grande, Lucida Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
			  display: flex;
			  flex-direction: row;
			  justify-content: space-between;
			  border: 2px solid $background-gray;
			  border-radius: 3px;
			  box-shadow: none;
			  &:hover{
				border: 2px solid $dark-gray;
			  }
			  span {
				display: flex;
				flex-direction: column;
				text-align: center;
				div {
				  display: flex;
				  flex-direction: row;
				  align-items: center;
				  text-align: center;
				  &.text {
					padding-left: 5px;
					margin-top: -6px;
				  }
				  &.main-text {
					font-weight: bold;
					color: black;
				  }
				}
			  }
			}
			ul {
			  z-index: 3;
			  box-shadow: none;
			  width: 184px;
			  margin-top: -1px;
			  border-radius: 3px;
			  background: white;
			  position: absolute;
			  div {
				display: flex;
				flex-direction: row;
			  }
			  li {
				outline: 0;
				box-sizing: border-box;
				font-size: 14px !important;
				font-family: Proxima Nova, Lucida Grande, Lucida Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
				border: 1px solid $background-gray;
				width: 100%;
				div:nth-of-type(1) {
				  display: flex;
				  flex-direction: column;
				  div {
					display: flex;
					flex-direction: row;
					align-items: center;
					&.text {
					  padding-left: 35px;
					}
					&.main-text {
					  color: $text;
					  padding-left: 5px;
					  &:hover {
						color: black;
					  }
					}
					&:nth-of-type(1) {
					  margin-bottom: 2px;
					}
					&:nth-of-type(2) {
					  display: none;
					}
				  }
				}
			  }
			}
		  }
	}
	@media (min-width: 769px) {
		.commodity-graph{
		width: 46%;
		margin-right: 1%;
			.commodity-select {
				width: 200%;
			}
		}
		.commodity-stats {
			width: 53%
		}		
		.commodity-csv {
			left: 68%;
		}
	}
	@media (min-width: 1080px) {
		.commodity-graph{
		width: 55%;
		margin-right: 8%;
			.commodity-select {
				width: 100%;
			}
		}
		.commodity-stats {
			width: 35%
		}
		.commodity-csv {
			left: 79%;
		}
	}
	.commodity-stats {
		height: 480px;
		font-size: 13px;
		line-height: 24px;
		.commodity-header,
		.commodity-footer {
			color: $text;
			display: flex;
			align-items: stretch;
		}
		.commodity-data {
			display: flex;
		}
		.column1,
		.column2,
		.column3,
		.column4 {
			padding: 2px 5px;
			border-top: solid 1px $background-gray;
			font-size: 13px;
			line-height: 23px;
		}
		.column1 {
			width: 20%;
		}
		.column2 {
			width: 45%;
			text-align: right;
			padding-right: 10px;
		}
		.column3 {
			width: 35%;
			text-align: right;
		}
		.column1,
		.column2 {
			color: $text;
		}
		.commodity-stats-title {
			padding: 6px 0 88px;
			font-size: 16px;
			font-weight: bold;
		}
		.commodity-header {
			color: $text;
			margin-bottom: 10px;
			padding: 0 4px;
			.column1,
			.column2,
			.column3,
			.column4 {
				font-size: 14px;
				border: none;
				padding-right: 5px;
			}
		}
		.commodity-data {
			font-size: 15px;
			line-height: 24px;
			padding: 0 4px;
		}
		.commodity-footer {
			background-color: $background-gray;
			height: 44px;
			margin-top: 8px;
			padding: 0 4px;
			.column1 {
				color: black;
				margin: auto 0;
				font-size: 14px;
				text-align: left;
				border: none;
			}
			.column2,
			.column3 {
				margin: auto 0;
				color: black;
				font-size: 14px;
				text-align: right;
				font-weight: 600;
				border: none;
			}
		}
		.disclaimer {
			font-size: 12px;
			text-align: right;
			color: $text;
		}
	}
	.csv-div{
		width: 150px;
		margin-left: 800px;
		.commodity-csv {
			position: relative;
			bottom: 0px;
			button {
				margin-top: 55px;
				width: 150px;
				height: 40px;
				background-color: white;
				border: solid 1px $background-gray;
				outline: none;
				font-size: 13px;
				color: $text;
				letter-spacing: 1px;
				padding: 0 5px;
				svg {
					float: right;
					opacity: 0.3;
				}
			}
			button:hover {
				background-color: $background-gray;
				cursor: pointer;
				color: $text;
				font-weight: 600;
				border: solid $background-gray 2px;
				svg {
					opacity: 1;
				}
			}
		}
	}
	
}
.csv-div{
	width: 100%;
	height: 40px;
	margin-top: 60px;
	.commodity-csv {
		width: 150px;
		max-width: 160px;
		text-align: center;
		font-size: 16px;
		line-height: 18px;
		.commodity-side-button {
			width: 100%;
			height: 25px;
			border: solid 2px $background-gray;
			border-radius: 2px;
			margin: 10px;
			padding-top: 10px;
			cursor: pointer;
			position: absolute;
			font-weight: 500;
			top: -9px;
			right: 105px;
			display: flex;
			color: $button-text;
			img{
				width: 11px;
				height: 13px;
				margin-right: 10px;
				margin-left: 15px;
				padding-top: 2px;
			}
		}
		.commodity-side-button:hover,
		.commodity-side-button.active {
			background-color: white;
			border: solid 2px $dark-gray;
			color: $text;
			font-weight: 600;
		}
	}
	.csv {
		padding-top: 5px;
		a {
			color: $text;
			text-decoration: none;
			font-size: 14px;
		}
	}
}

