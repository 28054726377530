.saving-header {
  width: 100%;
  background-image: url("./images/Energy-Saving-header.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 70px;
}

.saving-header-wrap {
  display: flex;
  justify-content: space-around;
  width: 70%;
  height: 80%;
  margin: auto;
  padding-top: 25px;
}
.saving-header-wrap .saving-header-title {
  padding-top: 100px;
  width: 30%;
  margin: 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  color: white;
}
.saving-header-wrap .saving-header-description {
  width: 60%;
  padding-top: 100px;
  margin: 0 0 0 auto;
  color: white;
  font-size: 16px;
  line-height: 22px;
}
.saving-header-wrap .saving-header-description p {
  margin: 0;
}
.saving-header-wrap .saving-header-description .link {
  color: white;
}
.saving-header-wrap .saving-header-description .link:hover {
  text-decoration: underline;
}

