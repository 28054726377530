$dark-gray: #3C414B;
$pale-gray: #EEEDEE;

.app-menu{
	align-items: center;
	display: flex;
	justify-content: space-between;
	.apps-base {
		margin-left: 30%;	
		color: $dark-gray;
		font-size: 15px;
		font-weight: 600;
		width: 140px;
	}
	.menu-close {
		background-color: white;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		border: 1px solid $dark-gray;
		margin-left: 21%;
		text-align: center;
		cursor: pointer;
		transition: transform 200ms ease;
	}
}
.menu-placeholder {
	font-size: 22px;
	line-height: 24px;
	font-weight: 500;
	margin: auto;
}
.app-menu{
	.menu-close:hover{
		background-color: $dark-gray;
		color: white;
		transform: rotate(-90deg);
		transition: transform 200ms ease;
	}
}