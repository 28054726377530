.building-header {
	width: 100%;
	height: 410px;
	background-color: rgba(60, 65, 75, 0.85);
	z-index: 100;
}
.building-header-title {
	width: 100%;
	padding-top: 140px;
	font-size: 42px;
	font-weight: 600;
	color: white;
	text-align: center;
}
.building-header-stats {
	display: flex;
	justify-content: space-around;
	width: 60%;
	margin: auto;
	padding-top: 40px;
	.header-stats-column {
		font-size: 18px;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.5px;
		width: 30%;
		.header-stats-column-title {
			color: #c5c5c5;
			font-weight: 400;
		}
		.header-stats-column-stat {
			color: white;
			font-weight: 600;
		}
	}
}
.building-header-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: inherit;
	z-index: -1;
	overflow: hidden;
	img {
		object-fit: cover;
		object-position: center;
		width: inherit;
		height: inherit;
		z-index: inherit;
	}
	@media (orientation: portrait) {
		width: inherit;
		height: 100%;		
	}
}
.building-header-map-link {
	width: 162px;
	height: 30px;
	padding: 6px 0;
	position: absolute;
	display: inline-block;
	top: 24px;
	right: 15%;
	line-height: 20px;
	cursor: pointer;
	font-weight: 600;
	background-color: #3c414b;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	transition: all 0.3s ease;
	color: #999999;
	letter-spacing: 1.7px;
	text-decoration: none;
	border-radius: 2px;
	transition: ease all 0.3s;
	img {
		width: 20px;
		height: 20px;
		filter: brightness(320%);
		margin: 6px 8px -5px 10px;
	}
}
@-webkit-keyframes bounce-left {
	50% {
		transform: translateX(-5px);
		-webkit-transform: translateX(-5px);
	}
	100% {
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
	}
}
@keyframes bounce-left {
	50% {
		transform: translateX(-5px);
		-webkit-transform: translateX(-5px);
	}
	100% {
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
	}
}
.building-header-map-link:hover {
	background-color: #99BE4F;
	color: black;
}
.building-header-map-link:hover #bounce-arrow-left {
	filter: brightness(-320%);
	-webkit-filter: brightness(-320%);
	animation: bounce-left 0.4s ease;
	-webkit-animation: bounce-left 0.4s ease;
	animation-iteration-count: 3;
	-webkit-animation-iteration-count: 3;
}

@media (max-width: 850px) {
	.building-header {
		height: auto;
		padding: 40px 0;
		.building-header-title {
			padding-top: 10px;
		}
		.building-header-image {
			max-height: 400px;
		}
	}
	.building-header-stats {
		flex-direction: column;
		padding-top: 20px;
		margin: auto;
		width: 90%;
		.header-stats-column {
			line-height: 22px;
			width: auto;
			padding: 10px 0;
		}
	}
	.building-header-map-link {
		display: none;
	}
}