// Import all partials
@use 'partials' as *;

body {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova","Lucida Grande","Lucida Sans","Helvetica Neue",Helvetica,Arial,sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//auto-sharpening of images that have been resized
img {
  image-rendering: -webkit-optimize-contrast;
}

// any images we forgot to mark with the alt attribute, give a big dashed red border
img[alt=""],
img:not([alt]) {
  border: 5px dashed #c00;
}
// but don't mark leaflet tiles or marker shadows, they can't be given alt attributes
.leaflet-tile-pane, .leaflet-shadow-pane {
  img {
    border: none;
  }
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}