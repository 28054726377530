.buildingProjectsView-wrapper .chart-container {
  width: 450px;
  float: left;
  margin-right: 50px;
}
.buildingProjectsView-wrapper .buildingProjects-title {
  text-align: left;
  font-size: 20px;
  margin-top: 20px;
}
.buildingProjectsView-wrapper .project-grid {
  margin-top: 25px;
  width: 835px;
}
.buildingProjectsView-wrapper .table-container {
  width: 300px;
  margin-top: 100px;
  margin-left: 20px;
  float: left;
}

.clear {
  clear: both;
}

.chart-title-electricity,
.chart-title-steam,
.chart-title-chilledWater,
.chart-title-totalSavings {
  font-weight: bold;
  text-align: left;
  margin-bottom: 30px;
}

.buildingProjectsView-wrapper .chart-container .chart-title-electricity {
  color: #98be48;
}
.buildingProjectsView-wrapper .chart-container .chart-title-steam {
  color: #f49233;
}
.buildingProjectsView-wrapper .chart-container .chart-title-chilledWater {
  color: #25a9c6;
}
.buildingProjectsView-wrapper .chart-container .chart-title-totalSavings {
  color: #222932;
}

.savingsTable-wrapper {
  position: relative;
  left: 15px;
}

.savingsTable-wrapper .headings-wrapper {
  display: flex;
  background-color: #f5f5f5;
}

.savingsTable-wrapper .table-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.savingsTable-wrapper .headings-wrapper .table-heading {
  flex: 1;
  display: inline-block;
  margin: 10px 30px;
  font-weight: bold;
  text-align: left;
}

.savingsTable-wrapper .table-row .table-row-item {
  flex: 1;
  display: inline-block;
  margin: 10px 30px;
  text-align: left;
}

.heading-container {
  height: 40px;
  width: 100%;
  line-height: 30px;
}

.ghausi-container,
.pes-container,
.vm3a-container {
  height: 150px;
  width: 100%;
}

.totals-container {
  height: 36px;
  width: 100%;
}

