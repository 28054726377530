$dark-gray: #2C3037;
$light-gray: #6C6C6C;
$divider: #F5F5F5;
$electricity: #92bd3b;
$first: #F1D021;
$second: #C2C2C2;
$third: #D8956F;
.ewc-landing {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.ewc-logo {
		width: 173px;
		margin-top: 55px;
		margin-bottom: 35px;
	}
	.ewc-landing-header {
		width: 70%;
		font-size: 20px;
		color: white;
		text-align: center;
		font-weight: 600;
		margin: 20px 0 0;
	}
	.ewc-landing-switch {
		width: 80%;
		max-width: 900px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		text-align: left;
		padding: 20px 0;
		font-size: 15px;
		line-height: 18px;
		justify-content: space-between;
		.ewc-landing-switch-title {
			margin: auto;
			font-weight: 600;
			width: 40%;
			min-width: 301px;
			@media(max-width: 768px) {
				width: 90%;
				text-align: center;
			}
			.ewc-landing-switch-subtitle {
				margin: 2px auto 4px;
				color: $dark-gray;
				font-style: italic;
				font-size: 14px;
				opacity: 0.6;
				a {
					color: $dark-gray;
					text-decoration: underline;
				}
			}
		}
		.ewc-switch-toggle {
			color: $dark-gray;
			.ewc-switch-description {
				opacity: 0.6;
			}
			.ewc-switch-button.active {
				img {
					filter: brightness(3);
				}
			}
		}
	}
	.ewc-ranking-table {
		width: 96%;
		max-width: 900px;
		padding: 10px 2%;
		margin: 0 auto;
		.ranking-table-header {
			display: flex;
			margin: auto;
			width: 96%;
			font-size: 14px;
			line-height: 17px;
			opacity: 0.6;
			.ranking-table-headline-team {
				width: 60%;
				margin-right: 3%;
				text-align: center;
			}
			.ranking-table-headline-savings {
				width: 30%;
				text-align: right;
				margin-right: 7%;
			}
		}
		.ranking-table-card-wrapper {
			display: flex;
			flex-direction: column;
			position: relative;
			width: 80%;
			height: 95px;
			padding: 15px 8%;
			margin: 12px auto;
			background: white;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
			color: black;
			.ranking-table-card {
				margin-top: 15px;
				display: flex;
				justify-content: space-between;
				font-size: 16px;
				line-height: 20px;
				font-weight: 600;
				.ranking-table-value {
					position: absolute;
					top: 42%;
					left: 5%;
					&.value1 {
						color: $first;
					}
					&.value2 {
						color: $second;
					}
					&.value3 {
						color: $third;
					}
				}
				.ranking-table-building {
					width: 80%;
					margin-left: 7%;
					margin-top: 1px;
					font-size: 14px;
				}
				.ranking-table-percent {
					flex-grow: 1;
					text-align: right;
				}
				@media(max-width: 400px) {
					margin-top: 0;
				}
			}
			.ranking-table-buildings {
				width: 60%;
				margin: 8px 0 0 7%;
				font-size: 14px;
				line-height: 20px;
				color: $light-gray;
			}
			.ranking-table-divider {
				position: absolute;
				right: 20%;
				top: 0;
				width: 2px;
				height: 95px;
				background: $divider;
				@media(max-width: 768px) {
					height: 125px;
					right: 25%;
				}
				@media(max-width: 500px) {
					right: 29%;
				}
			}
			@media(min-width: 769px) {
				width: 90%;
				height: 65px;
				padding: 15px 4%;
				.ranking-table-card {
					margin-top: 8px;
				}
			}
		}
	}
	.ewc-landing-footer {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		flex-wrap: wrap;
		width: 90%;
		max-width: 900px;
		.ewc-landing-footer-raffle {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			width: 55%;
			padding: 20px 40px 30px 5px;
			color: $dark-gray;
			text-align: left;
			.ewc-about-toggle,
			.ewc-calculation-toggle {
				margin-bottom: 20px;
				font-size: 15px;
				line-height: 20px;
				.ewc-about-toggle-header,
				.ewc-calculation-toggle-header {
					font-weight: 600;
					cursor: pointer;
					.ewc-toggle-symbol {
						font-size: 20px;
						margin-right: 20px;
						&.minus {
							margin-left: 2px;
						}
					}
				}
				.ewc-about-toggle-expanded,
				.ewc-calculation-toggle-expanded {
					padding: 0 32px;
					opacity: 0.7;
					img {
						width: 90%;
						height: auto;
						max-width: 475px;
						margin-top: 10px;
						opacity: 1;
					}
					@media(max-width: 400px) {
						width: 100%;
					}
				}
				.ewc-about-toggle-details-enter,
				.ewc-calculation-toggle-details-enter {
					transform: scaleY(0);
					transform-origin: center top;
				}
				.ewc-about-toggle-details-enter-active,
				.ewc-calculation-toggle-details-enter-active {
					transform: scaleY(1);
					transition: ease all 0.3s;
				}
				.ewc-about-toggle-details-exit,
				.ewc-calculation-toggle-details-exit {
					transform: scaleY(1);
					transform-origin: center top;
				}
				.ewc-about-toggle-details-exit-active,
				.ewc-calculation-toggle-details-exit-active {
					transform: scaleY(0);
					transition: ease all 300ms;
				}
			}
		}
		.ewc-logos {
			width: 260px;
		}
	}
}
