@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Bold.eot");
  src: url("./fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova-Bold.woff2") format("woff2"), url("./fonts/ProximaNova-Bold.woff") format("woff"), url("./fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Medium.eot");
  src: url("./fonts/ProximaNova-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova-Medium.woff2") format("woff2"), url("./fonts/ProximaNova-Medium.woff") format("woff"), url("./fonts/ProximaNova-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/ProximaNova-Regular.eot");
  src: url("./fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova-Regular.woff2") format("woff2"), url("./fonts/ProximaNova-Regular.woff") format("woff"), url("./fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova", "Lucida Grande", "Lucida Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

img[alt=""],
img:not([alt]) {
  border: 5px dashed #c00;
}

.leaflet-tile-pane img, .leaflet-shadow-pane img {
  border: none;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}

