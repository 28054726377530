$hover-background: #99BE4F;
$dark-gray: #3c414b;

.campus-header {
	width: 100%;
	background-image: url("./images/EnergyStory-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding-bottom: 70px;
}
.campus-header-wrap{
	display: flex;
	justify-content: space-around;
	width: 70%;
	height: 80%;
	margin: auto;
	padding-top: 25px;
	.campus-header-title {
		padding-top: 100px;
		width: 30%;
		margin: 0px;
		font-size: 36px;
		font-weight: 600;
		line-height: 1.2;
		text-align: left;
		color: white;
	}
	.campus-header-description {
		width: 60%;
		padding-top: 100px;
		margin: 0 0 0 auto;
		color: white;
		font-size: 16px;
		line-height: 22px;
		p {
			margin: 0;
		}
	}
}

.campus-header-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: inherit;
	z-index: -1;
	overflow: hidden;
	img {
		object-fit: cover;
		object-position: center;
		width: inherit;
		height: inherit;
		z-index: inherit;
	}
}
.campus-header-map-link {
	width: 195px;
	height: 36px;
	padding-top: 6px;
	position: absolute;
	display: inline-block;
	top: 33px;
	left: 3%;
	line-height: 20px;
	cursor: pointer;
	font-weight: 600;
	background-color: $dark-gray;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	transition: all 0.3s ease;
	color: #999999;
	letter-spacing: 1.7px;
	text-decoration: none;
	transition: ease all 0.3s;
	img {
		filter: brightness(320%);
		margin: 4px 8px -7px 16px;
	}
}
@-webkit-keyframes bounce-left {
	50% {
		transform: translateX(-5px);
		-webkit-transform: translateX(-5px);
	}
	100% {
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
	}
}
@keyframes bounce-left {
	50% {
		transform: translateX(-5px);
		-webkit-transform: translateX(-5px);
	}
	100% {
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
	}
}
.campus-header-map-link:hover {
	background-color: $hover-background;
	color: black;
}
.campus-header-map-link:hover #bounce-arrow-left {
	filter: brightness(-320%);
	-webkit-filter: brightness(-320%);
	animation: bounce-left 0.4s ease;
	-webkit-animation: bounce-left 0.4s ease;
	animation-iteration-count: 3;
	-webkit-animation-iteration-count: 3;
}
// @media (min-width: 1025px) {
// 	.campus-header {
// 		height: 410px;
// 	}
// }
