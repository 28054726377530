.saving {
  background-color: #EEEDEE;
}
.saving .saving-content .standard-card {
  width: calc(90% - 10px);
  max-width: 970px;
  margin: 30px auto;
  background: white;
  padding: 40px;
  padding-bottom: 60px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

