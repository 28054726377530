.building-footer {
  width: auto;
  display: flex;
  flex-shrink: 0;
  margin: auto auto 0px;
}

.building-footer-wrapper {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #6c6c6c;
}
.building-footer-wrapper .building-footer-title {
  font-weight: 600;
  color: #000;
  opacity: 0.7;
  padding-bottom: 15px;
}

.building-footer-links {
  display: flex;
  justify-content: space-between;
}
.building-footer-links .building-footer-column {
  width: 31%;
  display: flex;
  flex-direction: column;
}
.building-footer-links .building-footer-column a {
  color: #6c6c6c;
  text-decoration: none;
  cursor: pointer;
}
.building-footer-links .building-footer-column a:hover {
  text-decoration: underline;
}
.building-footer-links {
  padding-bottom: 40px;
}

