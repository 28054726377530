$text-dark: #2D3138;
$gray: #C5C5C5;
$light-gray: #EEEDEE;
$dark-gray: #3C414B;
.sidebar {
	width: 400px;
	height: 100vh;
	min-height: 500px;
	flex-shrink: 0;
	color: $text-dark;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
	z-index: 1;
	display: flex;
	flex-direction: column;
	@media (max-height: 800px) {
		overflow-y: scroll;
	}
	.sidebar-title {
		color: $text-dark;
		font-size: 16px;
		font-weight: 600;
		line-height: 19px;
		padding: 0 35px;
		margin-bottom: -5px;
	}
	p,
	.sidebar-view,
	.sidebar-info {
		font-size: 14px;
		color: $text-dark;
		line-height: 18px;
		padding: 0px 30px 5px 35px;
	}
	.sidebar-view {
		opacity: 0.7;
	}
	.sidebar-green-button {
		width: 201px;
		height: 35px;
		border: 3px solid $light-gray;
		border-radius: 4px;
		color: rgba($color: $text-dark, $alpha: 0.7);
		font-size: 15px;
		line-height: 35px;
		font-weight: 500;
		text-align: center;
		vertical-align: middle;
		margin-left: 35px;
		margin-bottom: 10px;
		cursor: pointer;
		transition: ease 0.2s all;
		&:hover, &.active{
			color: $dark-gray;
			background-color: white;
			border-color: $dark-gray;
			font-weight: bold;
			border-width: 2px;
		}
	}
	.sidebar-info {
		opacity: 0.7;
		margin: 20px 0;
		.sidebar-description {
			padding-bottom: 10px;
		}
		.sidebar-equation {
			display: flex;
			.sidebar-eui {
				width: 85px;
			}
			.sidebar-equal {
				padding-top: 10px;
				padding-right: 20px;
			}
			.sidebar-eui-equation {
				display: flex;
				flex-direction: column;
				.sidebar-eq-top {
					border-bottom: 2px solid $gray;
				}
				.sidebar-unit {
					font-weight: 500;
				}
			}
		}
	}
	.eui-key {
		margin-top: 20px;
	}
	a {
		font-weight: 700;
		color: $text-dark;
		&:hover {
			color: black;
		}
	}
	@media (max-height: 700px) {
		p {
			margin: 6px 0;
		}
	}
}
.map-options {
	background-color: #f5f5f5;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.29);
	padding: 14px 12px;
	margin: 8px 0;
	@media (max-height: 700px) {
		padding: 6px 12px;
	}
}
.filter-slider-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	padding: 13px;
	.filter-slider-title {
		font-size: 14px;
	}
	.filter-slider {
		display: flex;
		flex-direction: column;
		width: 230px;
		padding-right: 20px;
		.slider-values {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 5px;
			span {
				font-size: 12px;
				text-align: center;
				&:nth-of-type(2) {
					margin-right: -20px;
				}
			}
		}
		.rheostat {
			margin-top: 5px;
			margin-bottom: 5px;
			width: 100%;
			button {
				top: -8px;
				width: 20px;
				height: 20px;
				border-radius: 20px;
				border: 1px solid #bebebe;
				background-color: #ffffff;
				box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
				cursor: pointer;
			}
			.rheostat-background {
				background-color: #D8D8D8;
				border: none;
				border-radius: 4px;
				height: 4px;
				margin-right: -20px;
			}
			.rheostat-progress {
				background-color: #285760;
				border-radius: 4px;
				height: 4px;
				top: 0;
				margin-right: -20px;
			}
		}
	}
}
