.barChart-container {
  padding-top: 20px;
}

.bar {
  fill: #aaa;
}

.selectedBar-electricity {
  fill: #98be48;
}

.selectedBar-steam {
  fill: #f49233;
}

.selectedBar-chilledWater {
  fill: #25a9c6;
}

.selectedBar-totals {
  fill: #222932;
}

.selectedBar-electricity-negative,
.selectedBar-steam-negative,
.selectedBar-chilledWater-negative,
.selectedBar-totals-negative {
  fill: #fa3939;
}
