.building-selector-grid-header {
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  color: #3C414B;
  font-size: 13px;
  font-weight: normal;
  line-height: 15px;
  letter-spacing: 1.21px;
  margin-top: 16px;
  img {
    padding-right: 10px;
    vertical-align: middle;
  }
  .building-selector-grid-header-row {
    display: flex;
    border-right: 1px solid white;
    padding: 16px 1.5%;
    cursor: pointer;
  }
  .building-selector-grid-header-row:hover {
    color: black;
    img {
      filter: brightness(-200%);
    }
  }
  .building-selector-grid-header-row.col1,
  .building-selector-grid-header-row.col3 {
    width: 25%;
    flex-wrap: wrap;
  }
  .building-selector-grid-header-row.col2 {
    width: 30%;
    flex-wrap: wrap;
  }
  .building-selector-grid-header-row.col4 {
    width: 20%;
    flex-wrap: wrap;
    border-right: none;
  }
  .grid-column-text {
    margin: auto 0;
    width: 90%;
    text-transform: uppercase;
  }
  .grid-column-text-selector {
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: normal;
    color: #2E384D;
    opacity: 0.5;
    margin-top: 7px;
  }
  .underline {
    text-decoration: underline;
  }
}

.capitalize {
  text-transform: capitalize; 
}
