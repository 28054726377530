$button-background: #EEEDEE;
$dark-gray: #3C414B;
$hover-gray: #2D3138;
.app-menu {
	background-color: #fff;
	height: 60px;
	display: flex;
	color: $dark-gray;
	margin: 10px 35px;
	.menu-apps {
		width: 157px;
		height: 36px;
		background-color: white;
		border-radius: 3px;
		border: 3px solid $button-background;
		color: $dark-gray;
		cursor: pointer;
		transition: ease all 0.3s;
		.menu-apps-content {
			margin-top: 11px;
			font-size: 15px;
			font-weight: 500;
			line-height: 17px;
			text-align: center;
			opacity: 0.7;
		}
		&:hover {
			background-color: $hover-gray;
			border-color: $hover-gray;
			color: white;
			font-weight: 600;
		}
	}
	@media (max-height: 700px) {
		height: 25px;
		font-size: 15px;
		padding-bottom: 0px;
	}
}
