$dark-gray: #3C414B;
.campus-footer {
	width: 90%;
	max-width: 900px;
	margin: 30px auto;
	display: flex;
	justify-content: space-between;
	.footer-content {
		width: 30%;
		max-width: 220px;
		color: $dark-gray;
		font-size: 15px;
		line-height: 27px;
		.footer-title {
			font-weight: 600;
			margin-bottom: 10px;
		}
		.footer-link {
			opacity: 0.8;
			letter-spacing: 0.16px;
			Link,
			a {
				text-decoration: none;
				color: $dark-gray;
			}
		}
		.footer-logo {
			margin-top: 4px;
			img {
				width: 75%;
			}
		}
		.footer-description {
			line-height: 18px;
			padding-top: 10px;
			&.contact-us {
				padding-top: 20px;
				a {
					color: $dark-gray;
				}
			}
		}
	}
}
