$background-gray: #EEEDEE;
$dark-gray: #3c414b;
$hover-gray: rgba(255, 255, 255, 0.15);
$light-gray: #9DA0A5;

.building-navigation {
	height: 66px;
	width: 100%;
	background-color: $dark-gray;
	color: $light-gray;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1.7px;
	text-align: center;
	.building-navigation-wrapper {
		display: flex;
		justify-content: space-between;
		width: 76%;
		max-width: 950px;
		margin: auto;
	}
}
.building-navigation-button {
	width: 175px;
	cursor: pointer;
	padding: 25px 15px;
	transition: ease all 0.2s;
	&:hover {
			color: white;
			background-color: $hover-gray;
	}
	&.active {
		color: $dark-gray;
		background-color: $background-gray
	}
}
@media (max-width: 850px) {
	.building-navigation {
		.building-navigation-wrapper {
			justify-content: center;
			width: auto;
			svg {
				display: none;
			}
		}
	}
}
