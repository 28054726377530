.compare-next-wrapper {
  padding-top: 50px;
  width: fit-content;
  margin: auto;
}

.compare-next-wrapper .compare-link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

