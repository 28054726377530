body {
  background: #EEEDEE;
}

.chcp {
  height: 100%;
  min-height: 100vh;
  background-color: #EEEDEE;
}
.chcp .chcp-content {
  width: 90%;
  max-width: 1050px;
  margin: 30px auto 0;
}
.chcp .chcp-content .production-tab .graph-card,
.chcp .chcp-content .usage-tab .graph-card,
.chcp .chcp-content .metrics-tab .graph-card {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 30px 3% 30px 3%;
  margin-bottom: 30px;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button {
  width: 100%;
  max-width: 175px;
  height: 24px;
  padding-top: 11px;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  border: 2px solid #EEEDEE;
  border-radius: 3px;
  cursor: pointer;
  transition: ease all 0.3s;
  color: rgba(60, 65, 75, 0.7);
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button:hover,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button:hover,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button:hover {
  font-weight: 600;
  background-color: white;
  border-color: #2D3138;
  color: #3C414B;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button.active,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button.active,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-button.active {
  color: #3C414B;
  font-weight: 600;
  background-color: white;
  border-color: #2D3138;
  cursor: default;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options {
  display: flex;
  flex-direction: column;
  max-width: 178px;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button {
  width: 64%;
  max-width: 105px;
  height: 22px;
  padding: 9px 0 4px 20px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  align-self: flex-end;
  color: rgba(60, 65, 75, 0.7);
  border: 2px solid #EEEDEE;
  border-radius: 3px;
  cursor: pointer;
  transition: ease all 0.3s;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button .secondary-button-radio,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button .secondary-button-radio,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button .secondary-button-radio {
  position: absolute;
  top: 8px;
  left: 8px;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #E0E7FF;
  background: white;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button:hover,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button:hover,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button:hover {
  background: white;
  color: #3C414B;
  font-weight: 600;
  border-color: #2D3138;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button.active,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button.active,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button.active {
  background: white;
  border-color: #2D3138;
  color: #3C414B;
  font-weight: 600;
  cursor: default;
  background: transparent;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button.active .secondary-button-radio span,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button.active .secondary-button-radio span,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-options .chart-secondary-button.active .secondary-button-radio span {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #2D3138;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options {
  display: flex;
  flex-direction: column;
  max-width: 178px;
  width: 100%;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper {
  width: 100%;
  max-width: 155px;
  align-self: flex-end;
  transition: ease all 0.3s;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper .historic-select-box,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper .historic-select-box,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper .historic-select-box {
  display: block;
  height: 35px;
  width: 100%;
  padding-left: 15px;
  padding-right: 30px;
  border-radius: 3px;
  border: 2px solid #EEEDEE;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper:hover,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper:hover,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chart-primary-historic-options .historic-select-wrapper:hover {
  background: white;
  color: #3C414B;
  border: 2px solid #2D3138;
  border-radius: 3px;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities {
  max-width: 175px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 10px;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input {
  margin: 2px 12px 0 0;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity {
  text-align: left;
  position: relative;
  line-height: 21px;
  display: block;
  cursor: pointer;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity .chcp-comparison-options:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity .chcp-comparison-options:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity .chcp-comparison-options:before {
  content: "";
  padding: 0px;
  display: inline-block;
  height: 16px;
  width: 16px;
  border: solid 1px #d6d6d6;
  border-radius: 3px;
  font-size: 12px;
  margin-right: 7px;
  vertical-align: middle;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input + .chcp-comparison-options:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input + .chcp-comparison-options:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input + .chcp-comparison-options:before {
  color: white;
  background-color: white;
  margin-right: 15px;
  float: left;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.electricity:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.electricity:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.electricity:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2398BF47' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.gas:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.gas:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.gas:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%238D67D6' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.steam:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.totalEfficiency:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.steam:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.totalEfficiency:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.steam:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.totalEfficiency:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23f69222' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler1Metrics:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler2Metrics:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler3Metrics:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler4Metrics:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler1Metrics:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler2Metrics:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler3Metrics:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler4Metrics:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler1Metrics:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler2Metrics:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler3Metrics:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.boiler4Metrics:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23FAC990' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chilledWater:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.totalChcpEfficiency:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chilledWater:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.totalChcpEfficiency:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chilledWater:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.totalChcpEfficiency:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%231caac8' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.total:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.total:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.total:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%231caac8' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chcpEfficiency:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.tesEfficiency:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chcpDelta:before,
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.tesDelta:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chcpEfficiency:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.tesEfficiency:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chcpDelta:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.tesDelta:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chcpEfficiency:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.tesEfficiency:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.chcpDelta:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.tesDelta:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23ADE1EB' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.domestic:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.domestic:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.domestic:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%2352BFD5' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.recycled:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.recycled:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.recycled:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23ACE1EC' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.solar:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.solar:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.solar:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23F8D81C' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.oat:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.oat:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options.oat:before {
  background: url("data:image/svg+xml;utf8,<svg enable-background='new 0 0 700 700' height='16px' id='Layer_1' version='1.1' viewBox='0 0 700 700' width='16px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><rect x='0' y='0' width='700' height='700' fill='%23000000' /><path d='M564.385,278.229c19.137-19.135,19.137-50.162,0-69.297c-19.136-19.136-50.162-19.136-69.297,0  L294.143,409.876l-87.858-87.858c-19.136-19.135-50.161-19.135-69.297,0.001c-19.135,19.135-19.136,50.163-0.001,69.297  c0.018,0.019,0.039,0.035,0.057,0.053L271.2,525.638c12.641,12.642,33.136,12.642,45.776-0.001L564.33,278.283  C564.348,278.264,564.366,278.246,564.385,278.229z' fill='%23FFFFFF' /></svg>") no-repeat center center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input + .chcp-comparison-options,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input + .chcp-comparison-options,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input + .chcp-comparison-options {
  font-size: 15px;
  color: rgba(60, 65, 75, 0.7);
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-commodity input:checked + .chcp-comparison-options {
  color: #3C414B;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-comparison-options,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-comparison-options,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-comparison-options {
  display: flex;
  align-items: center;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-comparison-options:before,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-comparison-options:before,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .chart-menu .chcp-commodities .chcp-comparison-options:before {
  min-width: 16px;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .csv,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .csv,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .csv {
  margin-top: 20px;
  max-width: 175px;
  height: 24px;
  padding-top: 11px;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  border: 2px solid #EEEDEE;
  border-radius: 3px;
  cursor: pointer;
  transition: ease all 0.3s;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .csv img,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .csv img,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .csv img {
  width: 11px;
  height: 13px;
  margin-left: 0px;
  margin-right: 15px;
  padding-top: 2px;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .csv a,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .csv a,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .csv a {
  color: #3C414B;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .csv:hover,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .csv:hover,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .csv:hover {
  font-weight: 600;
  background-color: white;
  border-color: #2D3138;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .csv.not-ready,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .csv.not-ready,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .csv.not-ready {
  cursor: not-allowed;
}
.chcp .chcp-content .production-tab .graph-card .chart-sidebar .csv.not-ready:hover,
.chcp .chcp-content .usage-tab .graph-card .chart-sidebar .csv.not-ready:hover,
.chcp .chcp-content .metrics-tab .graph-card .chart-sidebar .csv.not-ready:hover {
  background-color: rgba(60, 65, 75, 0.7);
  color: white;
  border-color: rgba(60, 65, 75, 0.7);
}

