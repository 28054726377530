.ewc-building-ranking {
  max-width: 900px;
  margin: 40px auto 0;
}
.ewc-building-ranking .ewc-switch-toggle {
  max-width: 350px;
}
.ewc-building-ranking .ewc-switch-toggle .ewc-switch-button.active img {
  filter: brightness(3);
}
@media (max-width: 768px) {
  .ewc-building-ranking {
    margin-top: 0;
  }
}
.ewc-building-ranking .ewc-building-tips {
  width: 40%;
  height: 260px;
  margin: 50px 0;
}
.ewc-building-ranking .ewc-building-tips .ewc-building-tips-detail {
  width: 90%;
  height: 70px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .ewc-building-ranking .ewc-building-tips {
    width: 80%;
    max-width: 320px;
    height: 200px;
    margin: 30px auto;
  }
}
.ewc-building-ranking .ewc-building-social-media {
  height: 260px;
  width: 35%;
  margin: 50px 0;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-share {
  height: 90px;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-share .ewc-building-social-media-tweet {
  display: flex;
  background: #95CAF1;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-share .ewc-building-social-media-tweet img {
  height: 15px;
  width: auto;
  background: #4AA0EC;
  padding: 11px 11px 10px;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-share .ewc-building-social-media-tweet .ewc-building-social-tweet-text {
  width: 100%;
  height: auto;
  padding: 10px 0 0 30px;
  color: white;
  font-weight: 600;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-share .ewc-building-social-media-facebook {
  display: flex;
  background: #899CCE;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-share .ewc-building-social-media-facebook img {
  height: 18px;
  width: auto;
  background: #3678EA;
  padding: 8px 10px 10px 9px;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-share .ewc-building-social-media-facebook .ewc-building-social-fb-text {
  width: 100%;
  height: auto;
  padding: 10px 0 0 30px;
  color: white;
  font-weight: 600;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-submit {
  font-size: 15px;
  line-height: 18px;
}
.ewc-building-ranking .ewc-building-social-media .ewc-building-social-media-submit a {
  font-weight: 600;
  color: #35BCD5;
}
@media (max-width: 768px) {
  .ewc-building-ranking .ewc-building-social-media {
    width: 70%;
    max-width: 270px;
    height: 250px;
    margin: 0px auto;
  }
}
.ewc-building-ranking .ewc-building-ranking-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px;
  margin: 30px auto 0;
}
.ewc-building-ranking .ewc-building-ranking-footer .ewc-logos {
  width: 260px;
}
.ewc-building-ranking .ewc-building-ranking-footer .ewc-building-raffle {
  max-width: 360px;
}
@media (max-width: 768px) {
  .ewc-building-ranking .ewc-building-ranking-footer .ewc-building-raffle {
    margin: auto;
    margin-bottom: 30px;
  }
}
.ewc-building-ranking .ewc-building-ranking-footer .ewc-building-raffle .ewc-building-raffle-button {
  background: #2C3037;
  transition: ease all 0.3s;
}
.ewc-building-ranking .ewc-building-ranking-footer .ewc-building-raffle .ewc-building-raffle-button:hover {
  background: #92bd3b;
}

