.app-menu {
  background-color: #fff;
  height: 60px;
  display: flex;
  color: #3C414B;
  margin: 10px 35px;
}
.app-menu .menu-apps {
  width: 157px;
  height: 36px;
  background-color: white;
  border-radius: 3px;
  border: 3px solid #EEEDEE;
  color: #3C414B;
  cursor: pointer;
  transition: ease all 0.3s;
}
.app-menu .menu-apps .menu-apps-content {
  margin-top: 11px;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  opacity: 0.7;
}
.app-menu .menu-apps:hover {
  background-color: #2D3138;
  border-color: #2D3138;
  color: white;
  font-weight: 600;
}
@media (max-height: 700px) {
  .app-menu {
    height: 25px;
    font-size: 15px;
    padding-bottom: 0px;
  }
}

