.copyright {
	display: flex;
	margin: auto 0px 10px;
	flex-shrink: 0;
	.copyright-text {
		color: #abadaf;
		font-size: 12px;
		margin: 10px 35px;
		@media (max-height: 750px) {
			margin: 0px 35px 4px;
		}

	}
}