$background-gray: #EEEDEE;
$dark-gray: #3c414b;
$hover-gray: rgba(255, 255, 255, 0.15);
$light-gray: #9DA0A5;

.campus-navigation {
	height: 66px;
	width: 100%;
	background-color: $dark-gray;
	color: $light-gray;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 1.7px;
	text-align: center;
	.campus-navigation-wrapper{
		display: flex;
		justify-content: space-between;
		width: 76%;
		max-width: 900px;
		margin: auto;
		@media(max-width: 500px) {
			width: 95%;
		}
	}
}
.campus-navigation-button {
	width: 175px;
	cursor: pointer;
	padding: 25px 15px;
	transition: ease all 0.2s;
	
	&.active {
		color: $dark-gray;
		background-color: $background-gray
	}
	@media(max-width: 355px) {
		width: 48%;
	}	
}
#demand-mobile{
	padding-top: 20px;
}
