.container {
  padding: 36px;
  background-color: white;
  border-radius: 7.5px;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.1);

  > h2 {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 12px;
    color: #022851;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;

    > img {
      height: 48px;
      width: 48px;
    }
  }

  > p {
    color: #000000;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
