$background: #eeedee;
$button-background: #2D3138;
$shadow: rgba(0, 0, 0, 0.16);
$text-light: rgba(46, 56, 77, 0.7);
$electricity: #98be48;
$steam: #f49233;
$cooling: #25a9c6;
$text: #3C414B;
$light-text: rgba(60, 65, 75, 0.7);
$header-gray: #F6F6F6;
$border-gray: #D8D8D8;
.building-projects {
	background-color: $background;
	// building commissioning tab
	.building-commissioning {
		.project-grid-wrapper {
			.buildingProjects-title {
				width: 90%;
				display: flex;
				flex-direction: column;
				font-weight: 600;
				font-size: 18px;
				margin: 60px auto 30px;
				color: $text;
				span {
					font-size: 14px;
					line-height: 26px;
					font-weight: 100;
					color: $text-light;
				}
			}
		}
		.chart-container {
			width: 90%;
			margin: 30px auto;
		}
		.table-container {
			width: 90%;
			margin: auto;
		}
		.grid-container {
			width: 90%;
			margin: 30px auto;
			padding-bottom: 45px;
		}
		.chart-title-electricity {
			color: $electricity;
		}
		.chart-title-steam {
			color: $steam;
		}
		.chart-title-chilledWater {
			color: $cooling;
		}
		.chart-title-totalSavings {
			color: $text;
		}
	}
	.loading {
		width: 900px;
		margin: 40px auto;
	}
	.buildComm-info-wrap {
		width: 70%;
		margin: 30px auto;
		line-height: 20px;
		color: $text;
		.img-quote-wrap {
			display: flex;
			align-items: center;
			margin-top: 30px;
			img {
				display: block;
				width: 20%;
				flex-shrink: 0;
				margin-left: -15px;
			}
			.buildComm-quote {
				padding-left: 60px;
				color: $light-text;
				.buildComm-name {
					margin-top: 20px;
				}
			}
		}
		.buildComm-description {
			margin-bottom: 25px;
		}
	}
	// opportunities log / HVAC / holiday shutdown tabs
	.opportunities-log,
	.hvac-scheduling {
		.buildingProjects-title {
			padding-left: 32px;
			span {
				font-size: 15px;
				margin-left: 190px;
				color: $text;
			}
		}
	}
	.year-selection {
		position: absolute;
		right: 10%;
		width: 100px;
		height: 40px;
		padding: 8px 12px;
		border: 1px solid $border-gray;
		color: black;
		font-weight: bold;
		font-size: 14px;
		-webkit-appearance: none;
		-moz-appearance: none;
		cursor: pointer;
	}
}
.opportunities-table,
.hvac-table,
.holiday-table {
	width: 100%;
	border-top: 1px solid $border-gray;
	border-right: 1px solid $border-gray;
	.opportunities-table-header,
	.hvac-table-header,
	.holiday-table-header {
		display: flex;
		width: 100%;
		width: -webkit-fill-available;
		background: $header-gray;
		font-weight: 600;
	}
	.opportunities-table-data,
	.hvac-table-data,
	.holiday-table-data {
		display: flex;
		flex-wrap: wrap;
		.opportunities-data-row,
		.hvac-data-row,
		.holiday-data-row {
			display: flex;
			width: 100%;
			width: -webkit-fill-available;
		}
	}
	.opportunities-table-content,
	.hvac-table-content,
	.holiday-table-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 20px 30px;
		border: 1px solid $border-gray;
		border-top: none;
		border-right: none;
	}
}
