.datadownload {
  background-color: white;
  min-height: 100vh;
}

.datadownload-content {
  background-color: white;
  min-height: calc(100vh - 410px);
}

.datadownload-outer-filter {
  padding: 40px 12%;
  max-width: 900px;
  margin: auto;
}
.datadownload-outer-filter .graph-card {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 30px 0 40px 0;
}
@media (max-width: 850px) {
  .datadownload-outer-filter {
    padding: 20px 0 0;
    max-width: 100%;
  }
  .datadownload-outer-filter .graph-card {
    padding: 30px 0 20px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

.datadownload-inner-filter {
  padding: 20px 12%;
  max-width: 900px;
  margin: auto;
}
.datadownload-inner-filter .graph-card {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 30px 3% 40px 3%;
}
@media (max-width: 850px) {
  .datadownload-inner-filter {
    padding: 10px 0 0;
    max-width: 100%;
  }
  .datadownload-inner-filter .graph-card {
    padding: 30px 0 20px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  }
}

