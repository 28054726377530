.ewc-landing {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ewc-landing .ewc-logo {
  width: 173px;
  margin-top: 55px;
  margin-bottom: 35px;
}
.ewc-landing .ewc-landing-header {
  width: 70%;
  font-size: 20px;
  color: white;
  text-align: center;
  font-weight: 600;
  margin: 20px 0 0;
}
.ewc-landing .ewc-landing-switch {
  width: 80%;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  padding: 20px 0;
  font-size: 15px;
  line-height: 18px;
  justify-content: space-between;
}
.ewc-landing .ewc-landing-switch .ewc-landing-switch-title {
  margin: auto;
  font-weight: 600;
  width: 40%;
  min-width: 301px;
}
@media (max-width: 768px) {
  .ewc-landing .ewc-landing-switch .ewc-landing-switch-title {
    width: 90%;
    text-align: center;
  }
}
.ewc-landing .ewc-landing-switch .ewc-landing-switch-title .ewc-landing-switch-subtitle {
  margin: 2px auto 4px;
  color: #2C3037;
  font-style: italic;
  font-size: 14px;
  opacity: 0.6;
}
.ewc-landing .ewc-landing-switch .ewc-landing-switch-title .ewc-landing-switch-subtitle a {
  color: #2C3037;
  text-decoration: underline;
}
.ewc-landing .ewc-landing-switch .ewc-switch-toggle {
  color: #2C3037;
}
.ewc-landing .ewc-landing-switch .ewc-switch-toggle .ewc-switch-description {
  opacity: 0.6;
}
.ewc-landing .ewc-landing-switch .ewc-switch-toggle .ewc-switch-button.active img {
  filter: brightness(3);
}
.ewc-landing .ewc-ranking-table {
  width: 96%;
  max-width: 900px;
  padding: 10px 2%;
  margin: 0 auto;
}
.ewc-landing .ewc-ranking-table .ranking-table-header {
  display: flex;
  margin: auto;
  width: 96%;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.6;
}
.ewc-landing .ewc-ranking-table .ranking-table-header .ranking-table-headline-team {
  width: 60%;
  margin-right: 3%;
  text-align: center;
}
.ewc-landing .ewc-ranking-table .ranking-table-header .ranking-table-headline-savings {
  width: 30%;
  text-align: right;
  margin-right: 7%;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  height: 95px;
  padding: 15px 8%;
  margin: 12px auto;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: black;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card .ranking-table-value {
  position: absolute;
  top: 42%;
  left: 5%;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card .ranking-table-value.value1 {
  color: #F1D021;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card .ranking-table-value.value2 {
  color: #C2C2C2;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card .ranking-table-value.value3 {
  color: #D8956F;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card .ranking-table-building {
  width: 80%;
  margin-left: 7%;
  margin-top: 1px;
  font-size: 14px;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card .ranking-table-percent {
  flex-grow: 1;
  text-align: right;
}
@media (max-width: 400px) {
  .ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card {
    margin-top: 0;
  }
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-buildings {
  width: 60%;
  margin: 8px 0 0 7%;
  font-size: 14px;
  line-height: 20px;
  color: #6C6C6C;
}
.ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-divider {
  position: absolute;
  right: 20%;
  top: 0;
  width: 2px;
  height: 95px;
  background: #F5F5F5;
}
@media (max-width: 768px) {
  .ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-divider {
    height: 125px;
    right: 25%;
  }
}
@media (max-width: 500px) {
  .ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-divider {
    right: 29%;
  }
}
@media (min-width: 769px) {
  .ewc-landing .ewc-ranking-table .ranking-table-card-wrapper {
    width: 90%;
    height: 65px;
    padding: 15px 4%;
  }
  .ewc-landing .ewc-ranking-table .ranking-table-card-wrapper .ranking-table-card {
    margin-top: 8px;
  }
}
.ewc-landing .ewc-landing-footer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 90%;
  max-width: 900px;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 55%;
  padding: 20px 40px 30px 5px;
  color: #2C3037;
  text-align: left;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 20px;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-header,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-header,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-header,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-header {
  font-weight: 600;
  cursor: pointer;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-header .ewc-toggle-symbol,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-header .ewc-toggle-symbol,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-header .ewc-toggle-symbol,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-header .ewc-toggle-symbol {
  font-size: 20px;
  margin-right: 20px;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-header .ewc-toggle-symbol.minus,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-header .ewc-toggle-symbol.minus,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-header .ewc-toggle-symbol.minus,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-header .ewc-toggle-symbol.minus {
  margin-left: 2px;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-expanded,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-expanded,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-expanded,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-expanded {
  padding: 0 32px;
  opacity: 0.7;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-expanded img,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-expanded img,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-expanded img,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-expanded img {
  width: 90%;
  height: auto;
  max-width: 475px;
  margin-top: 10px;
  opacity: 1;
}
@media (max-width: 400px) {
  .ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-expanded,
  .ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-expanded,
  .ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-expanded,
  .ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-expanded {
    width: 100%;
  }
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-details-enter,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-details-enter,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-details-enter,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-details-enter {
  transform: scaleY(0);
  transform-origin: center top;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-details-enter-active,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-details-enter-active,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-details-enter-active,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-details-enter-active {
  transform: scaleY(1);
  transition: ease all 0.3s;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-details-exit,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-details-exit,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-details-exit,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-details-exit {
  transform: scaleY(1);
  transform-origin: center top;
}
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-about-toggle-details-exit-active,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-about-toggle .ewc-calculation-toggle-details-exit-active,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-about-toggle-details-exit-active,
.ewc-landing .ewc-landing-footer .ewc-landing-footer-raffle .ewc-calculation-toggle .ewc-calculation-toggle-details-exit-active {
  transform: scaleY(0);
  transition: ease all 300ms;
}
.ewc-landing .ewc-landing-footer .ewc-logos {
  width: 260px;
}

