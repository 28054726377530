$dark-gray: #858a94;
$text-hover: #92BD3B;
$text: #2D3138;
$text-inactive: rgba(45, 49, 56, 0.5);
$shadow: rgba(0, 0, 0, 0.16);
.tab-wrap {
	width: calc(90% + 80px);
	max-width: 1050px;
	display: flex;
	// justify-content: space-between;
	justify-content: center;
	margin: auto;
	margin-top: 35px;
}
.tab-navigation-button {
	width: 24%;
	height: 165px;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: white;
	color: $text-inactive;
	transition: ease all 0.3s;
	cursor: pointer;
	.tab-title {
		padding: 5px;
		font-size: 17px;
		color: $text-inactive;
	}
	.tab-FY {
		padding: 3px 0 5px;
		font-size: 15px;
	}
	img {
		height: 26%;
		margin-top: 30px;
		margin-bottom: 5px;
		border: none;
		filter: grayscale(100%) brightness(80%);
		opacity: 0.5;
	}
	&.active,
	&:hover {
		transition: ease all 0.3s;
		color: $text;
		img {
			filter: none;
			opacity: 1;
		}
		& {
		box-shadow: 0 2px 4px 0 $shadow;
		}
		.tab-title {
			color: $text;
		}
	}
}
