.datadownload-header {
  width: 100%;
  height: 220px;
  background-image: url("../common/images/header.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.datadownload-header .datadownload-header-title {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: calc(100% - 10px);
  height: -webkit-fill-available;
  width: 50%;
  max-width: 290px;
  margin: auto;
  padding-top: 10px;
  justify-content: center;
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  color: white;
  font-weight: 600;
}

.datadownload-beta-banner {
  height: 40px;
  color: #98BF47;
  text-align: center;
  background-color: #2D3138;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
}

.datadownload-header-key {
  width: 95%;
  max-width: 850px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 60px 2% 10px;
  font-size: 14px;
  line-height: 16px;
  color: #333;
}
.datadownload-header-key .header-key-label {
  width: 25%;
  align-self: center;
  font-size: 15px;
}
.datadownload-header-key .header-key-commodities {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 73%;
}
.datadownload-header-key .header-key-commodities .header-key-commodity {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
}
.datadownload-header-key .header-key-commodities .header-key-commodity img {
  height: 24px;
  padding-bottom: 10px;
}

